import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'cmp/Button/Button'

export const UserInputs = ({userInputs, onFinish}) => {
  const inputRefList = {}
  userInputs.forEach(userInput => {
    inputRefList[userInput] = React.useRef()
  })

  return (
    <div className="UserInputs">
      {userInputs.map(userInput => (
        <div key={userInput} className="UserInputs-row">
          <span className="UserInputs-key">{userInput}</span>
          <input
            type="text"
            ref={inputRefList[userInput]}
          />
        </div>
      ))}
      <Button
        text="OK"
        onClick={() => {
          const result = userInputs.map(i => {
            return {[i]: inputRefList[i].current.value}
          })
          onFinish(result)
        }}
      />
    </div>
  )
}

UserInputs.propTypes = {
  userInputs: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFinish: PropTypes.func.isRequired,
}
