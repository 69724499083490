import {assign} from 'xstate'
import {server} from 'utils/server'

export const STATE = {
  IDLE: 'IDLE',
  EDITING_META: 'EDITING_META',
  LOADING_FILES: 'LOADING_FILES',
}

export const EVENT = {
  UPDATE_USER_INPUTS: 'UPDATE_USER_INPUTS',
  LOAD_FILES: 'LOAD_FILES',
  UPDATE_META: 'UPDATE_META',
  DELETE_META: 'DELETE_META',
  DONE_UPDATE_META: 'DONE_UPDATE_META',
}

export const fileManagerStatechart = {
  id: 'fileManagerSM',
  initial: STATE.IDLE,
  context: {
    fileContainer: null,
    fileNameFilter: '',
    metaFilterList: [],
    fileInfoList: [],
    selectedFileIdxList: [],
    fileCount: 0,
    page: 0,
    isStaticMode: false,
    fileURIList: [],
    dataSources: [],
  },
  states: {
    [STATE.IDLE]: {
      on: {
        [EVENT.UPDATE_USER_INPUTS]: {
          actions: ['setFilter'],
        },
        [EVENT.LOAD_FILES]: STATE.LOADING_FILES,
        [EVENT.UPDATE_META]: STATE.EDITING_META,
        [EVENT.DELETE_META]: STATE.EDITING_META,
      },
    },
    [STATE.EDITING_META]: {
      invoke: {
        src: 'requestUpdateMeta',
        onDone: STATE.LOADING_FILES,
      },
      exit: ['optimisticUpdateLocalFileInfoList'],
    },
    [STATE.LOADING_FILES]: {
      id: [STATE.LOADING_FILES],
      invoke: {
        src: 'requestGetFiles',
        onDone: STATE.IDLE,
      },
      exit: ['refreshFiles'],
    },
  },
}

export const fileManagerStatechartOptions = {
  services: {
    requestGetFiles: async(context) => {
      if(context.isStaticMode) {
        return context
      }
      else {
        return await server.getFiles(context.fileContainer, context.fileNameFilter, context.metaFilterList, context.page)
      }
    },
    requestUpdateMeta: async(context, event) => {
      if(event.type === EVENT.DELETE_META) {
        const fileName = context.fileInfoList[context.selectedFileIdxList[0]].fileName
        const metaKey = context.fileInfoList[context.selectedFileIdxList[0]].metaList[event.metaIdx].key

        await server.deleteFileMeta(context.fileContainer, fileName, metaKey)
      }
      else if(event.type === EVENT.UPDATE_META) {
        const fileNameList = context.fileInfoList
          .filter((fileInfo, fileInfoIndex) => context.selectedFileIdxList.includes(fileInfoIndex))
          .map(fileInfo => fileInfo.fileName)

        await server.updateFileMeta(context.fileContainer, fileNameList, event.meta)
      }
      else {
        throw new Error(`Invalid event type: '${event.type}'`)
      }

      return event
    },
  },
  actions: {
    setFilter: assign((context, event) => {
      return event.data
    }),
    refreshFiles: assign((context, event) => {
      const selectedFileIdxList = []
      event.data.fileInfoList.forEach((fileInfo, idx) => {
        const found = context.dataSources.find(d => d.name === fileInfo.fileName)

        if(found) {
          selectedFileIdxList.push(idx)
        }
      })

      return {
        ...event.data,
        selectedFileIdxList,
      }
    }),
    optimisticUpdateLocalFileInfoList: assign((context, event) => {
      if(context.isStaticMode) {
        if(event.data.type === EVENT.UPDATE_META) {
          const meta = event.data.meta
          const selectedFiles = context.fileInfoList.filter((fileInfo, fileInfoIdx) => context.selectedFileIdxList.includes(fileInfoIdx))
          selectedFiles.forEach(fileInfo => {
            const affectedMeta = fileInfo.metaList.find(m => m.key === meta.key)
            if(affectedMeta) {
              affectedMeta.value = meta.value
            }
            else {
              fileInfo.metaList.push(meta)
            }
          })
        }
        else if(event.data.type === EVENT.DELETE_META) {
          const metaIdx = event.data.metaIdx
          const selectedFiles = context.fileInfoList.filter((fileInfo, fileInfoIdx) => context.selectedFileIdxList.includes(fileInfoIdx))
          selectedFiles.forEach(fileInfo => {
            fileInfo.metaList.splice(metaIdx, 1)
          })
        }
        else {
          throw new Error(`Event type: ${event.data.type} is unknown.`)
        }

        return context
      }
    }),
  },
}
