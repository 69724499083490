import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Statechart} from 'scion-core'
import makeBem from 'bem-cx'

import { JobIdentifier } from '../../../../cmp/App/types'
import { Modal } from '../../../../cmp/Modal'
import { Button } from '../../../../cmp/Button/Button'

import './NewJobModal.css'

const states = {
  NEW: 'NEW',
  LOAD: 'LOAD',
  CLONE: 'CLONE',
}

const events = {
  SELECT_JOB: 'SELECT_JOB',
  UNSELECT_JOB: 'UNSELECT_JOB',
  CHECK_CLONE: 'CHECK_CLONE',
  UNCHECK_CLONE: 'UNCHECK_CLONE',
}

const cn = makeBem('NewJobModal')

export class NewJobModal extends Component {
  state = {
    selectedJobId: null,
    title: '',
    location: '',
    process: '',
    jobFilterText: '',
    sameAnalyses: false,
    requireData: false,
    mode: states.NEW,
  }

  componentDidMount() {
    const model = {
      states: [
        {
          id: states.NEW,
          transitions: [
            {
              event: events.SELECT_JOB,
              target: states.LOAD,
            },
          ],
          onEntry: () => this.setState({
            selectedJobId: null,
            mode: states.NEW,
            title: '',
            location: '',
            process: '',
            sameAnalyses: false,
            requireData: false,
          }),
        },
        {
          id: states.LOAD,
          transitions: [
            {
              event: events.UNSELECT_JOB,
              target: states.NEW,
            },
            {
              event: events.CHECK_CLONE,
              target: states.CLONE,
            },
          ],
          onEntry: () => {
            this.setState({
              mode: states.LOAD,
            })
          }
        },
        {
          id: states.CLONE,
          transitions: [
            {
              event: events.UNSELECT_JOB,
              target: states.NEW,
            },
            {
              event: events.UNCHECK_CLONE,
              target: states.LOAD,
            },
          ],
          onEntry: () => {
            this.setState({
              mode: states.CLONE,
            })
          }
        },
      ],
    }

    this.sc = new Statechart(model, {})
    this.sc.start()
  }

  render() {
    const state = this.state
    const props = this.props

    const filterJobIdentifiers = jobIdentifier => {
      let filterResult = true

      try {
        filterResult = new RegExp(state.jobFilterText).test(jobIdentifier.title) ||
          new RegExp(state.jobFilterText).test(jobIdentifier.location) ||
          new RegExp(state.jobFilterText).test(jobIdentifier.process) ||
          new RegExp(state.jobFilterText).test(jobIdentifier.owner)
      }
      catch(ex) {
        console.log(ex)
      }
      return filterResult
    }

    const onJobClick = jobIdentifier => {
      this.sc.gen(events.SELECT_JOB, jobIdentifier.id)

      this.setState({
        selectedJobId: jobIdentifier.id,
        title: jobIdentifier.title,
        location: jobIdentifier.location,
        process: jobIdentifier.process,
      })
    }

    let buttonText = 'New Job'

    if(state.mode === states.LOAD) {
      buttonText = 'Load Job'
    }
    else if(state.mode === states.CLONE) {
      buttonText = 'Clone Job'
    }

    return (
      <Modal
        isVisible={true}
        onClose={() => {}}
        title="New/Load/Clone Job"
      >
        <div id={cn}>

          <div id={cn.el('Settings')}>
            <h2>Job properties</h2>
            <div>
              <input
                type="text"
                value={state.title}
                onChange={e => this.setState({title: e.target.value})}
                disabled={state.mode === states.LOAD}
                placeholder="Title"
              />
              {state.mode === states.NEW && state.title === '' && (
                <span style={{color: 'red'}}>*</span>
              )}
            </div>

            <div>
              <input
                type="text"
                value={state.location}
                onChange={e => this.setState({location: e.target.value})}
                disabled={state.mode === states.LOAD}
                placeholder="Location"
              />
            </div>

            <div>
              <input
                type="text"
                value={state.process}
                onChange={e => this.setState({process: e.target.value})}
                disabled={state.mode === states.LOAD}
                placeholder="Process"
              />
            </div>

            <div id={cn.el('Checkboxes')}>

              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={state.clone}
                    onChange={e => e.target.checked ?
                      this.sc.gen(events.CHECK_CLONE) :
                      this.sc.gen(events.UNCHECK_CLONE)
                    }
                    disabled={state.mode === states.NEW}
                  />
                  Clone selected Job?
                </label>
              </div>

              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={state.sameAnalyses}
                    onChange={e => {
                      this.setState({sameAnalyses: e.target.checked})
                    }}
                    disabled={state.mode !== states.CLONE}
                  />
                  Apply same analyses?
                </label>
              </div>

              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={state.requireData}
                    onChange={e => {
                      this.setState({requireData: e.target.checked})
                    }}
                    disabled={state.mode !== states.CLONE}
                  />
                  Require data from the old Job?
                </label>
              </div>

            </div>

            <div id={cn.el('Buttons')}>
              <Button
                text={buttonText}
                disabled={state.mode === states.NEW && state.title === ''}
                onClick={this.onButtonClick}
              />
              {(state.mode === states.LOAD || state.mode === states.CLONE) && (
                <Button
                  text="Cancel"
                  onClick={() => this.sc.gen(events.UNSELECT_JOB)}
                />
              )}
            </div>

          </div>

          <div id={cn.el('SavedJobs')}>

            <div className="jobs-header">
              <h2>Saved Jobs</h2>
              <input
                value={state.jobFilterText}
                onChange={e => this.setState({jobFilterText: e.target.value})}
                type="text"
                placeholder="Filter name, location, or process..."
              />
            </div>

            <div className="tableContainer">
              <table>
                <thead>
                  <tr>
                    <th>Job name</th>
                    <th>Location</th>
                    <th>Process</th>
                    <th>Owner</th>
                  </tr>
                </thead>
                <tbody>
                  {props.jobIdentifiers.length > 0 ? props.jobIdentifiers
                    .filter(filterJobIdentifiers)
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map(jobIdentifier => (
                      <tr
                        className={cn.el('SavedJobItem').mod({selected: jobIdentifier.id === state.selectedJobId})}
                        key={jobIdentifier.id}
                        onClick={() => onJobClick(jobIdentifier)}
                      >
                        <td>{jobIdentifier.title}</td>
                        <td>{jobIdentifier.location}</td>
                        <td>{jobIdentifier.process}</td>
                        <td>{jobIdentifier.owner}</td>
                      </tr>
                  )) : <div>Empty...</div>}
                </tbody>
              </table>
            </div>

          </div>

        </div>

      </Modal>
    )
  }

  onButtonClick = () => {
    switch (this.state.mode){
      case states.NEW:
        const jobWithSameTitle = this.props.jobIdentifiers.find(i => i.title === this.state.title)

        if(jobWithSameTitle) {
          alert(`Job with name: ${jobWithSameTitle.title} already exists`)
        }
        else {
          this.props.newJob(this.state.title, this.state.location, this.state.process)
        }

        break

      case states.LOAD:
        this.props.loadJob(this.state.selectedJobId)
        break

      case states.CLONE:
        this.props.cloneJob(this.state.selectedJobId, this.state.sameAnalyses)
        break

      default:
        console.warn(`Unknown state: ${this.state.mode}`)
        break
    }
  }
}

NewJobModal.propTypes = {
  jobIdentifiers: PropTypes.arrayOf(PropTypes.shape(JobIdentifier)).isRequired,
  newJob: PropTypes.func.isRequired,
  loadJob: PropTypes.func.isRequired,
  cloneJob: PropTypes.func.isRequired,
}
