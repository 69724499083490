import React from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'

import {MENU_ITEM} from 'cmp/App/MainPage/MainPage'
import './Menu.css'

const cn = makeBem('Menu')

export const Menu = props => {
  let offset = 2
  let content = (
    <div>
      <div onClick={() => props.onMenuItemClick(MENU_ITEM.NEW_JOB)}>New/Load Job</div>
      <div onClick={() => props.onMenuItemClick(MENU_ITEM.SHARE_JOB)}>Share Job</div>
    </div>
  )
  const userName = localStorage.getItem('username')

  if(props.selectedMenuTitle === props.menuTitles.DATA) {
    offset = 43
    content = (
      <div>
        <div onClick={() => props.onMenuItemClick(MENU_ITEM.FILE_HANDLING)}>File Handling</div>
        <div onClick={() => props.onMenuItemClick(MENU_ITEM.FILE_MANAGER)}>File Manager</div>
        <div onClick={() => props.onMenuItemClick(MENU_ITEM.DB_QUERY_EDITOR)}>DB Query Editor</div>
      </div>
    )
  }
  else if(props.selectedMenuTitle === props.menuTitles.USER) {
    offset = 83
    content = (
      <div>
        <div
          onClick={() => alert('Coming soon...')}
        >Settings</div>
        <div
          onClick={props.onLogout}
        >Log out</div>
      </div>
    )
  }

  return (
    <div className="Menu">
      {props.children}
      {!!props.selectedMenuTitle && (
        <div
          className={cn.el('Content')}
          style={{left: offset}}
        >{content}</div>
      )}

      {props.defaultFileContainer && (
        <span className="Menu__jobTitle">
          <i className="fas fa-database"></i> {props.defaultFileContainer}
        </span>
      )}

      <span className="Menu__userName">
        <i className="fas fa-user"></i> {userName}
      </span>

      {props.jobTitle && (
        <span className="Menu__jobTitle">
          <i className="fas fa-laptop"></i> {props.jobTitle}
        </span>
      )}

    </div>
  )
}

Menu.propTypes = {
  selectedMenuTitle: PropTypes.string,
  menuTitles: PropTypes.object.isRequired,
  jobTitle: PropTypes.string,
  defaultFileContainer: PropTypes.string,
  onMenuItemClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
}
