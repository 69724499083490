import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import DragSelect from 'dragselect'
import {Pagination} from './Pagination'
import './ImageDisplay.css'

const maxFilenameDisplayLength = 120

export const ImageDisplay = props => {
  const isSelected = fileInfoIdx => _.includes(props.selectedImageIdxList, fileInfoIdx) ? 'selected' : ''
  const isFilteredOut = fileInfo => !props.doFilter || props.imageFilter.filter(fileInfo) ? '' : 'isFilteredOut'

  const truncateLongText = text => text.length > maxFilenameDisplayLength ?
    '...' + text.substr(text.length - maxFilenameDisplayLength) :
    text

  useEffect(() => {
    const dragSelect = new DragSelect({
      area: document.getElementById('ImageDisplay'),
      selectables: document.getElementsByClassName('thumb'),
      immediateDrag: false,
      multiSelectToggling: true,
      // For disabling thumb moving with arrow keys
      dragKeys: {up:[''], down: [''], left: [''], right: ['']},
    })

    dragSelect.subscribe('callback', ({items}) => {
      const selectedImageIdxList = items.map(thumb => Number(thumb.dataset.thumbIdx))
      props.selectImages(selectedImageIdxList)
    })

    return function unsubscribeDragSelect() {
      dragSelect.stop()
    }
  }, [props.fileInfoList])

  return (
    <div id="ImageDisplay">
      <div className="ImagePreviewContainer">
        {props.fileInfoList.map((fileInfo, fileInfoIdx) => (
          <div
            className={`thumb ${isSelected(fileInfoIdx)} ${isFilteredOut(fileInfo)}`}
            key={fileInfoIdx}
            data-thumb-idx={fileInfoIdx}
          >
            <img src={fileInfo.thumb} draggable="false" />
            <p className="filename">{truncateLongText(fileInfo.fileName)}</p>
          </div>
        ))}
      </div>
      {props.fileInfoList.length > 0 && (
        <Pagination
          fileCount={props.fileCount}
          selectedPage={props.page}
          setPage={props.setPage}
        />
      )}
    </div>
  )
}

ImageDisplay.propTypes = {
  fileInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageFilter: PropTypes.shape({
    //TODO: add remaining props!
    filter: PropTypes.func.isRequired,
  }).isRequired,
  fileCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  selectedImageIdxList: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPage: PropTypes.func.isRequired,
  selectImages: PropTypes.func.isRequired,
  doFilter: PropTypes.bool,
}
