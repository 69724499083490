import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const IMAGE_PER_PAGE = 100

export const Pagination = ({
  fileCount,
  selectedPage,
  setPage,
}) => {
  const isFirstPage = () => selectedPage === 0
  const isLastPage = () => selectedPage === Math.floor(fileCount / IMAGE_PER_PAGE)
  const pageCount = fileCount / IMAGE_PER_PAGE
  
  return (
    <div id="PaginationContainer">
      <div id="Pagination">

        <div
          className={`pageItem ${isFirstPage() ? 'activePage' : ''}`}
          onClick={() => setPage(selectedPage - 1)}
        >{'<'}</div>

        {_.range(pageCount).map(page => selectedPage === page ? (
            <div
              key={page}
              className="pageItem activePage"
            >{page + 1}</div>
          ) : (
            <div
              className="pageItem"
              key={page}
              onClick={() => setPage(page)}
            >{page + 1}</div>
          )
        )}

        <div
          className={`pageItem ${isLastPage() ? 'activePage' : ''}`}
          onClick={() => setPage(selectedPage + 1)}
        >{'>'}</div>

      </div>
    </div>
  )
}

Pagination.propTypes = {
  fileCount: PropTypes.number.isRequired,
  selectedPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
}
