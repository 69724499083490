import {EquipmentProperty} from './equipmentProperty'

/** @class */
export class Field extends EquipmentProperty {
  /**
   * @param json {object}
   *
   * @param json.key {string}
   * @param json.label {string}
   * @param json.description {string}
   * @param json.value {string}
   * @param json.type {FIELD_TYPE}
   */
  constructor(json) {
    super(json)

    json = json || {}

    this.value = json.value === undefined ? '' : json.value
    this.type = json.type
  }

  setValue = value => this.value = value
}
