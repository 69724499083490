import React from 'react'
import ReactDOM from 'react-dom'
import {Auth0Provider} from '@auth0/auth0-react'
import './css/index.css'
import './css/button.css'

/***** PRODUCTION MODE *****/
import {App} from './cmp/App/App'
const app = <App/>

/***** DEVELOPMENT MODE *****/
/*import {Dev} from './cmp/Dev/Dev'
const app = <Dev/>*/

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
  >
    {app}
  </Auth0Provider>,
  document.getElementById('root'),
)
