import {assign} from 'xstate'
import {server} from 'utils/server'

export const STATE = {
  IDLE: 'IDLE',
  EDITING_META: 'EDITING_META',
  LOADING_IMAGES: 'LOADING_IMAGES',
}

export const EVENT = {
  UPDATE_USER_INPUTS: 'UPDATE_USER_INPUTS',
  LOAD_IMAGES: 'LOAD_IMAGES',
  UPDATE_META: 'UPDATE_META',
  DELETE_META: 'DELETE_META',
  DONE_UPDATE_META: 'DONE_UPDATE_META',
  DONE_LOADING: 'DONE_LOADING',
}

export const imageManagerStatechart = {
  id: 'imageManagerSM',
  initial: STATE.IDLE,
  context: {
    fileNameFilter: '',
    metaFilterList: [],
    fileInfoList: [],
    selectedImageIdxList: [],
    fileCount: 0,
    page: 0,
    isStaticThumbMode: false,
    imageURIList: [],
  },
  states: {
    [STATE.IDLE]: {
      on: {
        [EVENT.UPDATE_USER_INPUTS]: {
          actions: ['setFilter'],
        },
        [EVENT.LOAD_IMAGES]: STATE.LOADING_IMAGES,
        [EVENT.UPDATE_META]: STATE.EDITING_META,
        [EVENT.DELETE_META]: STATE.EDITING_META,
      },
    },
    [STATE.EDITING_META]: {
      invoke: {
        src: 'requestUpdateMeta',
        onDone: [
          {
            target: [STATE.LOADING_IMAGES],
            cond: context => !context.isStaticThumbMode,
          },
          {
            target: [STATE.IDLE],
            cond: context => context.isStaticThumbMode,
            actions: ['optimisticUpdateLocalFileInfoList'],
          },
        ],
      },
    },
    [STATE.LOADING_IMAGES]: {
      id: [STATE.LOADING_IMAGES],
      invoke: {
        src: 'requestGetImages',
        onDone: STATE.IDLE,
      },
      exit: ['refreshImages'],
    },
  },
}

export const imageManagerStatechartOptions = {
  services: {
    requestGetImages: async(context, event) => {
      if(context.isStaticThumbMode) {
        return context
      }
      else {
        return await server.getImages(context.fileNameFilter, context.metaFilterList, context.page, event.data.fileContainer)
      }
    },
    requestUpdateMeta: async(context, event) => {
      if(event.type === EVENT.DELETE_META) {
        const fileName = context.fileInfoList[context.selectedImageIdxList[0]].fileName
        const metaKey = context.fileInfoList[context.selectedImageIdxList[0]].metaList[event.metaIdx].key

        await server.deleteImageMeta(fileName, metaKey, event.fileContainer)
      }
      else if(event.type === EVENT.UPDATE_META) {
        const fileNameList = context.fileInfoList
          .filter((fileInfo, fileInfoIndex) => context.selectedImageIdxList.includes(fileInfoIndex))
          .map(fileInfo => fileInfo.fileName)

        await server.updateImageMeta(fileNameList, event.meta, event.fileContainer)
      }
      else {
        throw new Error(`Invalid event type: '${event.type}'`)
      }

      return event
    },
  },
  actions: {
    setFilter: assign((context, event) => {
      return event.data
    }),
    refreshImages: assign((context, event) => {
      return {
        ...event.data,
        selectedImageIdxList: [],
      }
    }),
    optimisticUpdateLocalFileInfoList: assign((context, event) => {
      //TODO: investigate if this condition can be removed ("isStaticThumbMode" is obviously true here)
      if(context.isStaticThumbMode) {
        if(event.data.type === EVENT.UPDATE_META) {
          const meta = event.data.meta
          const selectedFiles = context.fileInfoList.filter((fileInfo, fileInfoIdx) => context.selectedImageIdxList.includes(fileInfoIdx))
          selectedFiles.forEach(fileInfo => {
            const affectedMeta = fileInfo.metaList.find(m => m.key === meta.key)
            if(affectedMeta) {
              affectedMeta.value = meta.value
            }
            else {
              fileInfo.metaList.push(meta)
            }
          })
        }
        else if(event.data.type === EVENT.DELETE_META) {
          const metaIdx = event.data.metaIdx
          const selectedFiles = context.fileInfoList.filter((fileInfo, fileInfoIdx) => context.selectedImageIdxList.includes(fileInfoIdx))
          selectedFiles.forEach(fileInfo => {
            fileInfo.metaList.splice(metaIdx, 1)
          })
        }
        else {
          throw new Error(`Event type: ${event.data.type} is unknown.`)
        }

        return context
      }
    }),
  },
}
