import {Allocation} from './allocation'

/** @class */
export class EventAllocation extends Allocation {
  constructor(json) {
    json = json || {}
    super(json)

    this.key = json.key
  }
}
