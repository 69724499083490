import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {Equipment} from 'cmp/App/types'
import {Modal} from 'cmp/Modal'
import {FileControl} from 'cmp/FileControl/FileControl'
import {
  FILE_INFO_META_NAME,
  FILE_INFO_TYPE,
} from 'cmp/FileControl/class'
import {MainPageContextConsumer} from 'cmp/App/MainPage/MainPage'

const analysisKeys = {
  "DRIVE_ELECTRIC_LOAD": 1,
  "ROM_BIN_UTILISATION": 2,
  "TONNAGE_RATE_LOOP_PERFORMANCE": 3,
  "FLOTATION_AIR_INJECTION_LOOP_PERFORMANCE": 4,
  "SURGE_BIN_UTILISATION": 5,
  "RECLAIMER_UTILISATION": 6,
  "ALARM_SYSTEM_PERFORMANCE": 7,
  "TOP_ALARMS": 8,
  "ROOT_CAUSE": 9,
  "CRUSHER_DUMP_POCKET_BRIDGING": 10,
   "ORE_BUNCHING_HOURGLASSING_DETECTION": 11,
   "FLOTATION_PERFORMANCE_MONITORING": 12,
   "CONVEYOR_SPEED_CURVE_ANALYSIS": 13,
}

export class EquipmentDataQuery extends React.Component {
  static propTypes = {
    equipment: PropTypes.shape(Equipment),
    fileQueries: PropTypes.array.isRequired,
    jobLocation: PropTypes.string.isRequired,
  }

  state = {
    isFileControlVisible: false,
    metaFilters: {},
    fileInfoType: null,
    isRequestingQueryData: false,
    path: '',
  }

  render() {
    if(this.props.equipment === undefined) {
      return (
        <div>No Equipment is selected</div>
      )
    }

    const fileQueries = this.props.fileQueries.filter(f => f.equipmentType.toLowerCase() === this.props.equipment.type.toLowerCase())

    return (
      <MainPageContextConsumer>
        {mainPageContext => {
          const analysisTypeKeys = []
          mainPageContext.job.analyses.forEach(a => {
            analysisTypeKeys.push(analysisKeys[a])
          })

          return (
            <div>

              <Modal
                isVisible={this.state.isFileControlVisible}
                onClose={() => this.setState({
                  isFileControlVisible: false,
                  metaFilters: {},
                })}
                title="File handling"
              >
                <FileControl
                  jobTitle={mainPageContext.job.title}
                  metaFilters={this.state.metaFilters}
                  path={this.state.path}
                  fileInfoType={this.state.fileInfoType}
                />
              </Modal>

              {fileQueries.filter(f => {
                const analysisArray = JSON.parse('[' + f.analysisTypes + ']')
                const intersection = _.intersection(analysisArray, analysisTypeKeys)
                return intersection.length > 0
              }).sort((a, b) => a.uiOrder - b.uiOrder).map((fileQuery, fileQueryIndex) => (
                <div key={fileQueryIndex}>
                  <a
                    href="/"
                    style={{marginBottom: 8, display: 'inline-block'}}
                    onClick={e => {
                      e.preventDefault()
                      let fileInfoType = ''

                      if(RegExp('cdata').test(fileQuery.container)) {
                        fileInfoType = FILE_INFO_TYPE.MEASUREMENT
                      }
                      else if(RegExp('ddata').test(fileQuery.container)) {
                        fileInfoType = FILE_INFO_TYPE.EVENT
                      }
                      else if(RegExp('idata').test(fileQuery.container)) {
                        fileInfoType = FILE_INFO_TYPE.IMAGE
                      }
                      else if(RegExp('sdata').test(fileQuery.container)) {
                        fileInfoType = FILE_INFO_TYPE.SUPPORT
                      }
                      else {
                        console.warn('unknown file container type')
                      }

                      this.setState({
                        isFileControlVisible: true,
                        metaFilters: {
                          [FILE_INFO_META_NAME.ASSET]: this.props.equipment.name,
                          [FILE_INFO_META_NAME.QUERY]: fileQuery.queryString,
                          [FILE_INFO_META_NAME.LOCATION]: this.props.jobLocation,
                        },
                        path: fileQuery.path,
                        fileInfoType,
                      })
                    }}
                  >
                    {fileQuery.name}
                  </a>
                </div>
              ))}

            </div>
          )
        }}
      </MainPageContextConsumer>
    )
  }
}
