import {server} from 'utils/server'

export const STATE = {
  IDLE: 'IDLE',
  USER_INPUTS: 'USER_INPUTS',
  SENDING: 'SENDING',
  RESPONSE: 'RESPONSE',
}

export const EVENT = {
  SELECT: 'SELECT',
  SHOW_RESPONSE: 'SHOW_RESPONSE',
  SEND: 'SEND',
  FINISH: 'FINISH',
  CANCEL: 'CANCEL',
}

export const SERVICE = {
  CALL_APP_ENDPOINT: 'CALL_APP_ENDPOINT',
}

export const ACTION = {
  OPEN_USER_INPUTS_MODAL: 'OPEN_USER_INPUTS_MODAL',
  CLOSE_USER_INPUTS_MODAL: 'CLOSE_USER_INPUTS_MODAL',
  OPEN_RESPONSE_MODAL: 'OPEN_RESPONSE_MODAL',
  CLOSE_RESPONSE_MODAL: 'CLOSE_RESPONSE_MODAL',
  DISABLE_BUTTONS: 'DISABLE_BUTTONS',
  ENABLE_BUTTONS: 'ENABLE_BUTTONS',
  SET_APP_ID: 'SET_APP_ID',
  SHOW_APP_START_SUCCESS: 'SHOW_APP_START_SUCCESS',
}

export const serviceAppsStatechart = {
  id: `ServiceAppsSM${Math.random()}`,
  initial: STATE.IDLE,
  context: {
    isSendingInProgress: false,
    isUserInputsVisible: false,
    isResponseVisible: false,
    serviceAppId: null,
    userInputs: [],
    responseMessage: null,
  },
  states: {
    [STATE.IDLE]: {
      entry: [ACTION.ENABLE_BUTTONS],
      exit: [ACTION.SET_APP_ID],
      on: {
        [EVENT.SELECT]: [
          {
            target: STATE.USER_INPUTS,
            cond: (context, event) => {
              return event.data.userInputs
            },
          },
          {
            target: STATE.SENDING,
            cond: (context, event) => {
              return !event.data.userInputs
            },
          },
        ],
      },
    },
    [STATE.USER_INPUTS]: {
      on: {
        [EVENT.SEND]: STATE.SENDING,
        [EVENT.CANCEL]: STATE.IDLE,
      },
      entry: [ACTION.OPEN_USER_INPUTS_MODAL],
      exit: [ACTION.CLOSE_USER_INPUTS_MODAL],
    },
    [STATE.SENDING]: {
      entry: [ACTION.DISABLE_BUTTONS],
      exit: [ACTION.SHOW_APP_START_SUCCESS],
      invoke: {
        src: SERVICE.CALL_APP_ENDPOINT,
        onDone: [
          {
            target: [STATE.IDLE],
            cond: (context, event) => {
              return !event.data
            },
          },
          {
            target: [STATE.RESPONSE],
            cond: (context, event) => {
              return event.data
            },
          },
        ],
      },
    },
    [STATE.RESPONSE]: {
      entry: [ACTION.OPEN_RESPONSE_MODAL],
      exit: [ACTION.CLOSE_RESPONSE_MODAL],
      on: {
        [EVENT.FINISH]: STATE.IDLE,
      },
    },
  },
}
