import {uuid} from 'utils/uuid'
import {addAllocation, getAllocation} from './timeSetProvider'
import {Equipment} from './equipment'

/** @class */
export class EquipmentContainer {
  constructor(json) {
    this.type = json.type
    this.label = json.type
    this.countMin = json.countMin
    this.countMax = json.countMax
    this.equipmentList = json.equipmentList.map(e => new Equipment(e))

    this.allocations = json.allocations
  }

  addEquipment(analyses) {
    this.equipmentList.push(new Equipment({
      isNew: true,
      id: uuid(),
      type: this.type,
      analyses,
    }))
  }
}

EquipmentContainer.prototype.addAllocation = addAllocation
EquipmentContainer.prototype.getAllocation = getAllocation
