import {addAllocation, getAllocation} from './timeSetProvider'
import {Equipment} from './equipment'
import {Allocation} from './allocation'
import {EventAllocation} from './eventAllocation'

/**
 * @class
 * @implements {TimeSetProvider}
 */
export class Plant {
  constructor(json) {
    json = json || {
        // TODO: Could use JSON instead of string?
        canvasData: '[]',
        equipmentList: [],
        eventAllocations: [],
        allocations: [],
      }

    this.canvasData = json.canvasData
    this.equipmentList = json.equipmentList.map(e => new Equipment(e))
    this.eventAllocations = json.eventAllocations.map(e => new EventAllocation(e))
    this.allocations = json.allocations.map(a => new Allocation(a))
  }

  /**
   * Deep searches recursively to find Equipment in the Equipment tree.
   *
   * @param idToFind {string} - The id of the searched Equipment.
   * @return {Equipment}
   */
  getEquipment(idToFind) {
    let result = null

    const searchEquipment = equipment => {
      if(equipment.id === idToFind) {
        result = equipment
      }
      else {
        equipment.equipmentContainers.forEach(c => c.equipmentList.forEach(e => searchEquipment(e)))
      }
    }

    this.equipmentList.forEach(equipment => {
      const foundEquipment = searchEquipment(equipment)
      if(foundEquipment !== undefined) {
        result = foundEquipment
      }
    })

    if(result === null) {
      throw new Error(`Unknown Equipment id: '${idToFind}'`)
    }

    return result
  }

  getAllEquipment() {
    const allEquipment = []

    const addSubEquipmentList = equipment => {
      equipment.equipmentContainers.forEach(container => {
        container.equipmentList.forEach(equipment => {
          allEquipment.push(equipment)
          addSubEquipmentList(equipment)
        })
      })
    }

    this.equipmentList.forEach(e => {
      allEquipment.push(e)
      addSubEquipmentList(e)
    })

    return allEquipment
  }
}

Plant.prototype.addAllocation = addAllocation
Plant.prototype.getAllocation = getAllocation
