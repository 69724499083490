import PropTypes from 'prop-types'

export class AnalysisType{
  constructor(key, groupId, name, label, checked){
    this.key = key
    this.groupId = groupId
    this.name = name
    this.label = label
    this.checked = checked
  }
}

export class AnalysisGroup{
  constructor(id, name){
    this.id = id
    this.name = name
  }
}

export const analysisGroups = [
  new AnalysisGroup(1, 'Production Data Analytics'),
  new AnalysisGroup(2, 'Discrete Event Data Analytics'),
  new AnalysisGroup(3, 'Computer Vision Analytics'),
  new AnalysisGroup(4, 'AI Models'),
  new AnalysisGroup(5, 'Other'),
  new AnalysisGroup(6, 'Computer Vision Annotation'),
]

export const analyses = [
  new AnalysisType(0, 1, 'DRIVE_ELECTRIC_LOAD', 'Drive Electric Load'),
  new AnalysisType(1, 1, 'ROM_BIN_UTILISATION', 'Rom Bin Utilisation'),
  new AnalysisType(2, 1, 'TONNAGE_RATE_LOOP_PERFORMANCE', 'Tonnage Rate Loop Performance'),
  new AnalysisType(3, 1, 'FLOTATION_AIR_INJECTION_LOOP_PERFORMANCE', 'Flotation Air Injection Loop Performance'),
  new AnalysisType(4, 1, 'SURGE_BIN_UTILISATION', 'Surge Bin Utilisation'),
  new AnalysisType(5, 1, 'RECLAIMER_UTILISATION', 'Reclaimer Utilisation'),
  new AnalysisType(15, 1, 'CONVEYOR_SPEED_CURVE_ANALYSIS', 'Conveyor Speed Curve Analysis'),

  new AnalysisType(6, 2, 'ALARM_SYSTEM_PERFORMANCE', 'Alarm System Performance'),
  new AnalysisType(24, 2, 'PLANT_AVAILABILITY', 'Plant Availability'),
  new AnalysisType(7, 2, 'TOP_ALARMS', 'Top Alarms'),
  new AnalysisType(8, 2, 'ROOT_CAUSE', 'Root Cause'),
  
  new AnalysisType(9, 3, 'CRUSHER_DUMP_POCKET_BRIDGING', 'Crusher Dump Pocket Bridging'),
  new AnalysisType(25, 3, 'CRUSHER_BRIDGE_DETECTION', 'Crusher Bridge Detection'),
  new AnalysisType(26, 3, 'STATIC_PSD', 'Static Particle Size Distribution'),
  new AnalysisType(12, 3, 'ORE_BUNCHING_HOURGLASSING_DETECTION', 'Ore Bunching Hourglassing Detection'),
  new AnalysisType(14, 3, 'FLOTATION_PERFORMANCE_MONITORING', 'Flotation Performance Monitoring'),

  // Computer Vision Annotations are checked in MainPage to decide if ImageManager tab should be visible!
  new AnalysisType(27, 6, 'SEGMENTATION', 'Segmentation'),
  new AnalysisType(28, 6, 'LABELING', 'Labeling'),
  new AnalysisType(29, 6, 'BOUNDING_BOX_PLACEMENT', 'Bounding Box Placement'),
  new AnalysisType(30, 6, 'REVIEW', 'Review'),

  new AnalysisType(16, 4, 'STATIONARY_TIME_SERIES', 'Stationary Time Series'),
  new AnalysisType(17, 4, 'DNN', 'DNN'),
  new AnalysisType(18, 4, 'CONVOLUTION', 'Convolution'),
  new AnalysisType(19, 4, 'SHORT_TERM_MEMORY', 'Short term memory'),
  new AnalysisType(20, 4, 'LONG_SHORT_TERM_MEMORY', 'Long Short-Term Memory'),
  new AnalysisType(21, 4, 'RECURRENT_MODEL', 'Recurrent Model'),

  new AnalysisType(22, 5, 'CONVEYOR_DESIGN', 'Conveyor Design'),
  new AnalysisType(23, 5, 'SOLAR', 'Solar'),
]

export const controlAnalysisGroups = [
  new AnalysisGroup(1, 'Rock Breaker'),
  new AnalysisGroup(2, 'Crushing Circuit'),
  new AnalysisGroup(3, 'Conveyor'),
  new AnalysisGroup(4, 'Wet Processing'),
  new AnalysisGroup(5, 'Water Plant'),
]

export const controlAnalyses = [
  new AnalysisType(0, 1, 'INVERSE_KINEMATICS', 'Inverse Kinematics'),
  new AnalysisType(1, 1, 'SIMULATOR', 'Simulator'),
  new AnalysisType(2, 2, 'APRON_FEEDER_MPC', 'Apron Feeder MPC'),
  new AnalysisType(3, 2, 'DYNAMIC_CRUSHING_OPEN_SIDE_SETTINGS_CONTROL', 'Dynamic Crushing Open Side Settings Control'),
  new AnalysisType(4, 2, 'ADAPTIVE_SCREEN_LOAD_BALANCING', 'Adaptive Screen Load Balancing'),
  new AnalysisType(5, 3, 'CONVEYOR_CHAIN_CONTROL', 'Inverse'),
  new AnalysisType(6, 4, 'FLOTATION_BANKS_OPTIMAL_CONTROL', 'Flotation Banks Optimal Control'),
  new AnalysisType(6, 5, 'PH_CONTROL', 'pH Control'),
]

export const Analysis = PropTypes.number

export const DataSet = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const DataSource = {
  name: PropTypes.string.isRequired,
  dataSets: PropTypes.arrayOf(PropTypes.shape(DataSet)).isRequired,
}

export const Quantity = {
  unit: PropTypes.string,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const Allocation = {
  dataSource: PropTypes.shape(DataSource),
  dataSet: PropTypes.shape(DataSet),
}

export const VariableAllocation = {
  ...Allocation,
  timeDataSet: PropTypes.shape(DataSet).isRequired,
}

export const Field = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  description: PropTypes.string,
  type: PropTypes.oneOf([
    'BOOL',
    'STRING',
    'INT',
    'REAL',
  ]).isRequired,
}

export const Constant = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.shape(Quantity).isRequired,
}

export const Variable = {
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.shape(Quantity).isRequired,
  unit: PropTypes.string,
  allocations: PropTypes.arrayOf(PropTypes.shape(VariableAllocation)).isRequired,
}

/* This is needed because of the circular dependency between Equipment and EquipmentContainer */
const equipmentBase = {
  id: PropTypes.string.isRequired,
  // type: PropTypes.oneOf(['bin', 'conveyor', 'truck', 'belt', 'electric_drive', 'weigher', 'rock_breaker_control']).isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(Field)).isRequired,
  constants: PropTypes.arrayOf(PropTypes.shape(Constant)).isRequired,
  variables: PropTypes.arrayOf(PropTypes.shape(Variable)).isRequired,
}

export const EquipmentContainer = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  countMin: PropTypes.number.isRequired,
  countMax: PropTypes.number.isRequired,
  equipmentList: PropTypes.arrayOf(PropTypes.shape(equipmentBase)).isRequired,
}

export const Equipment = {
  ...equipmentBase,
  equipmentContainers: PropTypes.arrayOf(PropTypes.shape(EquipmentContainer)).isRequired,
}

export const Plant = {
  canvasData: PropTypes.string.isRequired,
  equipmentList: PropTypes.arrayOf(PropTypes.shape(Equipment)).isRequired,
  eventAllocations: PropTypes.arrayOf(PropTypes.shape(Allocation)).isRequired,
}

export const Job = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  process: PropTypes.string.isRequired,
  // startTime: PropTypes.instanceOf(Date),
  startTime: PropTypes.string,
  // finishTime: PropTypes.instanceOf(Date),
  finishTime: PropTypes.string,
  plant: PropTypes.shape(Plant).isRequired,
  dataSources: PropTypes.arrayOf(PropTypes.shape(DataSource)).isRequired,
}

export const User = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
}

export const JobIdentifier = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export const isCVAnnotation = analysisKey => {
  return analysisKey === 'SEGMENTATION' ||
         analysisKey === 'LABELING' ||
         analysisKey === 'BOUNDING_BOX_PLACEMENT' ||
         analysisKey === 'REVIEW'
}
