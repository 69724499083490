import React from 'react'
import makeBem from 'bem-cx'

import {requestAsync, METHODS} from 'utils/server'
import './DBQueryEditor.css'

const cn = makeBem('DBQueryEditor')

export class DBQueryEditor extends React.Component {
  state = {
    userDBList: [],
    userDBQueryList: [],
    selectedDBDataSourceIndex: 0,
  }

  async componentDidMount() {
    const userDBList = await requestAsync(METHODS.GET, {
      url: 'dataQuery/userDB',
    })
    
    let userDBQueryList = []
    
    if(userDBList.length > 0) {
      userDBQueryList = await requestAsync(METHODS.GET, {
        url: `dataQuery/userDBQuery/${userDBList[0].id}`,
      })
    }

    this.setState({
      userDBList,
      userDBQueryList,
    })
  }

  onUserDBSelectChange = async(e) => {
    const selectedDBDataSourceIndex = Number(e.target.value)
    const userDBQueryList = await requestAsync(METHODS.GET, {
      url: `dataQuery/userDBQuery/${this.state.userDBList[selectedDBDataSourceIndex].id}`,
    })

    this.setState({
      selectedDBDataSourceIndex,
      userDBQueryList,
    })
  }

  onAddUserDB = async() => {
    const name = window.prompt('DB name:')
    const connectionString = window.prompt('Connection string:')
    await requestAsync(METHODS.POST, {
      url: 'dataQuery/userDB',
      payload: {
        name,
        connectionString,
      },
    })
    const userDBList = await requestAsync(METHODS.GET, {
      url: 'dataQuery/userDB',
    })

    let userDBQueryList = []

    if(userDBList.length > 0) {
      userDBQueryList = await requestAsync(METHODS.GET, {
        url: `dataQuery/userDBQuery/${userDBList[0].id}`,
      })
    }

    this.setState({
      userDBList,
      userDBQueryList,
    })
  }

  onAddQueryString = async() => {
    const name = window.prompt('Query name:')
    const query = window.prompt('Query string:')
    await requestAsync(METHODS.POST, {
      url: 'dataQuery/userDBQuery',
      payload: {
        name,
        query,
        userDBId: this.state.userDBList[this.state.selectedDBDataSourceIndex].id,
      },
    })
  }

  render() {
    return (
      <div id={cn}>

        <div id={cn.el('DB')}>
          <div>User Databases:</div>
          <select
            value={this.state.selectedDBDataSourceIndex}
            onChange={this.onUserDBSelectChange}
          >
            {this.state.userDBList.map((dbDataSource, dbDataSourceIndex) => (
              <option
                key={dbDataSourceIndex}
                value={dbDataSourceIndex}
              >
                {dbDataSource.name}
              </option>
            ))}
          </select>

          <button onClick={this.onAddUserDB}>Add User Database</button>
        </div>

        <div id={cn.el('QueryStrings')}>
          <span>Query strings:</span>
          <button onClick={this.onAddQueryString}>Add Query string</button>
          {this.state.userDBQueryList.length ? (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Query string</th>
                </tr>
              </thead>
              <tbody>
                {this.state.userDBQueryList.map((query, queryIndex) => (
                  <tr key={queryIndex}>
                    <td>{query.name}</td>
                    <td>{query.query}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : <div>empty...</div>}
        </div>

      </div>
    )
  }
}
