import axios from 'axios'
import _ from 'lodash'

import {FileInfo} from '../cmp/FileControl/class'

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

const request = (method, options, success, fail) => {
  if(!options.url) {
    throw new Error('Missing mandatory field \'options.url\'')
  }

  if(!options.isUnauthenticatedRequest) {
    const username = localStorage.getItem('username')
    const bearerToken = localStorage.getItem('bearer-token')
    axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`
    axios.defaults.headers.common['Auth-token'] = username
  }

  return axios({
    method,
    url: `/api/${options.url}`,
    data: options.payload,
  }).then(response => {
    if(success) {
      success(response.data)
    }
    else {
      return response.data
    }
  }).catch(error => {
    if(error.response && error.response.status === 401) {
      console.warn('Request 401', options.url)
      //localStorage.clear()
      window.location = '/'
    }
  })
}

export const requestAsync = (method, options, success, fail) => new Promise(resolve => request(method, options, resolve))

export const server = {
  get: (options, success, fail) => request(METHODS.GET, options, success, fail),

  post: (options, success, fail) => request(METHODS.POST, options, success, fail),

  delete: (options, success, fail) => request(METHODS.DELETE, options, success, fail),

  /***** FILE *****/

  getFileInfoList: async (fileInfoType, fileNameFilter, originalMetaFilters, path) => {
    //because modifying is needed for file_name
    const metaFilters = _.cloneDeep(originalMetaFilters)

    let url = `file/info/${fileInfoType}`
    let counter = 0

    if(fileNameFilter){
      metaFilters['FILE_NAME'] = fileNameFilter
    }

    if(path !== '') {
      metaFilters['PATH'] = path
    }

    for(let metaFilter in metaFilters){
      if(metaFilters[metaFilter] === null){
        continue
      }

      url += `${counter++ === 0 ? '?' : '&'}${metaFilter}=${encodeURIComponent(metaFilters[metaFilter])}`
    }

    const responsePayload = await requestAsync(METHODS.GET, {url})

    return {
      fileInfoList: responsePayload.fileInfoList.map(fileInfo => new FileInfo(fileInfo.fileName, fileInfo.size, fileInfo.type, fileInfo.metaList, fileInfo.uri)),
      fileCount: responsePayload.fileCount
    }
  },

  uploadFile: async(file, metaFilters, fileType, path, jobTitle) => {
    const metas = [{key: 'JOB', value: jobTitle}]

    _.each(metaFilters, (value, key) => {
      if(value !== null && key !== 'QUERY' && key !== 'JOB') {
        metas.push({key, value: value.toString()})
      }
    })

    const formData = new FormData()
    formData.append('file', file)
    formData.append('metas', JSON.stringify(metas))
    formData.append('path', path)

    axios.defaults.headers.common['Auth-token'] = localStorage.getItem('username')

    await axios.post(
      `/api/file/upload/${fileType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },

  exportFileFilter: async(payload) => {
    return await requestAsync(METHODS.POST, {
      url: 'file/exportFileFilter',
      payload,
    })
  },

  uploadImport: async(file, type) => {
    const formData = new FormData()
    formData.append('file', file)

    axios.defaults.headers.common['Auth-token'] = localStorage.getItem('username')

    await axios.post(
      `/api/file/uploadImport/${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },

  /***** CONTROL *****/

  initControl: async(jobId, controlModuleList) => {
    return await requestAsync(METHODS.POST, {
      url: 'control/init',
      payload: {
        jobId,
        controlModuleList,
      },
    })
  },

  changeControlStatus: async(id, jobId, isStart) => {
    return await requestAsync(METHODS.POST, {
      url: 'control/change_status',
      payload: {
        id,
        jobId,
        isStart,
      },
    })
  },

  pollControlStatus: async(jobId) => {
    return await requestAsync(METHODS.GET, {url: `control/poll/${jobId}`})
  },

  /***** EQUIPMENT *****/

  getEquipmentInitData: async() => {
    return await requestAsync(METHODS.GET, {
      url: 'equipment/init',
    })
  },

  /***** JOB *****/

  getJob: (jobId, success, fail) => {
    const requestSuccess = payload => success(JSON.parse(payload.job))

    return request(METHODS.GET, {url: `job/${jobId}`}, requestSuccess, fail)
  },

  postJob: (job, success, fail) => {
    return request(METHODS.POST, {
      url: `job`,
      payload: {
        id: job.id,
        title: job.title,
        job: JSON.stringify(job),
      },
    },
    success,
    fail)
  },

  putJob: (job, success, fail) => {
    return request(METHODS.PUT, {
      url: 'job',
      payload: {
        id: job.id,
        title: job.title,
        job: JSON.stringify(job),
      },
    })
  },

  getFileQueries: async() => {
    return await requestAsync(METHODS.GET, {
      url: 'job/fileQueries',
    })
  },

  getJobIdentifiers: (success, fail) => {
    return request(METHODS.GET, {url: 'job/identifiers'}, success, fail)
  },

  getJobsWithSharedUsers: async() => {
    return await requestAsync(METHODS.GET, {
      url: 'job/shared',
    })
  },

  postShareJob: async(sharedUserId, jobId) => {
    return request(METHODS.POST, {
      url: `job/share/${sharedUserId}/${jobId}`
    })
  },

  deleteShareJob: async(sharedUserId, jobId) => {
    return request(METHODS.DELETE, {
      url: `job/share/${sharedUserId}/${jobId}`
    })
  },

  getUsers: async() => {
    return await requestAsync(METHODS.GET, {
      url: 'job/users',
    })
  },

  /***** AUTH *****/

  postLogin: (payload, success, fail) => {
    return request(METHODS.POST, {
      url: 'auth/login',
      payload,
      isUnauthenticatedRequest: true,
    }, success, fail)
  },

  /***** BLOB *****/

  getThumbnails: path => request(METHODS.GET, {url: `blob/thumbnails/${path}`}),

  getFileNames: (type, success, fail) => {
    return request(METHODS.GET, {url: `blob/filenames/${type}`}, success, fail)
  },

  postAnnotatorProject: async(saveFileContent, container) => {
    return await requestAsync(METHODS.POST, {
      url: `blob/annotator/project?container=${container}`,
      payload: {saveFileContent},
    })
  },

  /***** IMAGE *****/

  postImageApp: async(payload) => {
    return await requestAsync(METHODS.POST, {
      url: 'image/app',
      payload,
    })
  },

  getServiceAppSettings: async() => {
    return await requestAsync(METHODS.GET, {
      url: 'image/appSettings',
    })
  },

  getImages: async(fileNameFilter, metaFilters, page, imageContainer) => {
    let url = `image/page/${page}`
    let hasFilenameFilter = false
    let hasMetaFilter = false

    if(fileNameFilter !== '') {
      hasFilenameFilter = true
      url += `?filename=${fileNameFilter}`
    }

    metaFilters.forEach(metaFilter => {
      if(!hasMetaFilter && !hasFilenameFilter) {
        hasMetaFilter = true
        url += `?meta[${metaFilter.key}]=${metaFilter.value}`
      }
      else {
        url += `&meta[${metaFilter.key}]=${metaFilter.value}`
      }
    })

    if(imageContainer) {
      if(hasMetaFilter || hasFilenameFilter) {
        url += `&container=${imageContainer}`
      }
      else {
        url += `?container=${imageContainer}`
      }
    }

    const responsePayload = await requestAsync(METHODS.GET, {url})
    return responsePayload
  },

  updateImageMeta: async(fileNameList, meta, container) => {
    await requestAsync(METHODS.PATCH, {
      url: `image/meta`,
      payload: {
        container,
        fileNameList,
        metaKey: meta.key,
        metaValue: meta.value,
      },
    })
  },

  deleteImageMeta: async(fileName, metaKey, container) => {
    const url = `image/meta/${metaKey}`

    await requestAsync(METHODS.DELETE, {
      url,
      payload: {
        fileNameList: [fileName],
        container,
      },
    })
  },

  // TODO: Function and API endpoint names don't match, yet, fix it!
  getFileContainers: async() => {
    return await requestAsync(METHODS.GET, {
      url: 'image/containers',
    })
  },

  postDesignerApp: async(payload) => {
    return await requestAsync(METHODS.POST, {
      url: 'image/designerApp',
      payload,
    })
  },

  /***** FILE 2 *****/

  getFiles: async(container, fileNameFilter, metaFilters, page) => {
    let url = `file2/info?container=${container}`

    if(fileNameFilter !== '') {
      url += `&filename=${fileNameFilter}`
    }

    if(metaFilters.length > 0) {
      metaFilters.forEach(metaFilter => {
        url += `&meta[${metaFilter.key}]=${metaFilter.value}`
      })
    }

    if(page !== 0) {
      url += `&page=${page}`
    }

    const responsePayload = await requestAsync(METHODS.GET, {url})
    return responsePayload
  },

  updateFileMeta: async(container, fileNameList, meta) => {
    await requestAsync(METHODS.PATCH, {
      url: `file2/meta`,
      payload: {
        container,
        fileNameList,
        metaKey: meta.key,
        metaValue: meta.value,
      },
    })
  },

  deleteFileMeta: async(container, fileName, metaKey) => {
    await requestAsync(METHODS.DELETE, {
      url: `file2/meta/${metaKey}?container=${container}`,
      payload: {
        fileNameList: [fileName],
      },
    })
  },

  /**
   * @param file {File} - Instance of browser's File interface
   * @param container {string} - File container in storage
   * @param metadata {Object[]} - File metadata key-value list
   * @param metadata.key {string} - File metadata key
   * @param metadata.value {string} - File metadata value
   * @param path {string} - File path in storage
   * @param jobTitle - Used for setting default metadata 'JOB'
   */
  uploadFile2: async(file, container, metadata, path, jobTitle) => {
    const metaList = _.cloneDeep(metadata)

    metaList.push({key: 'JOB', value: jobTitle})

    const formData = new FormData()
    formData.append('content', file)
    formData.append('metaList', JSON.stringify(metaList))
    formData.append('path', path)
    formData.append('container', container)

    axios.defaults.headers.common['Auth-token'] = localStorage.getItem('username')

    await axios.post(
      `/api/file2/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  },

  deleteFile2: async(container, file) => {
    await requestAsync(METHODS.DELETE, {
      url: `file2?container=${container}&file=${file}`,
    })
  },
}
