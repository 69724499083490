import React from 'react'
import PropTypes from 'prop-types'
import {Machine} from 'xstate'
import {useMachine} from '@xstate/react'
import _ from 'lodash'
import {toast, ToastContainer} from 'react-toastify'
import FileFilter from './FileFilter'
import FileMeta from './FileMeta'
import ServiceApps from 'cmp/ServiceApps'
import FileDisplay from './FileDisplay'
import TransferBar from './TransferBar'
import {EVENT, fileManagerStatechart, fileManagerStatechartOptions} from './fileManagerStatechart'
import {FileDisplayFilter} from './FileDisplayFilter'
import {server} from 'utils/server'
import {DataSource} from 'model/dataSource'
import './FileManager.css'

const TAB = {
  FILTER: 'FILTER',
  META: 'META',
  APPS: 'APPS',
}

export const FileManager = ({
  containers,
  job,
  appSettings,
  onFileSelect,
  isDataSourceManager = false,
  dataSources,
}) => {
  fileManagerStatechart.context.fileContainer = containers[0].containerId
  fileManagerStatechart.context.dataSources = dataSources

  /* region STATE */
  const [state, send, service] = useMachine(Machine(fileManagerStatechart, fileManagerStatechartOptions))
  const [activeTab, setActiveTab] = React.useState(TAB.FILTER)
  const [fileSubset, setFileSubset] = React.useState('FILTERED')
  /* endregion STATE */

  /* region CALLBACKS */
  const selectedFiles = state.context.fileInfoList.filter((fileInfo, fileInfoIdx) => state.context.selectedFileIdxList.includes(fileInfoIdx))

  const onSetFileNameFilter = fileNameFilter => send(EVENT.UPDATE_USER_INPUTS, {data: {fileNameFilter}})
  const onSetMetaFilterList = metaFilterList => send(EVENT.UPDATE_USER_INPUTS, {data: {metaFilterList}})
  const onSearch = () => {
    send([
      {type: EVENT.UPDATE_USER_INPUTS, data: {isStaticMode: false}},
      {type: EVENT.LOAD_FILES},
    ])
  }
  const onSelectFileContainer = fileContainer => send(EVENT.UPDATE_USER_INPUTS, {data: {fileContainer}})

  const onAddFileMeta = meta => send(EVENT.UPDATE_META, {meta})
  const onRemoveFileMeta = metaIdx => send(EVENT.DELETE_META, {metaIdx})
  const onToggleStaticMode = () => send(EVENT.UPDATE_USER_INPUTS, {data: {isStaticMode: !state.context.isStaticMode}})

  const onSetPage = page => send([
    {type: EVENT.UPDATE_USER_INPUTS, data: {
      page,
      isStaticMode: false,
    }},
    {type: EVENT.LOAD_FILES},
  ])

  /**
   * Toggles presence of file index in Statechart's context: 'selectedFileIdxList'.
   * @param fileIdx {number}
   */
  const onToggleFileIdx = (fileIdx, isChecked) => {
    const fileName = state.context.fileInfoList[fileIdx].fileName

    isDataSourceManager && onFileSelect(isChecked, fileName, state.context.fileContainer)
    const selectedFileIdxList = toggleFileIdxList(state.context.selectedFileIdxList, fileIdx)

    send(EVENT.UPDATE_USER_INPUTS, {data: {selectedFileIdxList}})
  }

  const onTransferBarDone = e => {
    send(EVENT.LOAD_FILES)
  }

  const getImagesToSend = () => {
    let imagesToSend = []

    switch(fileSubset) {
      case 'FILTERED':

        state.context.selectedFileIdxList.forEach(i => imagesToSend.push(state.context.fileInfoList[i].uri))
        break

      case 'PAGINATED':
        imagesToSend = state.context.fileInfoList.map(f => f.uri)
        break

      case 'ALL':
        imagesToSend = state.context.imageURIList
        break

      default:
        throw new Error(`Unknown image subset: ${fileSubset}`)
    }

    return imagesToSend
  }

  const onAppStart = async(appSettingsId) => {
    const settings = appSettings.find(a => a.id === appSettingsId)
    const payload = {
      imgs: getImagesToSend(),
      projectName: job.title,
      endpoint: settings.endpoint,
      appUiUrl: settings.appUiUrl,
      appName: settings.appName,
      container: job.containerId,
    }

    const responsePayload = await server.postImageApp(payload)
    let appUiUrl

    if(responsePayload && responsePayload.app_url) {
      appUiUrl = responsePayload.app_url
    }
    else {
      appUiUrl = settings.appUiUrl
    }

    toast.dark(`${settings.appName} App started`)

    if(appUiUrl) {
      try {
        window.open(appUiUrl, '_blank').focus()
      }
      catch(e) {
        console.error(e)
        alert('There was a problem while trying to open the App. Try again later!')
      }
    }

    return responsePayload
  }

  /* endregion CALLBACKS */

  /* region LOGGING */
  React.useEffect(() => service.subscribe(state => console.log(state)).unsubscribe, [service])
  /* endregion LOGGING */

  return (
    <div id="FileManager">
      <div className="filterBar">
        <ul className="tabs">
          <li
            className={activeTab === TAB.FILTER ? 'active' : undefined}
            onClick={() => setActiveTab(TAB.FILTER)}
          >Filter</li>
          <li
            className={activeTab === TAB.META ? 'active' : undefined}
            onClick={() => setActiveTab(TAB.META)}
          >Meta</li>
          <li
            className={activeTab === TAB.APPS ? 'active' : undefined}
            onClick={() => setActiveTab(TAB.APPS)}
          >Apps</li>
        </ul>
        <div className="filterBarContent">
          <div
            className="FileManager__TabContainer"
            style={{display: activeTab === TAB.FILTER ? null : 'none'}}
          >
            <FileFilter
              fileNameFilter={state.context.fileNameFilter}
              metaFilterList={state.context.metaFilterList}
              fileContainers={containers}
              fileContainer={state.context.fileContainer}
              onSelectFileContainer={onSelectFileContainer}
              setFileNameFilter={onSetFileNameFilter}
              setMetaFilterList={onSetMetaFilterList}
              onSearch={onSearch}
            />
          </div>
          <div
            className="FileManager__TabContainer"
            style={{display: activeTab === TAB.META ? null : 'none'}}
          >
            <FileMeta
              fileInfo={selectedFiles[0]}
              selectedFileCount={selectedFiles.length}
              isStaticMode={state.context.isStaticMode}
              addMeta={onAddFileMeta}
              removeMeta={onRemoveFileMeta}
              onToggleStaticMode={onToggleStaticMode}
            />
          </div>
          <div
            className="FileManager__TabContainer"
            style={{display: activeTab === TAB.APPS ? null : 'none'}}
          >
            <div className="imageAppRadioContainer">
              <div>
                <input
                  id="SendAppFiltered"
                  type="radio"
                  className="withLabel"
                  checked={fileSubset === 'FILTERED'}
                  onChange={() => setFileSubset('FILTERED')}
                />
                <label className="appLabel" htmlFor="SendAppFiltered">Filtered</label>
              </div>

              <div>
                <input
                  id="SendAppPaginated"
                  type="radio"
                  className="withLabel"
                  checked={fileSubset === 'PAGINATED'}
                  onChange={() => setFileSubset('PAGINATED')}
                />
                <label className="appLabel" htmlFor="SendAppPaginated">Paginated</label>
              </div>

              <div>
                <input
                  id="SendAppAll"
                  type="radio"
                  className="withLabel"
                  checked={fileSubset === 'ALL'}
                  onChange={() => setFileSubset('ALL')}
                />
                <label className="appLabel" htmlFor="SendAppAll">All</label>
              </div>
            </div>
            <ServiceApps
              appSettings={appSettings}
              onAppStart={onAppStart}
              serviceTypes={[10]}
            />
          </div>
        </div>
      </div>
      <div className="fileDisplayContainer">
        <FileDisplay
          fileInfoList={state.context.fileInfoList}
          fileFilter={new FileDisplayFilter(state.context.fileNameFilter, state.context.metaFilterList)}
          doFilter={state.context.isStaticMode}
          fileCount={state.context.fileCount}
          page={state.context.page}
          selectedFileIdxList={state.context.selectedFileIdxList}
          setPage={onSetPage}
          onToggleFileIdx={onToggleFileIdx}
        />
      </div>
      <TransferBar
        key={Math.random()}
        container={state.context.fileContainer}
        metaFilterList={state.context.metaFilterList}
        path=""
        jobTitle={job.title}
        onDone={onTransferBarDone}
        selectedFiles={selectedFiles}
      />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  )
}

/* region PURE FUNCTIONS*/
/**
 * @param list {number[]}
 * @param fileIdx {number}
 */
function toggleFileIdxList(list, fileIdx) {
  const resultList = _.clone(list)
  const indexOfFileIdx = list.indexOf(fileIdx)

  if(indexOfFileIdx === -1) {
    resultList.push(fileIdx)
  }
  else {
    _.remove(resultList, item => item === fileIdx)
  }

  return resultList
}
/* endregion PURE FUNCTIONS */

FileManager.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape({
    containerId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  job: PropTypes.object.isRequired,
  appSettings: PropTypes.array.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  isDataSourceManager: PropTypes.bool,
  dataSources: PropTypes.arrayOf(PropTypes.instanceOf(DataSource)),
}
