import React from 'react'
import PropTypes from 'prop-types'
import {Machine} from 'xstate'
import {useMachine} from '@xstate/react'
import {STATE, EVENT, imageManagerStatechart, imageManagerStatechartOptions} from './imageManagerStatechart'
import {ImageDisplay} from './ImageDisplay/ImageDisplay'
import {Sidebar} from 'cmp/App/MainPage/Sidebar'
import {WorkZone} from 'cmp/App/MainPage/WorkZone'
import {ImageFilter} from './ImageFilter/ImageFilter'
import {ImageMeta} from './ImageMeta/ImageMeta'
import ServiceApps from 'cmp/ServiceApps'
import {server} from 'utils/server'
import {ImageFilter as ImageFilterClass} from './imageFilter'
import './ImageManager.css'

export const TAB = {
  FILTER: 'FILTER',
  META: 'META',
  APPS: 'APPS',
}

export const ImageManager = ({
                               activeTab,
                               onTabClick,
                               job,
                               appSettings,
                               fileContainer,
                             }) => {
  /* region STATE */
  const [state, send, service] = useMachine(Machine(imageManagerStatechart, imageManagerStatechartOptions))
  const [sidebarActiveTab, setSidebarActiveTab] = React.useState(TAB.FILTER)
  const [fileSubset, setFileSubset] = React.useState('FILTERED')
  /* endregion STATE */

  /* region CALLBACKS */
  const selectedFiles = state.context.fileInfoList.filter((fileInfo, fileInfoIdx) => state.context.selectedImageIdxList.includes(fileInfoIdx))

  const onSetFileNameFilter = fileNameFilter => send(EVENT.UPDATE_USER_INPUTS, {data: {fileNameFilter}})
  const onSetMetaFilterList = metaFilterList => send(EVENT.UPDATE_USER_INPUTS, {data: {metaFilterList}})
  const onSearch = () => {
    send([
      {type: EVENT.UPDATE_USER_INPUTS, data: {isStaticThumbMode: false}},
      {type: EVENT.LOAD_IMAGES, data: {fileContainer}},
    ])
  }

  const onAddImageMeta = meta => send(EVENT.UPDATE_META, {meta, fileContainer})
  const onRemoveImageMeta = metaIdx => send(EVENT.DELETE_META, {metaIdx, fileContainer})

  const onSetPage = page => send([
    {type: EVENT.UPDATE_USER_INPUTS, data: {
      page,
      isStaticThumbMode: false,
    }},
    {type: EVENT.LOAD_IMAGES, data: {fileContainer}},
  ])
  const onSelectImages = selectedImageIdxList => send(EVENT.UPDATE_USER_INPUTS, {data: {selectedImageIdxList}})
  const onToggleStaticThumbMode = () => send(EVENT.UPDATE_USER_INPUTS, {data: {isStaticThumbMode: !state.context.isStaticThumbMode}})

  const getImagesToSend = () => {
    let imagesToSend = []

    switch(fileSubset) {
      case 'FILTERED':

        state.context.selectedImageIdxList.forEach(i => imagesToSend.push(state.context.fileInfoList[i].uri))
        break

      case 'PAGINATED':
        imagesToSend = state.context.fileInfoList.map(f => f.uri)
        break

      case 'ALL':
        imagesToSend = state.context.imageURIList
        break

      default:
        throw new Error(`Unknown image subset: ${fileSubset}`)
    }

    return imagesToSend
  }

  const onAppStart = async(appSettingsId) => {
    const settings = appSettings.find(a => a.id === appSettingsId)

    const isAnnotatorApp = () => {
      return settings.serviceType === 40
    }

    let result

    if(isAnnotatorApp()) {
      result = await onAnnotatorAppStart(settings)
    }
    else {
      result = await onRegularAppStart(settings)
    }

    return result
  }

  /*
   App types:
   - Regular
   - Annotator
   */
  const onRegularAppStart = async(settings) => {
    const payload = {
      imgs: getImagesToSend(),
      projectName: job.title,
      endpoint: settings.endpoint,
      appUiUrl: settings.appUiUrl,
      appName: settings.appName,
      container: fileContainer,
    }

    const responsePayload = await server.postImageApp(payload)

    if(responsePayload.body === undefined) {
      console.log('App Start response has a missing property: "body"')
    }

    let appUiUrl

    if(settings.appUiUrl) {
      appUiUrl = settings.appUiUrl
    }
    else if(responsePayload && responsePayload.app_url) {
      appUiUrl = responsePayload.app_url
    }

    if(appUiUrl) {
      try {
        window.open(appUiUrl, '_blank').focus()
      }
      catch(e) {
        console.error(e)
        alert('There was a problem while trying to open the App. Try again later!')
      }
    }

    return responsePayload.body
  }

  const onAnnotatorAppStart = async(settings) => {
    const payload = {
      imgs: getImagesToSend(),
      projectName: job.title,
      endpoint: settings.endpoint,
      appUiUrl: settings.appUiUrl,
      appName: settings.appName,
      container: fileContainer,
    }

    const vggProject = await server.postImageApp(payload)
    const projectFileName = await server.postAnnotatorProject(JSON.stringify(vggProject), fileContainer)

    const token = localStorage.getItem('username')
    const vggUrl = `via.html?project=${projectFileName}&token=${token}&container=${fileContainer}`

    try {
      window.open(vggUrl, '_blank').focus()
    }
    catch(e) {
      console.error(e)
      alert('There was a problem while trying to open Annotator. Try again later!')
    }

    return projectFileName
  }
  /* endregion CALLBACKS */

  /* region LOGGING */
  //React.useEffect(() => service.subscribe(state => console.log(state)).unsubscribe, [service])
  /* endregion LOGGING */

  return (
    <React.Fragment>
      <Sidebar title="Image Meta Editor">
        <div className="WorkZone__Tabs">
          <span
            className={sidebarActiveTab === TAB.FILTER ? 'active' : undefined}
            onClick={() => setSidebarActiveTab(TAB.FILTER)}
          >Filter</span>
          <span
            className={sidebarActiveTab === TAB.META ? 'active' : undefined}
            onClick={() => setSidebarActiveTab(TAB.META)}
            style={{left: 53}}
          >Meta</span>
          <span
            className={sidebarActiveTab === TAB.APPS ? 'active' : undefined}
            onClick={() => setSidebarActiveTab(TAB.APPS)}
            style={{left: 106}}
          >Apps</span>
        </div>
        <div className="SidebarContent">

          {/********** FILTER Tab ************/}

          <div
            className="ImageManager__TabContainer"
            style={{display: sidebarActiveTab === TAB.FILTER ? null : 'none'}}
          >
            <ImageFilter
              fileNameFilter={state.context.fileNameFilter}
              metaFilterList={state.context.metaFilterList}
              setFileNameFilter={onSetFileNameFilter}
              setMetaFilterList={onSetMetaFilterList}
              onSearch={onSearch}
            />
          </div>

          {/********** META Tab ************/}

          <div
            style={{display: sidebarActiveTab === TAB.META ? null : 'none'}}
            className="ImageManager__TabContainer"
          >
            <ImageMeta
              fileInfo={selectedFiles[0]}
              selectedFileCount={selectedFiles.length}
              isStaticThumbMode={state.context.isStaticThumbMode}
              addMeta={onAddImageMeta}
              removeMeta={onRemoveImageMeta}
              onToggleStaticThumbMode={onToggleStaticThumbMode}
            />
          </div>

          {/********** Apps Tab ************/}

          <div
            style={{display: sidebarActiveTab === TAB.APPS ? null : 'none'}}
            className="ImageManager__TabContainer"
          >
            <div className="imageAppRadioContainer">
              <div>
                <input
                  id="SendAppFiltered"
                  type="radio"
                  className="withLabel"
                  checked={fileSubset === 'FILTERED'}
                  onChange={() => setFileSubset('FILTERED')}
                />
                <label className="appLabel" htmlFor="SendAppFiltered">Filtered</label>
              </div>

              <div>
                <input
                  id="SendAppPaginated"
                  type="radio"
                  className="withLabel"
                  checked={fileSubset === 'PAGINATED'}
                  onChange={() => setFileSubset('PAGINATED')}
                />
                <label className="appLabel" htmlFor="SendAppPaginated">Paginated</label>
              </div>

              <div>
                <input
                  id="SendAppAll"
                  type="radio"
                  className="withLabel"
                  checked={fileSubset === 'ALL'}
                  onChange={() => setFileSubset('ALL')}
                />
                <label className="appLabel" htmlFor="SendAppAll">All</label>
              </div>
            </div>

            <ServiceApps
              /* Random key is needed for ensuring always re-rendering ServiceApps component when switching between tabs. */
              key={Math.random()}
              appSettings={appSettings}
              onAppStart={onAppStart}
              serviceTypes={[30, 40]}
            />
          </div>

        </div>
      </Sidebar>

      <WorkZone activeTab={activeTab} onTabClick={onTabClick}>
        {state.matches(STATE.LOADING_IMAGES) ? (
          <div>Loading images...</div>
        ) : (
          <ImageDisplay
            fileInfoList={state.context.fileInfoList}
            imageFilter={new ImageFilterClass(state.context.fileNameFilter, state.context.metaFilterList)}
            fileCount={state.context.fileCount}
            page={state.context.page}
            selectedImageIdxList={state.context.selectedImageIdxList}
            setPage={onSetPage}
            selectImages={onSelectImages}
            doFilter={state.context.isStaticThumbMode}
          />
        )}
      </WorkZone>

    </React.Fragment>
  )
}

ImageManager.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  appSettings: PropTypes.arrayOf(PropTypes.shape({
    appName: PropTypes.string.isRequired,
    appDescription: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
  })).isRequired,
  fileContainer: PropTypes.string,
}
