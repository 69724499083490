import React from 'react'
import makeBem from 'bem-cx'
import PropTypes from 'prop-types'
import TagsInput from 'react-tagsinput'

import { MainPageComponent } from '../../../../cmp/App/MainPage/MainPageComponent'
import { Sidebar } from '../../../../cmp/App/MainPage/Sidebar'
import { WorkZone } from '../../../../cmp/App/MainPage/WorkZone'
import './Analyser.css'
import { server } from '../../../../utils/server'
import { Job } from '../../../../cmp/App/types'
import { Button } from '../../../../cmp/Button/Button'

const cn = makeBem('Analyser')
const ANALYSIS_TYPES = {
  TRAINING: 'TRAINING',
  PREDICTION: 'PREDICTION',
}

export class Analyser extends MainPageComponent {
  static propTypes = {
    job: PropTypes.shape(Job).isRequired,
  }

  state = {
    reportNames: [],
    isLoadingReportNames: true,
    reportNameFilter: '',
    jobNameFilter: [this.props.job.title],
  }

  componentDidMount(){
    server.get({url: `blob/reportnames?name=${this.props.job.title}`}, reportNames => {
      this.setState({
        isLoadingReportNames: false,
        reportNames,
      })
    })
  }

  setStateAsync = state => new Promise(resolve => this.setState(state, resolve))

  render() {
    return (
      <>
        <Sidebar title="Analyse options">
          <div className={cn.el('Sidebar')}>
            <Button
              text="Training"
              onClick={() => this.createAnalysis(ANALYSIS_TYPES.TRAINING)}
            />
            <br/>
            <Button
              text="Prediction"
              onClick={() => this.createAnalysis(ANALYSIS_TYPES.PREDICTION)}
            />
            <div>
              <input
                className={cn.el('Filter')}
                type="text"
                placeholder="File name filter"
                value={this.state.reportNameFilter}
                onChange={this.onFileNameFilterChange}
              />
            </div>
            <div className={cn.el('JobFilter')}>
              <TagsInput
                inputProps={{placeholder: "Job name filter"}}
                value={this.state.jobNameFilter}
                onChange={async (jobNameFilter) => {
                  await this.setStateAsync({
                    jobNameFilter,
                    isLoadingReportNames: true,
                  })

                  let query = ''
                  this.state.jobNameFilter.forEach(name => {
                    query+= `name=${name}&`
                  })

                  // query.slice(0, -1) removes the last '&' or '?'
                  server.get({url: `blob/reportnames?${query.slice(0, -1)}`}, reportNames => {
                    this.setState({
                      isLoadingReportNames: false,
                      reportNames,
                    })
                  })
                }}
              />
            </div>
          </div>
        </Sidebar>
        <WorkZone activeTab={this.props.activeTab} onTabClick={this.props.onTabClick}>
          {this.state.isLoadingReportNames ? <div>Please wait...</div> : this.mapFilteredReportNames()}
        </WorkZone>
      </>
    )
  }

  createAnalysis = async(analysisType) => {
    if(window.confirm('Job will be closed. Continue?')) {
      await server.post({url: `job/analysis/${this.props.job.id}/${analysisType}`})
      alert('Analysis created')
    }
  }

  onFileNameFilterChange = e => this.setState({reportNameFilter: e.target.value})

  mapFilteredReportNames = () => {
    const reportNameElements = this.state.reportNames.filter(reportName => {
      return reportName.name.match(new RegExp(`${this.state.reportNameFilter}`, 'gi'))
    }).map((reportName, reportNameIndex) => {
      const href = reportName.uri

      return (
        <div key={reportNameIndex}>
          <a href={href} target="_blank" rel="noopener noreferrer">{reportName.name}</a>
        </div>
      )
    })

    if(reportNameElements.length === 0) {
      return <div>Support data list is empty...</div>
    }
    else {
      return reportNameElements
    }
  }
}
