import React from 'react'
import PropTypes from 'prop-types'

import { MainPageComponent } from '../../../../cmp/App/MainPage/MainPageComponent'
import { Sidebar } from '../../../../cmp/App/MainPage/Sidebar'
import { WorkZone } from '../../../../cmp/App/MainPage/WorkZone'
import { server } from '../../../../utils/server'
import { Button } from '../../../../cmp/Button/Button'
import './Control.css'

export class Control extends MainPageComponent {
  static propTypes = {
    jobId: PropTypes.number.isRequired,
    equipmentList: PropTypes.array.isRequired,
  }

  state = {
    isLoading: false,
    equipmentList: [],
  }

  setStateAsync = state => new Promise(resolve => this.setState(state, resolve))

  async componentDidMount() {
    await this.setStateAsync({isLoading: true})

    const equipmentList = await server.initControl(this.props.jobId, this.props.equipmentList
      .filter(e => e.isControlModule)
      .map(e => ({
        id: e.id,
        type: e.type,
      }))
    )

    await this.setStateAsync({
      isLoading: false,
      equipmentList,
    })

    this.pollInterval = setInterval(async() => {
      const equipmentList = await server.pollControlStatus(this.props.jobId)
      this.setStateAsync({equipmentList})
    }, 3000)
  }

  componentWillUnmount(){
    clearInterval(this.pollInterval)
  }

  render() {
    const findEquipmentName = equipmentId => {
      return this.props.equipmentList.find(e => e.id === equipmentId).name
    }

    const equipmentList = this.state.equipmentList.map(e => {
      e.name = findEquipmentName(e.id)
      return e
    })

    const workZoneContent = this.state.isLoading ? <div>Loading...</div> : (
      <div id="Control">
        {equipmentList.map(equipment => {
          let moduleStatusMessage

          if(equipment.moduleCommand === 0) {
            if(equipment.moduleStatus === 0){
              moduleStatusMessage = 'stopped'
            }
            else {
              moduleStatusMessage = 'running'
            }
          }
          else {
            if(equipment.moduleCommand === 1){
              moduleStatusMessage = 'server starting...'
            }
            else {
              moduleStatusMessage = 'server finishing...'
            }
          }

          return (
            <div
              key={equipment.id}
              className="controllerEquipment"
            >
              <div>{equipment.name}</div>
              <div>Status: {moduleStatusMessage}</div>
              <div className="endpoint">
                <a
                  href={equipment.endpoint}
                  target="_blank"
                  rel="noopener noreferrer"
                >Go to Endpoint</a>
              </div>
              <Button
                text="Start"
                disabled={equipment.moduleCommand !== 0 || equipment.moduleStatus === 1}
                onClick={() => this.changeControlStatus(equipment.id, true)}
              />
              <Button
                text="Stop"
                disabled={equipment.moduleCommand !== 0 || equipment.moduleStatus === 0}
                onClick={() => this.changeControlStatus(equipment.id, false)}
              />
            </div>
          )
        })}
      </div>
    )

    return (
      <>
      <Sidebar title="Control options">
        <div></div>
      </Sidebar>
      <WorkZone activeTab={this.props.activeTab} onTabClick={this.props.onTabClick}>
        {workZoneContent}
      </WorkZone>
      </>
    )
  }

  changeControlStatus = async(equipmentId, isStart) => {
    await this.setStateAsync({isLoading: true})
    const equipmentList = await server.changeControlStatus(equipmentId, this.props.jobId, isStart)
    this.setState({
      isLoading: false,
      equipmentList,
    })
  }
}
