/** @class */
export class EquipmentProperty {
  /**
   * @param json
   *
   * @param json.key {string}
   * @param json.label {string}
   * @param json.description {string}
   */
  constructor(json) {
    json = json || {}

    this.key = json.key
    this.label = json.label
    this.description = json.description
  }
}
