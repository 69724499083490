import React from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'
import ReactTooltip from 'react-tooltip'

import {FIELD_TYPE} from 'model/constants'
import {Equipment, EquipmentContainer, Field, Constant} from 'cmp/App/types'
import {MainPageContextConsumer} from 'cmp/App/MainPage/MainPage'
import {Button} from 'cmp/Button/Button'
import {Modal} from 'cmp/Modal'
import './EquipmentSettings.css'
import {uuid} from 'utils/uuid'
import {capitalizeFirstCharacter} from 'utils/stringManipulation'

const cn = makeBem('EquipmentSettings')

const canRemoveEquipment = equipmentContainer => {
  return equipmentContainer.countMin < equipmentContainer.equipmentList.length
}

const canAddEquipment = equipmentContainer => {
  return equipmentContainer.countMax > equipmentContainer.equipmentList.length
}

export const EquipmentSettings = props => (
  <div className="EquipmentSettings">
    {!!props.equipment ? (
      <MainEquipment mainEquipment={props.equipment}/>
    ) : (
      <div>No equipment is selected</div>
    )}
  </div>
)

EquipmentSettings.propTypes = {
  equipment: PropTypes.shape(Equipment),
}

export const MainEquipment = props => (
  <MainPageContextConsumer>
    {mainPageContext => (
      <div className={cn.el('MainEquipment')}>
        <div className={cn.el('Headline')}>
          <div className={cn.el('Headline').el('Name')}>{props.mainEquipment.name}</div>
          <div>
            <FieldManager
              equipment={props.mainEquipment}
              onChangeEquipmentField={(fieldKey, actionType, fieldType) => mainPageContext.changeEquipmentField(props.mainEquipment.id, fieldKey, actionType, fieldType)}
              onChangeEquipmentSettings={settings => mainPageContext.setEquipmentSettings(props.mainEquipment.id, settings)}
            />
            <i
              className="fas fa-trash"
              onClick={() => {
                mainPageContext.deleteMainEquipment(props.mainEquipment.id)
              }}
            />
          </div>
        </div>
        <div className={cn.el('Constant')}>
          description
          <input
            type="text"
            value={props.mainEquipment.description}
            onChange={e => {
              mainPageContext.setEquipmentDescription(props.mainEquipment.id, e.target.value)
            }}
          />
        </div>

        <EquipmentComponent
          equipment={props.mainEquipment}
          isMainEquipment={true}
          canRemove={false}
          // Not for removing Main Equipment!
          onRemove={() => {}}
        />

        <div>
          {props.mainEquipment.equipmentContainers.map((equipmentContainer, index) => (
            <EquipmentContainerComponent
              key={index}
              equipmentContainer={equipmentContainer}
              addEquipment={() => {
                mainPageContext.addSubEquipment(props.mainEquipment.id, equipmentContainer.type)
              }}
              removeEquipment={(equipmentToRemoveId, equipmentType) => {
                mainPageContext.removeSubEquipment(equipmentToRemoveId, props.mainEquipment.id, equipmentType)
              }}
            />
          ))}
        </div>

      </div>
    )}
  </MainPageContextConsumer>
)

MainEquipment.propTypes = {
  mainEquipment: PropTypes.shape(Equipment).isRequired,
}

export const EquipmentField = props => {
  let input

  switch (props.field.type) {
    case FIELD_TYPE.STRING:
      input = (
        <input
          type="text"
          value={props.field.value}
          onChange={e => {
            props.onChange(e.target.value)
          }}
        />
      )
      break

    case FIELD_TYPE.BOOL:
      input = (
        <input
          type="checkbox"
          checked={props.field.value}
          onChange={e => {
            props.onChange(e.target.checked)
          }}
        />
      )
      break

    case FIELD_TYPE.INT:
      input = (
        <input
          type="number"
          value={props.field.value}
          onChange={e => {
            props.onChange(Number(e.target.value))
          }}
        />
      )
      break

    case FIELD_TYPE.REAL:
      input = (
        <input
          type="number"
          value={props.field.value}
          onChange={e => {
            props.onChange(Number(e.target.value))
          }}
          step="0.01"
        />
      )
      break

    default:
      input = (
        <input
          type="text"
          value={props.field.value}
          onChange={e => {
            props.onChange(e.target.value)
          }}
        />
      )
  }

  const tooltipId = uuid()

  return (
    <div
      className={cn.el('Field')}
      data-tip
      data-for={tooltipId}
    >
      <span className={cn.el('Field').el('Label')}>{props.field.label}</span>
      {input}
      <ReactTooltip
        id={tooltipId}
        delayShow={300}
      >
        <div>{props.field.description || ''}</div>
      </ReactTooltip>
    </div>
  )
}
EquipmentField.propTypes = {
  field: PropTypes.shape(Field).isRequired,
  onChange: PropTypes.func.isRequired,
}

const EquipmentConstant = props => {
  const tooltipId = uuid()

  return (
    <div
      className={cn.el('Constant')}
      data-tip
      data-for={tooltipId}
    >
      {props.constant.label}
      <input
        type="number"
        value={props.constant.value}
        onChange={e => {
          props.onValueChange(e.target.value)
        }}
      />
      <select
        value={props.constant.quantity.unit || ''}
        onChange={e => {
          props.onUnitChange(e.target.value)
        }}
      >
        {props.constant.quantity.units.map(unit => (
          <option key={unit} value={unit}>{unit}</option>
        ))}
      </select>
      <ReactTooltip
        id={tooltipId}
        delayShow={300}
      >
        <div>{props.constant.description}</div>
      </ReactTooltip>
    </div>
  )
}

EquipmentConstant.propTypes = {
  constant: PropTypes.shape(Constant).isRequired,
  onValueChange: PropTypes.func.isRequired,
  onUnitChange: PropTypes.func.isRequired,
}

const EquipmentContainerComponent = props => {
  return (
    <div>
      <hr/>
      <div className={cn.el('Field') + ' ' + cn.el('bold')}>
        {capitalizeFirstCharacter(props.equipmentContainer.label)}
      </div>
      {props.equipmentContainer.equipmentList.map((equipment, index) => (
        <div key={equipment.id}>
          <EquipmentComponent
            equipment={equipment}
            canRemove={canRemoveEquipment(props.equipmentContainer)}
            onRemove={() => props.removeEquipment(equipment.id, props.equipmentContainer.type)}
            isMainEquipment={false}
          />
        </div>
      ))}
      {canAddEquipment(props.equipmentContainer) && (
        <Button
          text={`Add ${props.equipmentContainer.label}`}
          onClick={props.addEquipment}
        />
      )}
    </div>
  )
}

EquipmentContainerComponent.propTypes = {
  equipmentContainer: PropTypes.shape(EquipmentContainer).isRequired,
  addEquipment: PropTypes.func.isRequired,
  removeEquipment: PropTypes.func.isRequired,
}

export const EquipmentComponent = props => {
  return (
    <MainPageContextConsumer>
      {context => (
        <div>
          <div className={cn.el('icons')}>
            {!props.isMainEquipment && (
              <FieldManager
                equipment={props.equipment}
                onChangeEquipmentField={(fieldKey, actionType, fieldType) => context.changeEquipmentField(props.equipment.id, fieldKey, actionType, fieldType)}
                onChangeEquipmentSettings={settings => context.setEquipmentSettings(props.equipment.id, settings)}
              />
            )}
            {props.canRemove && (
              <i
                className="fas fa-trash"
                onClick={props.onRemove}
              />
            )}
          </div>
          <div className={cn.el('NameField')}>
            <span>name</span>
            <input
              type="text"
              value={props.equipment.name}
              onChange={e => {
                context.setEquipmentName(props.equipment.id, e.target.value)
              }}
            />
          </div>
          {props.equipment.fields.map((field, index) => (
            <EquipmentField
              key={index}
              field={field}
              onChange={value => {
                context.setField(props.equipment.id, field.key, value)
              }}
            />
          ))}

          {props.equipment.constants.map((constant, index) => {
            return (
              <EquipmentConstant
                key={constant.key}
                constant={constant}
                onValueChange={value => {
                  context.setConstantValue(props.equipment.id, constant.key, value)
                }}
                onUnitChange={unit => {
                  context.setConstantUnit(props.equipment.id, constant.key, unit)
                }}
              />
            )
          })}

        </div>
      )}
    </MainPageContextConsumer>
  )
}

EquipmentComponent.propTypes = {
  equipment: PropTypes.shape(Equipment).isRequired,
  isMainEquipment: PropTypes.bool.isRequired,
  canRemove: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
}

const FieldManager = props => {
  const [isFieldManagerModalVisible, setFieldManagerModalVisible] = React.useState(false)
  const [settingsText, setSettingsText] = React.useState(props.equipment.settings)

  const equipmentType = window.minealytics.db_equipment.find(e => e.name === props.equipment.type)
  // TODO: parse in server.js!
  const equipmentSettings = JSON.parse(equipmentType.settings)

  /**
   * Add/Remove Field button click handler.
   *
   * @param fieldKey {string}
   * @param actionType {('ADD'|'REMOVE')}
   * @param fieldType {('FIELD'|'CONSTANT'|'VARIABLE')}
   */
  const onChangeField = (fieldKey, actionType, fieldType) => {
    props.onChangeEquipmentField(fieldKey, actionType, fieldType)
  }

  const onChangeEquipmentSettings = () => {
    props.onChangeEquipmentSettings(settingsText)
    setFieldManagerModalVisible(false)
  }

  return (
    <span className={cn.el('FieldManager')}>

      <Modal
        title={`Equipment: ${props.equipment.name}`}
        isVisible={isFieldManagerModalVisible}
        onClose={onChangeEquipmentSettings}
      >
        <div className="FieldManager__title">Properties</div>
        <div>
          {equipmentSettings.fields.map(equipmentTypeField => {
            const isSelected = !!props.equipment.fields.some(equipmentField => {
              return equipmentTypeField.key === equipmentField.key
            })

            return (
              <div key={equipmentTypeField.key}>
                <input
                  className="FieldManager__checkbox"
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => onChangeField(equipmentTypeField.key, isSelected ? 'REMOVE' : 'ADD', 'FIELD')}
                />
                <span>{equipmentTypeField.label}</span>
              </div>
            )
          })}
          {equipmentSettings.constants.map(equipmentTypeConstant => {
            const isSelected = !!props.equipment.constants.some(equipmentConstant => {
              return equipmentTypeConstant.key === equipmentConstant.key
            })

            return (
              <div key={equipmentTypeConstant.key}>
                <input
                  className="FieldManager__checkbox"
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => onChangeField(equipmentTypeConstant.key, isSelected ? 'REMOVE' : 'ADD', 'CONSTANT')}
                />
                <span>{equipmentTypeConstant.label}</span>
              </div>
            )
          })}
        </div>
        <hr/>
        <div className="FieldManager__title">Data Points</div>
        <div>
          {equipmentSettings.variables.map(equipmentTypeVariable => {
            const isSelected = !!props.equipment.variables.some(equipmentVariable => {
              return equipmentTypeVariable.key === equipmentVariable.key
            })

            return (
              <div key={equipmentTypeVariable.key}>
                <input
                  className="FieldManager__checkbox"
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => onChangeField(equipmentTypeVariable.key, isSelected ? 'REMOVE' : 'ADD', 'VARIABLE')}
                />
                <span>{equipmentTypeVariable.label}</span>
              </div>
            )
          })}

        </div>
        <hr/>
        <div className="FieldManager__title">Settings</div>
        <textarea
          value={settingsText}
          onChange={e => setSettingsText(e.target.value)}
          cols={50}
          rows={5}
        />
      </Modal>

      <i
        className="fas fa-cog"
        onClick={() => setFieldManagerModalVisible(true)}
      ></i>

    </span>
  )
}

FieldManager.propTypes = {
  equipment: PropTypes.shape(Equipment).isRequired,
  onChangeEquipmentField: PropTypes.func.isRequired,
  onChangeEquipmentSettings: PropTypes.func.isRequired,
}
