import React from 'react'
import PropTypes from 'prop-types'
import {DropZone} from 'cmp/DragNDrop/DropZone'
import makeBem from 'bem-cx'

const cn = makeBem('AllocationTree')

export const EventAllocator = props => (
  <div className={cn.el('Variable')}>
    <div className={cn.el('Icon').mod('Variable')}>
      <i className="fas fa-ruler"></i>
    </div>
    <DropZone
      label={(
        <span>
          <i className="fas fa-clock"></i>
          <span className={cn.el('DropZoneLabel')}>{props.allocation ? props.allocation.dataSet.name : '-'}</span>
        </span>
      )}
      isEmpty={!props.allocation}
      onDrop={data => {
        const allocation = JSON.parse(data)
        if(allocation.isDelete) {
          props.removeAllocation()
        }
        else {
          props.addAllocation(allocation)
        }
      }}
    />
    <span>{props.label}</span>
  </div>
)

EventAllocator.propTypes = {
  label: PropTypes.string.isRequired,
  allocation: PropTypes.object,
  addAllocation: PropTypes.func.isRequired,
  removeAllocation: PropTypes.func.isRequired,
}
