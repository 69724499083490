import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import {uuid} from 'utils/uuid'

export const Button = props => {
  const buttonTempId = uuid()

  return (
    <>
      <button
        data-tip
        data-for={buttonTempId}
        className="button button--winona button--border-thin button--round-s"
        data-text={props.text}
        {...props}
      >
        <span>{props.text}</span>
      </button>
      {props.tooltip && (
        <ReactTooltip
          id={buttonTempId}
          delayShow={300}
        >{props.tooltip}</ReactTooltip>
      )}
    </>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
}
