/** @class */
export class Quantity {
  /**
   * @param json {object}
   *
   * @param json.unit {string}
   * @param json.units {string[]}
   */
  constructor(json) {
    if(!json.unit || !Array.isArray(json.units)) {
      throw new Error('json.unit or json.units is missing')
    }

    this.unit = json.unit
    this.units =  json.units
  }
}
