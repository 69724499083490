import React from 'react'
import PropTypes from 'prop-types'

export const FileNameFilter = props => {
  /* region CALLBACKS */
  const onFileNameFilterChange = e => props.setFileNameFilter(e.target.value)

  const onFileNameFilterClear = e => {
    props.setFileNameFilter('')
    e.target.blur()
  }

  const isInputEmpty = props.fileNameFilter === ''
  /* endregion CALLBACKS */

  return (
    <div id="FileNameFilter">
      <i
        className="fileNameFilterClear editIcon fas fa-times"
        onClick={onFileNameFilterClear}
        style={isInputEmpty ? {visibility: 'hidden'} : undefined}
      />
      <textarea
        className="fileNameFilter"
        placeholder={isInputEmpty ? 'filename (RegExp)...' : undefined}
        type="text"
        value={props.fileNameFilter}
        onChange={onFileNameFilterChange}
      />
    </div>
  )
}

FileNameFilter.propTypes = {
  fileNameFilter: PropTypes.string.isRequired,
  setFileNameFilter: PropTypes.func.isRequired,
}
