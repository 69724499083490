import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import update from 'immutability-helper'
import makeBem from 'bem-cx'
import TagsInput from 'react-tagsinput'
import {Machine, interpret} from 'xstate'

import {
  STATE,
  EVENT,
  FILE_INFO_TYPE,
  FILE_INFO_META_NAME,
  FILE_INFO_META_TYPE,
  DISCIPLINE,
  FileMetaData,
  getMetaType,
} from './class'
import {server} from '../../utils/server'
import {Button} from '../../cmp/Button/Button'
import {DataSource} from 'model/dataSource'
import Tree, {TreeNode} from '../../cmp/Tree'
import {ThumbnailDisplay} from './ThumbnailDisplay'
import './FileControl.css'

const filesCN = makeBem('Files')

export class FileControl extends React.Component {
  static propTypes = {
    jobTitle: PropTypes.string.isRequired,
    fileInfoType: PropTypes.string,
    path: PropTypes.string,
    metaFilters: PropTypes.object,
    onFilterSet: PropTypes.func,
    isDataSourceManager: PropTypes.bool,
    onSupportPick: PropTypes.func,
    onFileSelect: PropTypes.func,
    dataSources: PropTypes.arrayOf(PropTypes.instanceOf(DataSource)),
  }

  static defaultProps = {
    fileInfoType: FILE_INFO_TYPE.MEASUREMENT,
    path: '',
    metaFilters: {},
    onFilterSet: () => {},
    onFileSelect: () => {},
    isDataSourceManager: false,
    dataSources: [],
  }

  state = {
    fileInfoTypeFilter: this.props.fileInfoType,
    metaFilters: {
      JOB: this.props.jobTitle,
      TIME_VALIDITY: null,
      TYPE_SUBCLASSES: null,
      DISCIPLINES: null,
      LOCATION: this.props.metaFilters[FILE_INFO_META_NAME.LOCATION] || null,
      ASSET: this.props.metaFilters[FILE_INFO_META_NAME.ASSET] || null,
      SOURCE: null,
      SHORT_DESCRIPTION: null,
      MANUFACTURER: null,
      DOCUMENT_NUMBER: null,
      REFERENCES: null,
      USER_DATA: null,
      QUERY: this.props.metaFilters[FILE_INFO_META_NAME.QUERY] || null,
    },
    fileInfoList: [],
    showLoading: false,
    isUploading: false,
    files: [],
    importFiles: [],
    fileIndexToUpload: 0,
    path: this.props.path,
    fileCount: 0,
    selectedFileNames: this.props.dataSources.map(d => d.name),
  }

  async componentDidMount() {
    this.fileInputRef = React.createRef()
    // TODO: importFileRef may be unused
    this.importFileRef = React.createRef()
    this.fileNameFilterRef = React.createRef()

    const stateMachine = Machine({
      id: 'file state machine',
      initial: STATE.REQUESTING_FILE_LIST,
      states: {
        [STATE.REQUESTING_FILE_LIST]: {
          on: {
            [EVENT.RESPONSE_FILE_LIST]: STATE.LISTING,
          },
          entry: this.beginLoadingFileInfoList,
          exit: this.finishLoadingFileInfoList,
        },

        [STATE.LISTING]: {
          on: {
            [EVENT.UPLOAD]: STATE.UPLOADING,
            [EVENT.UPLOAD_IMPORT]: STATE.UPLOADING_IMPORT,
            [EVENT.EDIT_META]: STATE.EDITING_META,
            [EVENT.REQUEST_FILE_LIST]: STATE.REQUESTING_FILE_LIST,
            [EVENT.DELETE]: STATE.DELETING,
          },
          entry: this.clearSelections,
        },

        [STATE.UPLOADING]: {
          on: {
            [EVENT.UPLOAD]: STATE.UPLOADING,
            [EVENT.REQUEST_FILE_LIST]: STATE.REQUESTING_FILE_LIST,
          },
          entry: this.beginUpload,
          exit: this.finishUpload,
        },

        [STATE.EDITING_META]: {
          on: {
            [EVENT.REQUEST_FILE_LIST]: STATE.REQUESTING_FILE_LIST,
          },
          entry: this.beginEditMeta,
        },

        [STATE.UPLOADING_IMPORT]: {
          on: {
            [EVENT.UPLOAD_IMPORT_READY]: STATE.LISTING,
          },
          entry: this.beginImportUpload,
          exit: this.finishImportUpload,
        },

        [STATE.DELETING]: {
          on: {
            [EVENT.DELETE]: STATE.DELETING,
            [EVENT.REQUEST_FILE_LIST]: STATE.REQUESTING_FILE_LIST,
          },
          entry: this.beginDelete,
          exit: this.finishDelete,
        },
      },
    })

    this.sm = interpret(stateMachine).onTransition(state => {
      const previousStateName = state.history && state.history.value
      const nextStateName = state.value
      if(previousStateName === nextStateName) {
        console.log(`e:${state.event.type} -- No state change`)
      }
      else {
        console.log(`${state.history && state.history.value} -- e:${state.event.type} --> ${state.value}`)
      }
    })

    this.sm.start()
  }

  beginLoadingFileInfoList = async () => {
    if(this.fileInputRef.current){
      this.fileInputRef.current.value = ''
    }

    this.setState({
      showLoading: true,
      files: [],
      fileIndexToUpload: 0,
    })

    const responsePayload = await server.getFileInfoList(this.state.fileInfoTypeFilter, this.fileNameFilterRef.current ? this.fileNameFilterRef.current.value : '', this.state.metaFilters, this.state.path)
    this.sm.send(EVENT.RESPONSE_FILE_LIST, {
      fileInfoList: responsePayload.fileInfoList,
      fileCount: responsePayload.fileCount,
    })
  }

  finishLoadingFileInfoList = (context, event) => {
    this.setState({
      showLoading: false,
      fileInfoList: event.fileInfoList,
      fileCount: event.fileCount,
    })
  }

  beginUpload = async() => {
    this.setState({isUploading: true})

    await server.uploadFile(
      this.state.files[this.state.fileIndexToUpload],
      this.state.metaFilters,
      this.state.fileInfoTypeFilter,
      this.state.path,
      this.props.jobTitle,
    )

    if(this.state.fileIndexToUpload + 1 === this.state.files.length){
      alert(this.state.files.length + ' files uploaded')
      this.sm.send(EVENT.REQUEST_FILE_LIST)
    }
    else{
      this.setState({fileIndexToUpload: this.state.fileIndexToUpload + 1})
      this.sm.send(EVENT.UPLOAD)
    }
  }

  finishUpload = () => this.setState({isUploading: false})

  beginImportUpload = async() => {
    this.setState({isUploading: true})

    await server.uploadImport(this.state.importFiles[0], this.state.fileInfoTypeFilter)

    window.alert('Import file upload successful')

    this.sm.send(EVENT.UPLOAD_IMPORT_READY)
  }

  finishImportUpload = async() => {
    this.setState({
      isUploading: false,
      importFiles: [],
    })
  }

  beginEditMeta = () => this.setState({
    showLoading: true,
  })

  //could be universal
  setStateAsync = state => new Promise(resolve => this.setState(state, resolve))

  //TODO: refactor
  onMetaFilterChange = async (fileMetaData) => {
    const value = fileMetaData.value.length === 0 ? null : fileMetaData.value

    await this.setStateAsync(state => update(state, {
      metaFilters: {
        [fileMetaData.name]: {
          $set: value,
        },
      },
    }))

    this.sm.send(EVENT.REQUEST_FILE_LIST)
  }

  onPathChange = async (fileMetaData) => {
    const path = fileMetaData.value.length === 0 ? '' : fileMetaData.value
    await this.setStateAsync({path})
    this.sm.send(EVENT.REQUEST_FILE_LIST)
  }

  onExportButtonClick = async() => {
    function downloadURI(uri, name) {
      let link = document.createElement("a")
      link.download = name
      link.href = uri
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    const {path, fileName} = await server.exportFileFilter({
      path: this.state.path,
      type: this.state.fileInfoTypeFilter,
      query: this._getMetaQueryString(),
    })

    downloadURI(path, fileName)
  }

  /**
   * Converts component state's 'metaFilters' property object to query string
   *
   * @return {string}
   * @private
   */
  _getMetaQueryString = () => {
    let result = ''

    _.forEach(this.state.metaFilters, (value, key) => {
      if(value !== null) {
        if(key === FILE_INFO_META_NAME.QUERY) {
          result += `&${value}`
        }
        else {
          result += `&${key}=${value}`
        }
      }
    })

    return result.substr(1)
  }

  beginDelete = async() => {
    const wait = ms => {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    this.setState({isUploading: true})

    this.state.selectedFileNames.forEach(async(name) => {
      await server.delete({url: `blob/delete/${this.state.fileInfoTypeFilter}/${name}`})
    })

    await wait(500)

    this.sm.send(EVENT.REQUEST_FILE_LIST)
  }

  finishDelete = () => this.setState({isUploading: false})

  clearSelections = () => this.setState({selectedFileNames: this.props.dataSources.map(d => d.name)})

  render() {
    let summaryText = ''

    if(this.state.fileCount <= 100) {
      summaryText = `Showing ${this.state.fileInfoList.length} files`
    }
    else if(this.state.fileCount > 100 && this.state.fileCount < 5000) {
      summaryText = `Showing ${this.state.fileInfoList.length} files of ${this.state.fileCount}`
    }
    else {
      summaryText = `Showing ${this.state.fileInfoList.length} files of ${this.state.fileCount}+`
    }

    return (
      <div id="FileControl">
        <div className="Filters">

          <div>
            <div className="title">File name</div>
            <div className="input">
              <input
                type="text"
                id="FileControl-fileInput"
                ref={this.fileNameFilterRef}
                onKeyDown={e => {
                  if(this.sm.state.value !== STATE.LISTING) {
                    return
                  }

                  if(e.key === 'Enter' || e.key === 'Tab'){
                    this.sm.send(EVENT.REQUEST_FILE_LIST)
                  }
                }}
                onBlur={() => {
                  if(this.sm.state.value !== STATE.LISTING) {
                    return
                  }

                  this.sm.send(EVENT.REQUEST_FILE_LIST)
                }}
              />
            </div>
          </div>

          <div>
            <div className="title">Job name</div>
            <div className="input">
              <MetaInput
                meta={new FileMetaData(FILE_INFO_META_NAME.JOB, this.state.metaFilters.JOB)}
                onChange={this.onMetaFilterChange}
              />
            </div>
          </div>

          <div className="title">File type</div>
          <div className="input">
            <select
              value={this.state.fileInfoTypeFilter}
              onChange={async(e) => {
                await this.setStateAsync({fileInfoTypeFilter: e.target.value})
                this.sm.send(EVENT.REQUEST_FILE_LIST)
              }}
            >
              {_.map(FILE_INFO_TYPE, fileInfoTye => <option key={fileInfoTye} value={fileInfoTye}>{fileInfoTye}</option>)}
            </select>
          </div>

          <div>
            <div className="title">Path</div>
            <MetaInput
              meta={new FileMetaData('PATH', this.state.path)}
              onChange={this.onPathChange}
            />
          </div>

          <div>
            <div className="title">Query</div>
            <div className="input">
              <MetaInput
                meta={new FileMetaData(FILE_INFO_META_NAME.QUERY, this.state.metaFilters.QUERY)}
                onChange={this.onMetaFilterChange}
              />
            </div>
          </div>

          <Tree>
            <TreeNode label="Details">

              <div>
                <div className="title">Time validity</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.TIME_VALIDITY, this.state.metaFilters.TIME_VALIDITY)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Type subclasses</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.TYPE_SUBCLASSES, this.state.metaFilters.TYPE_SUBCLASSES)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Disciplines</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.DISCIPLINES, this.state.metaFilters.DISCIPLINES)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Location</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.LOCATION, this.state.metaFilters.LOCATION)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Asset</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.ASSET, this.state.metaFilters.ASSET)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

            </TreeNode>
            <TreeNode label="Special">

              <div>
                <div className="title">Source</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.SOURCE, this.state.metaFilters.SOURCE)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Short description</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.SHORT_DESCRIPTION, this.state.metaFilters.SHORT_DESCRIPTION)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Manufacturer</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.MANUFACTURER, this.state.metaFilters.MANUFACTURER)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">Document number</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.DOCUMENT_NUMBER, this.state.metaFilters.DOCUMENT_NUMBER)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">References</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.REFERENCES, this.state.metaFilters.REFERENCES)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

              <div>
                <div className="title">User data</div>
                <div className="input">
                  <MetaInput
                    meta={new FileMetaData(FILE_INFO_META_NAME.USER_DATA, this.state.metaFilters.USER_DATA)}
                    onChange={this.onMetaFilterChange}
                  />
                </div>
              </div>

            </TreeNode>
          </Tree>

        </div>

        <div>
          <div className={filesCN.mod('loading', this.state.showLoading)}>
            {this.state.fileInfoList.length === 0 ? <div>empty...</div> : (
              <table>
                <thead>
                <tr>
                  <th></th>
                  <th>File name</th>
                  <th>Size</th>
                  <th>Meta</th>
                </tr>
                </thead>
                <tbody>
                {this.state.fileInfoList.map(fileInfo => {

                  const metaSummarized = fileInfo.metaList.map(meta => {
                    let value = meta.value

                    if(getMetaType(meta.name) === FILE_INFO_META_TYPE.MULTI_STRING) {
                      value = meta.value.join(',')
                    }

                    return `${meta.name}=${value}`
                  }).join('\n')

                  const href = fileInfo.uri

                  return (
                    <tr key={fileInfo.fileName}>
                      <td>
                        <input
                          type="checkbox"
                          checked={_.includes(this.state.selectedFileNames, fileInfo.fileName)}
                          onChange={e => {
                            this.props.isDataSourceManager && this.props.onFileSelect(e.target.checked, fileInfo.fileName, fileInfo.type)

                            if(e.target.checked) {
                              this.setState({selectedFileNames: _.concat(this.state.selectedFileNames, fileInfo.fileName)})
                            }
                            else {
                              const selectedFileNames = _.pull(this.state.selectedFileNames, fileInfo.fileName)
                              this.setState({selectedFileNames})
                            }
                          }}
                        />
                      </td>
                      <td>
                        <a href={href} target="_blank" rel="noopener noreferrer">{fileInfo.fileName}</a>
                      </td>
                      <td>{fileInfo.size}</td>
                      <td>{metaSummarized}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            )}
          </div>
          <div className={filesCN.el('Summary')}>{summaryText}</div>
        </div>

        <div className="Upload">

          <div className="ButtonGroup">
            <Button
              text="Upload Files"
              disabled={_.isEmpty(this.state.files)}
              onClick={() => this.sm.send(EVENT.UPLOAD)}
            />
            <input
              type="file"
              ref={this.fileInputRef}
              onChange={e => this.setState({files: e.target.files})}
              multiple
            />
            {this.state.isUploading && <div>Uploading files {this.state.fileIndexToUpload + 1}/{this.state.files.length}</div>}
          </div>

          <div className="ButtonGroup">
            <Button
              text="Export"
              onClick={this.onExportButtonClick}
            />

            <Button
              text="Import"
              onClick={() => this.sm.send(EVENT.UPLOAD_IMPORT)}
              disabled={_.isEmpty(this.state.importFiles)}
            />

            <input
              type="file"
              accept=".csv"
              ref={this.importFileRef}
              onChange={e => this.setState({importFiles: e.target.files})}
            />
          </div>

          <Button
            text="Delete"
            onClick={() => this.sm.send(EVENT.DELETE)}
          />

          {this.state.fileInfoTypeFilter === FILE_INFO_TYPE.IMAGE && (
            <ThumbnailDisplay path={this.state.path}/>
          )}

        </div>
      </div>
    )
  }
}

const MetaInput = props => {
  let input
  const inputRef = React.createRef()
  const fileInfoMetaType = getMetaType(props.meta.name)

  if(fileInfoMetaType === FILE_INFO_META_TYPE.STRING){
    input = (
      <input
        ref={inputRef}
        type="text"
        defaultValue={props.meta.value}
        onKeyDown={e => {
          if(e.key === 'Enter' || e.key === 'Tab'){
            props.onChange({
              name: props.meta.name,
              value: e.target.value,
            })

            e.target.blur()
          }
        }}
        onBlur={e => props.onChange({
          name: props.meta.name,
          value: e.target.value,
        })}
      />
    )
  }
  else if(fileInfoMetaType === FILE_INFO_META_TYPE.DATETIME){
    input = (
      <input
        type="date"
        defaultValue={props.meta.value}
        onChange={e => {
          props.onChange({
            name: props.meta.name,
            value: e.target.value,
          })

          e.target.blur()
        }}
      />
    )
  }
  else if(fileInfoMetaType === FILE_INFO_META_TYPE.MULTI_STRING){
    const [tags, setTags] = React.useState(props.meta.value)

    input = (
      <TagsInput
        value={tags}
        onChange={tags => {
          props.onChange({
            name: props.meta.name,
            value: tags,
          })
          setTags(tags)
        }}
      />
    )
  }
  else if(fileInfoMetaType === FILE_INFO_META_TYPE.DISCIPLINES){
    input = (
      <select
        defaultValue={props.meta.value}
        onChange={e => {
          props.onChange({
            name: props.meta.name,
            value: e.target.value,
          })

          e.target.blur()
        }}
      >
        <option key="none" value="">none</option>
        {_.map(DISCIPLINE, discipline => <option key={discipline} value={discipline}>{discipline}</option>)}
      </select>
    )
  }

  return input
}

MetaInput.propTypes = {
  meta: PropTypes.instanceOf(FileMetaData).isRequired,
  onChange: PropTypes.func.isRequired,
}
