import React, {Component} from 'react'
import PropTypes from 'prop-types'

import './EquipmentLeaf.css'

export class EquipmentLeaf extends Component {
  render() {
    const props = this.props

    return (
      <div
        className="EquipmentLeaf draw2d_droppable"
        data-type={props.type}
      >
        <i className="fas fa-cog"></i>
        {props.label}
      </div>
    )
  }
}

EquipmentLeaf.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
