import React, {Component} from 'react'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Analysis, analyses, analysisGroups, controlAnalyses, controlAnalysisGroups } from '../../../../cmp/App/types'
import { Modal } from '../../../../cmp/Modal/index'
import { Button } from '../../../../cmp/Button/Button'
import Tree, {TreeNode} from 'cmp/Tree'

import './AnalysisSelectModal.css'

export class AnalysisSelectModal extends Component {
  state = {
    analyses: analyses.map(analysis => {
      analysis.checked = this.props.analyses.includes(analysis.key)
      return analysis
    }),
    controlAnalyses: controlAnalyses.map(analysis => {
      analysis.checked = this.props.analyses.includes(analysis.key)
      return analysis
    }),
    areAllAnalysesChecked: false,
  }

  render() {
    const state = this.state
    const props = this.props

    const onCreateButtonClick = () => props.select(
      state.analyses.filter(analysis => analysis.checked).map(analysis => analysis.name),
      state.controlAnalyses.filter(analysis => analysis.checked).map(analysis => analysis.name)
    )

    const analyses = analysisGroups.map(group => (
      <TreeNode
        key={group.name}
        label={group.name}
      >
        {state.analyses.filter(a => a.groupId === group.id).map(analysis => {
          return (
            <div key={analysis.key}>
              <label>
                <input
                  type="checkbox"
                  checked={!!state.analyses.find(a => a.key === analysis.key).checked}
                  onChange={e => {
                    const checked = e.target.checked
                    const analysisKey = state.analyses.findIndex(a => a.key === analysis.key)

                    this.setState(state => update(state, {
                      analyses: {
                        [analysisKey]: {
                          checked: {
                            $set: checked,
                          },
                        },
                      },
                      areAllAnalysesChecked: {
                        $set: false,
                      },
                    }))
                  }}
                />
                {analysis.label}
              </label>
            </div>
          )
        })}
      </TreeNode>
    ))

    const controlAnalyses = controlAnalysisGroups.map(group => (
      <TreeNode
        key={group.name}
        label={group.name}
      >
        {state.controlAnalyses.filter(a => a.groupId === group.id).map(analysis => (
          <div key={analysis.key}>
            <label>
              <input
                type="checkbox"
                checked={!!state.controlAnalyses.find(a => a.key === analysis.key).checked}
                onChange={e => {
                  const checked = e.target.checked
                  const analysisKey = state.controlAnalyses.findIndex(a => a.key === analysis.key)

                  this.setState(state => update(state, {
                    controlAnalyses: {
                      [analysisKey]: {
                        checked: {
                          $set: checked,
                        },
                      },
                    },
                    areAllAnalysesChecked: {
                      $set: false,
                    },
                  }))
                }}
              />
              {analysis.label}
            </label>
          </div>
        ))}
      </TreeNode>
    ))

    return (
      <Modal
        isVisible={true}
        onClose={() => {}}
        title="Select Modules"
      >
        <div className="AnalysisSelectModal">

          <div>
            <label>
              <input
                type="checkbox"
                checked={state.areAllAnalysesChecked}
                onChange={e => {
                  const areAllAnalysesChecked = e.target.checked
                  const newAnalyses = _.cloneDeep(state.analyses)
                  const newControlAnalyses = _.cloneDeep(state.controlAnalyses)
                  newAnalyses.forEach(analysis => analysis.checked = areAllAnalysesChecked)
                  newControlAnalyses.forEach(controlAnalysis => controlAnalysis.checked = areAllAnalysesChecked)

                  this.setState({
                    analyses: newAnalyses,
                    controlAnalyses: newControlAnalyses,
                    areAllAnalysesChecked,
                  })
                }}
              />
              Select all Analyses
            </label>
          </div>

          <div className="AnalysisSelectModal__analyses">
            {analyses}
            {controlAnalyses}
          </div>

          <div className="AnalysisSelectModal__control">
            <Button
              text="Create"
              disabled={!state.analyses.find(analysis => analysis.checked) && !state.controlAnalyses.find(controlAnalysis => controlAnalysis.checked)}
              onClick={onCreateButtonClick}
            />
            <Button
              text="Back"
              onClick={props.back}
            />
          </div>
        </div>

      </Modal>
    )
  }
}

AnalysisSelectModal.propTypes = {
  analyses: PropTypes.arrayOf(PropTypes.shape(Analysis)).isRequired,
  select: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}
