import React from 'react'
import {server} from 'utils/server'
import './ShareJob.css'

export const ShareJob = props => {
  const [isDataRefreshed, setDataRefreshed] = React.useState(false)
  const [numberOfShares, setNumberOfShares] = React.useState(0)
  const [users, setUsers] = React.useState([])
  const [jobs, setJobs] = React.useState([])
  const [jobFilterText, setJobFilterText] = React.useState('')

  React.useEffect(() => {
    async function getJobsWithSharedUsers() {
      const jobs = await server.getJobsWithSharedUsers()
      const users = await server.getUsers()
      setJobs(jobs)
      setUsers(users)
      setDataRefreshed(true)
    }

    getJobsWithSharedUsers()
  }, [numberOfShares])

  const onShare = async(userId, isShare, jobId) => {
    setDataRefreshed(false)

    if(isShare) {
      await server.postShareJob(userId, jobId)
    }
    else {
      await server.deleteShareJob(userId, jobId)
    }

    setNumberOfShares(numberOfShares + 1)
  }

  const filterJobIdentifiers = jobIdentifier => {
    let filterResult = true

    try {
      filterResult = new RegExp(jobFilterText).test(jobIdentifier.title) ||
        new RegExp(jobFilterText).test(jobIdentifier.location) ||
        new RegExp(jobFilterText).test(jobIdentifier.process)
    }
    catch(ex) {
      console.log(ex)
    }
    return filterResult
  }

  if(isDataRefreshed) {
    return (
      <div className="ShareJob">
        <input
          value={jobFilterText}
          onChange={e => setJobFilterText(e.target.value)}
          type="text"
          placeholder="Filter name, location, or process..."
        />
        <div className="tableContainer">
          <table>
            <thead>
            <tr className="title">
              <th>Job name</th>
              <th>Location</th>
              <th>Process</th>
            </tr>
            </thead>
            <tbody>
            {jobs
              .filter(filterJobIdentifiers)
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(job => (
                <tr key={job.id}>
                  <td>{job.title}</td>
                  <td>{job.location}</td>
                  <td>{job.process}</td>
                  <td>
                    <ShareUserDropdown
                      sharedUsers={job.sharedUsers}
                      allUsers={users}
                      onShare={(userId, isShare) => onShare(userId, isShare, job.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  else {
    return <div>Please, wait...</div>
  }
}

const ShareUserDropdown = props => {
  const [isOpened, setOpened] = React.useState(false)

  const isSharedWithUser = userId => {
    const foundUser = props.sharedUsers.find(user => user.userId === userId)
    return !!foundUser
  }

  return (
    <div>
      <button
        onClick={() => setOpened(!isOpened)}
      >
        {`Shared with ${props.sharedUsers.length} user(s)`}
      </button>
      {isOpened && (
        <ul className="userList">
          {props.allUsers
            .sort((a, b) => a.token.localeCompare(b.token))
            .map(user => (
              <li key={user.id}>
                <input
                  type="checkbox"
                  checked={isSharedWithUser(user.id)}
                  onChange={e => props.onShare(user.id, e.target.checked)}
                />
                <span>{user.token}</span>
              </li>
          ))}
        </ul>
      )}
    </div>
  )
}
