import React, {Component, Children} from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'

import './Tree.css'

const cn = makeBem('Tree')

const Tree = props => (
  <ul className={cn}>
    {Children.map(props.children, child => (
      <li>{child}</li>
    ))}
  </ul>
)

export default Tree

Tree.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export class TreeNode extends Component {
  state = {
    isOpened: false,
  }

  render() {
    return (
      <>
        <span
          className={cn.el('Caret').mod('caretDown', this.state.isOpened)}
          onClick={() => this.setState({isOpened: !this.state.isOpened})}
        >
          {this.state.isOpened ? <i className="fas fa-angle-down fa-fw"></i> : <i className="fas fa-angle-right fa-fw"></i>}
          {this.props.label}
        </span>
        <ul className={cn.el('Nested').mod('active', this.state.isOpened)}>
          {Children.map(this.props.children, child => (
            <li>{child}</li>
          ))}
        </ul>
      </>
    )
  }
}

//TODO: .isRequired is removed from children, put back when EquipmentTree is finished!
TreeNode.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
}
