/** @class */
export class Connection {
  /**
   * @param json {object}
   *
   * @param json.id {string} - Connection id
   * @param json.targetId {string} - Target Equipment id
   */
  constructor(json) {
    if(!json.id || !json.targetId) {
      throw new Error('json.id or json.targetId is missing')
    }

    this.id = json.id
    this.targetId = json.targetId
    this.targetOffset = {
      value: 0,
      unit: 'm',
    }
    this.type = "FLOWCONNECTION"
  }
}
