import React, {useState} from 'react'
import PropTypes from 'prop-types'

export const NewMetaInput = props => {
  /* region STATE */
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  /* endregion STATE */

  /* region CALLBACKS */
  const isAddAllowed = key !== ''

  const onAdd = () => {
    if(isAddAllowed) {
      setKey('')
      setValue('')
      props.onAdd(key, value)
    }
  }
  /* endregion CALLBACKS */

  return (
    <div className="NewMetaInput">
      <input
        type="text"
        value={key}
        onChange={e => setKey(e.target.value)}
      />
      <input
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <i
        className={`editIcon fas fa-check ${!isAddAllowed && 'disabled'}`}
        onClick={onAdd}
      />
    </div>
  )
}

NewMetaInput.propTypes = {
  onAdd: PropTypes.func.isRequired,
}
