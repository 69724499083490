import React from 'react'
import PropTypes from 'prop-types'
import TagsInput from 'react-tagsinput'
import {Machine, assign} from 'xstate'
import {useMachine} from '@xstate/react'
import {toast, ToastContainer} from 'react-toastify'
import {Button} from 'cmp/Button/Button'
import {Modal} from 'cmp/Modal'
import {UserInputs} from './UserInputs'
import {server} from 'utils/server'
import {appFilter} from 'utils/appFilter'
import {serviceAppsStatechart, EVENT, SERVICE, ACTION} from './ServiceAppsStatechart'
import './ServiceApps.css'
import 'react-toastify/dist/ReactToastify.min.css'

const sendingAppButtonText = 'Sending...'

export const ServiceApps = ({
  appSettings,
  serviceTypes,
  onAppStart,
}) => {
  const serviceAppsStatechartOptions = {
    services: {
      [SERVICE.CALL_APP_ENDPOINT]: async(context) => {
        const response = await onAppStart(context.serviceAppId)
        return response
      },
    },
    actions: {
      [ACTION.DISABLE_BUTTONS]: assign(() => {
        return {
          isSendingInProgress: true,
        }
      }),
      [ACTION.ENABLE_BUTTONS]: assign(() => {
        return {
          isSendingInProgress: false,
        }
      }),
      [ACTION.OPEN_USER_INPUTS_MODAL]: assign((context, event) => {
        return {
          userInputs: event.data.userInputs,
          isUserInputsVisible: true,
        }
      }),
      [ACTION.CLOSE_USER_INPUTS_MODAL]: assign(() => {
        return {
          isUserInputsVisible: false,
        }
      }),
      [ACTION.OPEN_RESPONSE_MODAL]: assign((context, event) => {
        return {
          isResponseVisible: true,
          responseMessage: event.data,
        }
      }),
      [ACTION.CLOSE_RESPONSE_MODAL]: assign(() => {
        return {
          isResponseVisible: false,
        }
      }),
      [ACTION.SET_APP_ID]: assign((context, event) => {
        return {
          serviceAppId: event.data.serviceAppId,
        }
      }),
      [ACTION.SHOW_APP_START_SUCCESS]: () => {
        toast.dark('App started successfully')
      },
    },
  }

  // idx = -1 -> VGG APP
  const [appFilters, setAppFilters] = React.useState([])

  const isAnnotatorApp = serviceType => serviceType === 40

  const [state, send, service] = useMachine(Machine(serviceAppsStatechart, serviceAppsStatechartOptions))

  const filteredAppSettings = appFilter(appSettings, serviceTypes, appFilters)

  /* region LOGGING */
  //React.useEffect(() => service.subscribe(state => console.log(state)).unsubscribe, [service])
  /* endregion LOGGING */

  const selectedServiceApp = appSettings.find(a => a.id === state.context.serviceAppId)

  const sortAppButtons = (a, b) => a.appName.localeCompare(b.appName)

  const onAppTriggerButtonClick = appSettings => {
    send(EVENT.SELECT, {
      data: {
        serviceAppId: appSettings.id,
        userInputs: appSettings.userInputs
      }
    })
  }

  return (
    <div className="ServiceApps">

      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />

      {state.context.isUserInputsVisible && (
        <Modal
          title="User inputs"
          isVisible={true}
          onClose={() => send(EVENT.CANCEL)}
        >
          <div>
            {selectedServiceApp && (
              <UserInputs
                userInputs={selectedServiceApp.userInputs}
                onFinish={userInputs => {
                  send(EVENT.SEND, {userInputs})
                }}
              />
            )}
          </div>
        </Modal>
      )}

      <Modal
        title="App response"
        isVisible={state.context.isResponseVisible}
        onClose={() => send(EVENT.FINISH)}
      >
        <textarea cols="40" rows="10" defaultValue={state.context.responseMessage} />
      </Modal>

      <div className="sectionTitle">Apps</div>

      <div>

        <TagsInput
          value={appFilters}
          onChange={value => setAppFilters(value)}
          inputProps={{placeholder: "Filter Apps..."}}
        />

        {filteredAppSettings
          .sort(sortAppButtons)
          .map(appSettings => (
          <Button
            key={appSettings.id}
            text={state.context.isSendingInProgress ? sendingAppButtonText : appSettings.appName}
            onClick={() => onAppTriggerButtonClick(appSettings)}
            tooltip={appSettings.appDescription}
          />
        ))}

      </div>
    </div>
  )
}

ServiceApps.propTypes = {
  appSettings: PropTypes.arrayOf(PropTypes.shape({
    appName: PropTypes.string.isRequired,
    appDescription: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
  })).isRequired,
  onAppStart: PropTypes.func.isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
}
