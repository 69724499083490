export class ImageFilter {
  constructor(fileNameFilter, metaFilterList) {
    this.fileNameFilter = fileNameFilter
    this.metaFilterList = metaFilterList
  }

  filter(fileInfo) {
    const fileNameTest = new RegExp(this.fileNameFilter).test(fileInfo.fileName)

    if(!fileNameTest) {
      return false
    }

    let metaFilterTest = true

    this.metaFilterList.forEach(metaFilter => {
      const fileInfoMeta = fileInfo.metaList.find(fileInfo => fileInfo.key === metaFilter.key)

      if(!fileInfoMeta) {
        metaFilterTest = false
        return
      }

      const fileInfoMetaTest = new RegExp(metaFilter.value).test(fileInfoMeta.value)

      if(!fileInfoMetaTest) {
        metaFilterTest = false
      }
    })

    return metaFilterTest
  }
}
