import React from 'react'

import Tree, { TreeNode } from '../../../../../cmp/Tree'
import { EquipmentLeaf } from '../../../../../cmp/EquipmentLeaf'

export const EquipmentTree = () => (
  <Tree>

    <TreeNode label="Size Reduction">
      <TreeNode label="Crushing">
        <EquipmentLeaf label="Gyratory Crusher" type="GyratoryCrusher" />
        <EquipmentLeaf label="Jaw Crusher" type="JawCrusher" />
        <EquipmentLeaf label="Cone Crusher" type="ConeCrusher" />
        <EquipmentLeaf label="Sizer" type="Sizer" />
        <TreeNode label="Rock Breakers">
            <EquipmentLeaf label="Rock Breaker" type="RockBreaker" />
        </TreeNode>
      </TreeNode>
      <TreeNode label="Grinding">
        <EquipmentLeaf label="AG Mill" type="AGMill" />
        <EquipmentLeaf label="SAG Mill" type="SAGMill" />
        <EquipmentLeaf label="Rod Mill" type="RodMill" />
        <EquipmentLeaf label="Ball Mill" type="BallMill" />
        <EquipmentLeaf label="Pebble Mill" type="PebbleMill" />
      </TreeNode>
    </TreeNode>

    <TreeNode label="Size Control">
            <TreeNode label="Screening">
                <EquipmentLeaf label="Banana Screen" type="BananaScreen" />
                <EquipmentLeaf label="Vibrating Screen" type="VibratingScreen" />
            </TreeNode>
            <TreeNode label="Classification">
              <EquipmentLeaf label="Hydrocyclone" type="Hydrocyclone" />
            </TreeNode>
    </TreeNode>

    <TreeNode label="Enrichment">
        <TreeNode label="Washing">
            <EquipmentLeaf label="Wet Screen" type="WetScreen" />
            <EquipmentLeaf label="Scrubber" type="Scrubber" />
        </TreeNode>
        <TreeNode label="Flotation">
        </TreeNode>
        <TreeNode label="Leaching">
        </TreeNode>
    </TreeNode>

    <TreeNode label="Upgrading">
        <TreeNode label="Dewatering">
        </TreeNode>
        <TreeNode label="Drying">
        </TreeNode>
        <TreeNode label="Calcining">
        </TreeNode>
        <TreeNode label="Sintering">
        </TreeNode>
    </TreeNode>

    <TreeNode label="Material handling">
      <TreeNode label="Splitters">
      </TreeNode>
      <TreeNode label="Storage">
        <EquipmentLeaf label="Bin" type="Bin" />
        <EquipmentLeaf label="Stockpile" type="Stockpile" />
        <EquipmentLeaf label="Open Tank" type="OpenTank" />
        <EquipmentLeaf label="Pressurised Tank" type="PressurisedTank" />
      </TreeNode>
      <TreeNode label="Discharging">
        <EquipmentLeaf label="Apron Feeder" type="ApronFeeder" />
        <EquipmentLeaf label="Belt Feeder" type="BeltFeeder" />
        <EquipmentLeaf label="Vibrating Feeder" type="VibratingFeeder" />
      </TreeNode>
      <TreeNode label="Conveying">
        <EquipmentLeaf label="Conveyor" type="Conveyor" />
        <EquipmentLeaf label="Shuttle Conveyor" type="ShuttleConveyor" />
      </TreeNode>
      <TreeNode label="Stacking">
        <EquipmentLeaf label="Stacker" type="Stacker" />
      </TreeNode>
      <TreeNode label="Reclaiming">
        <EquipmentLeaf label="Reclaimer" type="Reclaimer" />
      </TreeNode>
      <TreeNode label="Loading">
        <EquipmentLeaf label="Clamshell Train Loader" type="ClamshellTrainLoader" />
      </TreeNode>
    </TreeNode>

    <TreeNode label="Instruments">
        <TreeNode label="Pumps">
        </TreeNode>
        <TreeNode label="Analysers">
        </TreeNode>
        <TreeNode label="Sensors">
          <EquipmentLeaf label="Temperature" type="Sensor"/>
          <EquipmentLeaf label="Pressure" type="Sensor"/>
          <EquipmentLeaf label="Level" type="Sensor"/>
          <EquipmentLeaf label="Weight" type="Sensor"/>
          <EquipmentLeaf label="Current" type="Sensor"/>
          <EquipmentLeaf label="Voltage" type="Sensor"/>
          <EquipmentLeaf label="Irradiance" type="Sensor"/>
          <EquipmentLeaf label="Speed" type="Sensor"/>
        </TreeNode>
        <TreeNode label="Cameras">
          <EquipmentLeaf label="CCTV" type="Camera"/>
          <EquipmentLeaf label="MV" type="Camera"/>
          <EquipmentLeaf label="ToF" type="Camera"/>
        </TreeNode>
    </TreeNode>

    <TreeNode label="Mine">
      <TreeNode label="Vehicles">
      </TreeNode>
    </TreeNode>

    <TreeNode label="Energy">
      <TreeNode label="PV">
        <EquipmentLeaf label="PV Cell" type="PVCell"/>
        <EquipmentLeaf label="Charger" type="Charger"/>
        <EquipmentLeaf label="Inverter" type="Inverter"/>
        <EquipmentLeaf label="Battery" type="Battery"/>
      </TreeNode>
    </TreeNode>

    <TreeNode label="Control modules">
      <EquipmentLeaf label="Model Generator" type="ModelGenerator"/>
      <TreeNode label="Rock Breaker">
        <EquipmentLeaf label="RRR Exact Inverse Kinematics Control" type="RRRExactInverseKinematicsControl"/>
        <EquipmentLeaf label="Reinforcement Learning Inverse Kinematics Control" type="ReinforcementLearningInverseKinematicsControl"/>
        <EquipmentLeaf label="Reinforcement Evolutionary Strategy Inverse Kinematics Control" type="ReinforcementEvolutionaryStrategyInverseKinematicsControl"/>
        <EquipmentLeaf label="Kinematics Simulator Control" type="KinematicsSimulatorControl"/>
      </TreeNode>
      <TreeNode label="Crushing">
        <EquipmentLeaf label="Crushing Circuit Simulator" type="CrushingCircuitSimulator"/>
        <EquipmentLeaf label="Crushing Circuit Optimal Control" type="CrushingCircuitOptimalControl"/>
      </TreeNode>
      <TreeNode label="Flotation Bank">
        <EquipmentLeaf label="Flotation Bank Simulator" type="FlotationBankSimulator"/>
        <EquipmentLeaf label="Flotation Bank Optimal Control" type="FlotationBankOptimalControl"/>
      </TreeNode>
      <TreeNode label="Water Management">
        <EquipmentLeaf label="Water Management Simulator" type="WaterManagementSimulator"/>
        <EquipmentLeaf label="Water Management Control" type="WaterManagementControl"/>
      </TreeNode>
      <TreeNode label="Hydrocyclone">
        <EquipmentLeaf label="Hydrocyclone Simulator" type="HydrocycloneSimulator"/>
        <EquipmentLeaf label="Hydrocyclone Control" type="HydrocycloneControl"/>
      </TreeNode>
    </TreeNode>

  </Tree>
)
