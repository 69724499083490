const CONTAINER_COUNT_MAX = 1000

const ANALYSIS_KEY = {
  DRIVE_ELECTRIC_LOAD : 'DRIVE_ELECTRIC_LOAD',
  ROM_BIN_UTILISATION : 'ROM_BIN_UTILISATION',
  TONNAGE_RATE_LOOP_PERFORMANCE : 'TONNAGE_RATE_LOOP_PERFORMANCE',
  FLOTATION_AIR_INJECTION_LOOP_PERFORMANCE : 'FLOTATION_AIR_INJECTION_LOOP_PERFORMANCE',
  SURGE_BIN_UTILISATION : 'SURGE_BIN_UTILISATION',
  RECLAIMER_UTILISATION : 'RECLAIMER_UTILISATION',
  ALARM_SYSTEM_PERFORMANCE : 'ALARM_SYSTEM_PERFORMANCE',
  TOP_ALARMS : 'TOP_ALARMS',
  ROOT_CAUSE : 'ROOT_CAUSE',
  CRUSHER_DUMP_POCKET_BRIDGING : 'CRUSHER_DUMP_POCKET_BRIDGING',
  ORE_PARTICLE_SIZE_DISTRIBUTION : 'ORE_PARTICLE_SIZE_DISTRIBUTION',
  CONVEYOR_BELT_EDGE_DISTANCE : 'CONVEYOR_BELT_EDGE_DISTANCE',
  ORE_BUNCHING_HOURGLASSING_DETECTION : 'ORE_BUNCHING_HOURGLASSING_DETECTION',
  SCREENED_ORE_CONTAMINATION_DETECTION : 'SCREENED_ORE_CONTAMINATION_DETECTION',
  FLOTATION_PERFORMANCE_MONITORING : 'FLOTATION_PERFORMANCE_MONITORING',
  CONVEYOR_SPEED_CURVE_ANALYSIS : 'CONVEYOR_SPEED_CURVE_ANALYSIS',
}

const QUANTITY_TYPE = {
  ACCELERATION: {
    unit: 'm/s2',
    units: ['m/s2', 'km/s2', 'mm/s2'],
  },
  ANGLE: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
  CONCENTRATION: {
    unit: 'w/w%',
    units: ['w/w%', 'v/v%'],
  },
  CURRENT: {
    unit: "A",
    units: ["A", "kA"],
  },
  DENSITY: {
    unit: 'kg/m3',
    units: ['kg/m3', 't/m3'],
  },
  DISTANCE: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
  FREQUENCY: {
    unit: 'Hz',
    units: ['Hz', '1/min', '1/hour'],
  },
  LEVEL: {
    unit: '%',
    units: ['%', '[0-1]'],
  },
  MASS: {
    unit: 't',
    units: ['t', 'g', 'kg'],
  },
  MASS_FLOW: {
    unit: 't/h',
    units: ['t/h', 'kg/s'],
  },
  POWER: {
    unit: "kW",
    units: ["kW", "W", "MW"],
  },
  PRESSURE: {
    unit: 'Pa',
    units: ['Pa', 'kPa', 'bar', 'g', 'm'],
  },
  TEMPERATURE: {
    unit: 'Cdeg',
    units: ['Cdeg', 'K'],
  },
  TORQUE: {
    unit: 'Nm',
    units: ['Nm', 'kNm', '%'],
  },
  VELOCITY: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
  VISCOSITY: {
    unit: 'mm2/s',
    units: ['mm2/s'],
  },
  VOLTAGE: {
    unit: "V",
    units: ["V", "kV"],
  },
  VOLUME: {
    unit: 'm3',
    units: ['m3', 'l'],
  },
  VOLUME_FLOW: {
    unit: 'l/s',
    units: ['l/s', 'm3/h'],
  },
}

// Different case convention for historical reasons
const EQUIPMENT_KEY = {
  /*Equipment*/
  Bin: 'Bin',
  Conveyor: 'Conveyor',
  Belt: 'Belt',
  ElectricDrive: 'ElectricDrive',
  Weigher: 'Weigher',
  Truck: 'Truck',

  GyratoryCrusher: 'GyratoryCrusher',
  JawCrusher: 'JawCrusher',
  ConeCrusher: 'ConeCrusher',
  RockBreaker: 'RockBreaker',
  ApronFeeder: 'ApronFeeder',
  VibratingFeeder: 'VibratingFeeder',
  AGMill: 'AGMill',
  SAGMill: 'SAGMill',
  RodMill: 'RodMill',
  BallMill: 'BallMill',
  PebbleMill: 'PebbleMill',
  Hydrocyclone: 'Hydrocyclone',

  Sensor: 'Sensor',
  Camera: 'Camera',
  PVCell: 'PVCell',
  Charger: 'Charger',
  Inverter: 'Inverter',
  Battery: 'Battery',
  ModelGenerator: 'ModelGenerator',

  /*Control modules*/
  RRRExactInverseKinematicsControl: 'RRRExactInverseKinematicsControl',
  ReinforcementLearningInverseKinematicsControl: 'ReinforcementLearningInverseKinematicsControl',
  ReinforcementEvolutionaryStrategyInverseKinematicsControl: 'ReinforcementEvolutionaryStrategyInverseKinematicsControl',
  KinematicsSimulatorControl: 'KinematicsSimulatorControl',
  CrushingCircuitSimulator: 'CrushingCircuitSimulator',
  CrushingCircuitOptimalControl: 'CrushingCircuitOptimalControl',
  FlotationBankSimulator: 'FlotationBankSimulator',
  FlotationBankOptimalControl: 'FlotationBankOptimalControl',
  WaterManagementSimulator: 'WaterManagementSimulator',
  WaterManagementControl: 'WaterManagementControl',
  HydrocycloneSimulator: 'HydrocycloneSimulator',
  HydrocycloneControl: 'HydrocycloneControl',
}

const allMillsEquipmentInitData = {
  fields: [],
  constants: [
    {
      key: 'maxBearingPressure',
      label: 'max bearing pressure',
      description: '...',
      quantity: {
    unit: 'Pa',
    units: ['Pa', 'kPa', 'bar', 'g', 'm'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'maxAngle',
      label: 'max angle',
      description: '...',
      quantity: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'maxToeAngle',
      label: 'max toe angle',
      description: '...',
      quantity: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'p80',
      label: 'P80',
      description: '...',
      quantity: {
    unit: '%',
    units: ['%', '[0-1]'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'maxPower',
      label: 'max power',
      description: '...',
      quantity: {
    unit: "kW",
    units: ["kW", "W", "MW"],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'volume',
      label: 'volume',
      description: '...',
      quantity: {
    unit: 'm3',
    units: ['m3', 'l'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'maxFeedrate',
      label: 'max feedrate',
      description: '...',
      quantity: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'maxWaterAdditionFlow',
      label: 'max water addition flow',
      description: '...',
      quantity: {
    unit: 'l/s',
    units: ['l/s', 'm3/h'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
  ],
  variables: [
    {
      key: 'bearingPressure',
      label: 'bearing pressure',
      description: '...',
      quantity: {
    unit: 'Pa',
    units: ['Pa', 'kPa', 'bar', 'g', 'm'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'maxAngle',
      label: 'max angle',
      description: '...',
      quantity: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'toeAngle',
      label: 'toe angle',
      description: '...',
      quantity: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'p80',
      label: 'P80',
      description: '...',
      quantity: {
    unit: '%',
    units: ['%', '[0-1]'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'power',
      label: 'power',
      description: '...',
      quantity: {
    unit: "kW",
    units: ["kW", "W", "MW"],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'volume',
      label: 'volume',
      description: '...',
      quantity: {
    unit: 'm3',
    units: ['m3', 'l'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'weight',
      label: 'weight',
      description: '...',
      quantity: {
    unit: 't',
    units: ['t', 'g', 'kg'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
    {
      key: 'waterAdditionFlow',
      label: 'water addition flow',
      description: '...',
      quantity: {
    unit: 'l/s',
    units: ['l/s', 'm3/h'],
  },
      analysisKeys: [
        ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
      ],
    },
  ],
  containers: [
    {
      type: EQUIPMENT_KEY.ElectricDrive,
      countMin: 1,
      countMax: CONTAINER_COUNT_MAX,
    },
  ],
}

export const FIELD_TYPE = {
  STRING: 'STRING',
  BOOL: 'BOOL',
  INT: 'INT',
  REAL: 'REAL',
}

export const EQUIPMENT_INIT_DATA = {
  [EQUIPMENT_KEY.Bin]: {
    fields: [
      {
        key: 'isDualTipBin',
        label: 'is dual tip bin',
        description: '...',
        type: FIELD_TYPE.BOOL,
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    constants: [
      {
        key: 'volume',
        label: 'volume',
        description: 'live volume',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
          ANALYSIS_KEY.ALARM_SYSTEM_PERFORMANCE,
        ],
        quantity: {
    unit: 'm3',
    units: ['m3', 'l'],
  },
      },
      {
        key: 'massCapacity',
        label: 'mass capacity',
        description: 'mass capacity',
        analysisKeys: [],
        quantity: {
    unit: 't',
    units: ['t', 'g', 'kg'],
  },
      },
    ],
    variables: [
      {
        key: 'level',
        label: 'level',
        description: 'bin level',
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    containers: [
      {
        type: EQUIPMENT_KEY.Truck,
        countMin: 1,
        countMax: CONTAINER_COUNT_MAX,
      },
    ],
  },
  [EQUIPMENT_KEY.Conveyor]: {
    fields: [
      {
        key: 'isActiveSpeedControlled',
        label: 'is active speed controlled',
        description: '...',
        type: FIELD_TYPE.BOOL,
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    constants: [],
    variables: [],
    containers: [
      {
        type: EQUIPMENT_KEY.Belt,
        countMin: 1,
        countMax: 1,
      },
      {
        type: EQUIPMENT_KEY.ElectricDrive,
        countMin: 1,
        countMax: CONTAINER_COUNT_MAX,
      },
      {
        type: EQUIPMENT_KEY.Weigher,
        countMin: 0,
        countMax: CONTAINER_COUNT_MAX,
      },
    ],
  },
  [EQUIPMENT_KEY.Truck]: {
    fields: [],
    constants: [
      {
        key: 'volume',
        label: 'volume',
        description: 'truck volume',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'm3',
    units: ['m3', 'l'],
  },
      },
      {
        key: 'massCapacity',
        label: 'mass capacity',
        description: 'maximum mass capacity',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 't',
    units: ['t', 'g', 'kg'],
  },
      },
      {
        key: 'minArrivalFrequency',
        label: 'minimum arrival frequency',
        description: 'minimum frequency of truck arrivals',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'Hz',
    units: ['Hz', '1/min', '1/hour'],
  },
      },
      {
        key: 'maxArrivalFrequency',
        label: 'maximum arrival frequency',
        description: 'maximum frequency of truck arrivals',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'Hz',
    units: ['Hz', '1/min', '1/hour'],
  },
      },
    ],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.Belt]: {
    fields: [],
    constants: [
      {
        key: 'beltLength',
        label: 'belt length',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'beltWidth',
        label: 'belt width',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'designMassFlow',
        label: 'desigh mass flow',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 't/h',
    units: ['t/h', 'kg/s'],
  },
      },
      {
        key: 'maxSpeed',
        label: 'max speed',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
          ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS,
        ],
        quantity: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
      },

      {
        key: 'belt_thikcness',
        label: 'belt_thikcness',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'trough_angle',
        label: 'trough_angle',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
      },
      {
        key: 'surcharge_angle',
        label: 'surcharge_angle',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'deg',
    units: ['deg', 'rad'],
  },
      },
      {
        key: 'c_roller_len',
        label: 'c_roller_len',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'rollgap',
        label: 'rollgap',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'c_roll_diam',
        label: 'c_roll_diam',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'wing_roll_diam',
        label: 'wing_roll_diam',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
      {
        key: 'wing_roll_len',
        label: 'wing_roll_len',
        description: '...',
        analysisKeys: [ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },

    ],
    variables: [
      {
        key: 'beltSpeed',
        label: 'belt speed',
        description: '...',
        quantity: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
          ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS,
        ],
      },
      {
        key: 'totalBurden',
        label: 'total burden',
        description: '...',
        quantity: {
    unit: 't',
    units: ['t', 'g', 'kg'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'bedDepth',
        label: 'bed_depth',
        description: '...',
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },

      {
        key: 'conveyorThroughput',
        label: 'conveyor throughput',
        description: '...',
        quantity: {
    unit: 't/h',
    units: ['t/h', 'kg/s'],
  },
        analysisKeys: [
          ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS,
        ],
      },

      {
        key: 'powerPerDrive',
        label: 'power per drive',
        description: '...',
        quantity: {
    unit: "kW",
    units: ["kW", "W", "MW"],
  },
        analysisKeys: [
          ANALYSIS_KEY.CONVEYOR_SPEED_CURVE_ANALYSIS,
        ],
      },

    ],
    containers: [],
  },
  [EQUIPMENT_KEY.ElectricDrive]: {
    fields: [
      {
        key: 'nPhases',
        label: 'n phases',
        description: '...',
        type: FIELD_TYPE.INT,
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'motorEfficiency',
        label: 'motor efficiency',
        description: '...',
        type: FIELD_TYPE.INT,
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'powerFactor',
        label: 'power factor',
        description: '...',
        type: FIELD_TYPE.INT,
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    constants: [
      {
        key: 'maxTorque',
        label: 'max torque',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'Nm',
    units: ['Nm', 'kNm', '%'],
  },
      },
      {
        key: 'maxSpeed',
        label: 'max speed',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
      },
      {
        key: 'fullLoadCurrent',
        label: 'full load current',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: "A",
    units: ["A", "kA"],
  },
      },
      {
        key: 'maxLineVoltage',
        label: 'max line voltage',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: "V",
    units: ["V", "kV"],
  },
      },
      {
        key: 'maxFrequency',
        label: 'max frequency',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'Hz',
    units: ['Hz', '1/min', '1/hour'],
  },
      },
      {
        key: 'maxPower',
        label: 'max power',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: "kW",
    units: ["kW", "W", "MW"],
  },
      },
    ],
    variables: [
      {
        key: 'maxAccel',
        label: 'max acceleration',
        description: 'maximum of belt tension, slip and motor accelerations',
        quantity: {
    unit: 'm/s2',
    units: ['m/s2', 'km/s2', 'mm/s2'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'motorSpeed',
        label: 'motor speed',
        description: 'rotational speed',
        quantity: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'torque',
        label: 'torque',
        description: '...',
        quantity: {
    unit: 'Nm',
    units: ['Nm', 'kNm', '%'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'current',
        label: 'current',
        description: '...',
        quantity: {
    unit: "A",
    units: ["A", "kA"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'voltage',
        label: 'voltage',
        description: '...',
        quantity: {
    unit: "V",
    units: ["V", "kV"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'power',
        label: 'power',
        description: '...',
        quantity: {
    unit: "kW",
    units: ["kW", "W", "MW"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'frequency',
        label: 'frequency',
        description: '...',
        quantity: {
    unit: 'm/s',
    units: ['m/s', 'mm/s', 'km/h', '%'],
  },
        analysisKeys: [
          ANALYSIS_KEY.FREQUENCY,
        ],
      },
      {
        key: 'lineCurrent',
        label: 'line current',
        description: '...',
        quantity: {
    unit: "A",
    units: ["A", "kA"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'phaseCurrent',
        label: 'phase current',
        description: '...',
        quantity: {
    unit: "A",
    units: ["A", "kA"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'lineVoltage',
        label: 'line voltage',
        description: '...',
        quantity: {
    unit: "V",
    units: ["V", "kV"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'phaseVoltage',
        label: 'phase voltage',
        description: '...',
        quantity: {
    unit: "V",
    units: ["V", "kV"],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    containers: [],
  },
  [EQUIPMENT_KEY.Weigher]: {
    fields: [],
    constants: [
      {
        key: 'position',
        label: 'position',
        description: '...',
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
        quantity: {
    unit: 'm',
    units: ['m', 'mm', 'cm', 'km'],
  },
      },
    ],
    variables: [
      {
        key: 'massFlow',
        label: 'mass flow',
        description: '...',
        quantity: {
    unit: 't/h',
    units: ['t/h', 'kg/s'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    containers: [],
  },

  [EQUIPMENT_KEY.GyratoryCrusher]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.JawCrusher]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.ConeCrusher]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.RockBreaker]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.ApronFeeder]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.VibratingFeeder]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.Hydrocyclone]: {
    fields: [],
    constants: [
      {
        key: 'maxFeedFlow',
        label: 'max feed flow',
        description: '...',
        quantity: {
    unit: 'l/s',
    units: ['l/s', 'm3/h'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'maxFeedPressure',
        label: 'max feed pressure',
        description: '...',
        quantity: {
    unit: 'Pa',
    units: ['Pa', 'kPa', 'bar', 'g', 'm'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'maxFeedDensity',
        label: 'max feed density',
        description: '...',
        quantity: {
    unit: 'kg/m3',
    units: ['kg/m3', 't/m3'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    variables: [
      {
        key: 'feedFlow',
        label: 'feed flow',
        description: '...',
        quantity: {
    unit: 'l/s',
    units: ['l/s', 'm3/h'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'feedPressure',
        label: 'feed pressure',
        description: '...',
        quantity: {
    unit: 'Pa',
    units: ['Pa', 'kPa', 'bar', 'g', 'm'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
      {
        key: 'feedDensity',
        label: 'feed density',
        description: '...',
        quantity: {
    unit: 'kg/m3',
    units: ['kg/m3', 't/m3'],
  },
        analysisKeys: [
          ANALYSIS_KEY.DRIVE_ELECTRIC_LOAD,
        ],
      },
    ],
    containers: [],
  },

  [EQUIPMENT_KEY.AGMill]: allMillsEquipmentInitData,
  [EQUIPMENT_KEY.SAGMill]: allMillsEquipmentInitData,
  [EQUIPMENT_KEY.RodMill]: allMillsEquipmentInitData,
  [EQUIPMENT_KEY.BallMill]: allMillsEquipmentInitData,
  [EQUIPMENT_KEY.PebbleMill]: allMillsEquipmentInitData,

  [EQUIPMENT_KEY.Sensor]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.Camera]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.PVCell]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.Charger]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.Inverter]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.Battery]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },
  [EQUIPMENT_KEY.ModelGenerator]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
  },

  [EQUIPMENT_KEY.RRRExactInverseKinematicsControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.ReinforcementLearningInverseKinematicsControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.ReinforcementEvolutionaryStrategyInverseKinematicsControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.KinematicsSimulatorControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.CrushingCircuitSimulator]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.CrushingCircuitOptimalControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.FlotationBankSimulator]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.FlotationBankOptimalControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.WaterManagementSimulator]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.WaterManagementControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.HydrocycloneSimulator]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
  [EQUIPMENT_KEY.HydrocycloneControl]: {
    fields: [],
    constants: [],
    variables: [],
    containers: [],
    isControlModule: true,
  },
}

export const JOB_INIT_TYPE = {
  NEW: 'NEW',
  LOAD: 'LOAD',
  CLONE: 'CLONE',
}

/**
 * @enum {MG_SUPPORT_TYPE}
 */
export const MG_SUPPORT_TYPE = {
  WEIGHT: 'WEIGHT',
  MODEL: 'MODEL',
}