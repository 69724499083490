import React from 'react'
import PropTypes from 'prop-types'

import {server} from '../../utils/server'
import './ThumbnailDisplay.css'

export const ThumbnailDisplay = props => {
  const [thumbnails, setThumbnails] = React.useState([])

  React.useEffect(() => {
    async function fetchData() {
      const thumbnails = await server.getThumbnails(props.path)

      setThumbnails(thumbnails);
    }
    fetchData()
  }, [])

  return (
    <div id="ThumbnailDisplay">
      {thumbnails.map(thumbnail => (
        <img key={thumbnail.fileName} src={thumbnail.uri} alt={thumbnail.fileName} />
      ))}
    </div>
  )
}

ThumbnailDisplay.propTypes = {
  path: PropTypes.string.isRequired,
}
