import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import makeBem from 'bem-cx'

import {Plant} from 'model/plant'
import {DataSource} from 'model/dataSource'
import { Modal } from '../../../../cmp/Modal'
import { Draggable } from '../../../../cmp/DragNDrop/Draggable'
import {FileControl} from '../../../FileControl/FileControl'
import {FILE_INFO_TYPE} from '../../../FileControl/class'
import {AllocatorContextConsumer} from 'cmp/App/MainPage/Allocator/Allocator'
import {Button} from 'cmp/Button/Button'
import FileManager from '../FileManager'

const cn = makeBem('DataSourceControl')

export class DataSourceControl extends Component {
  static propTypes = {
    plant: PropTypes.instanceOf(Plant).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.instanceOf(DataSource)).isRequired,
    selectedDataSourceIndex: PropTypes.number.isRequired,
    setPlant: PropTypes.func.isRequired,
    addDataSource: PropTypes.func.isRequired,
    selectDataSourceIndex: PropTypes.func.isRequired,
  }

  state = {
    isLoadDataSourceModalVisible: false,
  }

  constructor(props) {
    super(props)
    this.dataSourceSelect = React.createRef()
  }

  render() {
    const dataSource = this.props.dataSources[this.props.selectedDataSourceIndex]
    const notSelectedDataSources = _.filter(this.props.dataSources, d => d.name !== dataSource.name)

    return (
      <AllocatorContextConsumer>
        {context => (
          <div className={cn}>
            <Modal
              isVisible={this.state.isLoadDataSourceModalVisible}
              onClose={() => this.setState({isLoadDataSourceModalVisible: false})}
              title="Add Data Source"
            >
              <FileManager
                isDataSourceManager={true}
                dataSources={this.props.dataSources}
                containers={context.containers}
                job={context.job}
                appSettings={context.appSettings}
                onFileSelect={(isSelected, fileName, container) => {
                  if(isSelected) {
                    this.props.addDataSource(fileName, container)
                  }
                  else {
                    this.props.deleteDataSource(fileName, container)
                  }
                }}
              />
              {/* <FileControl
                jobTitle={context.job.title}
                onFileSelect={(isSelected, fileName, container) => {
                  if(isSelected) {
                    this.props.addDataSource(fileName, container)
                  }
                  else {
                    this.props.deleteDataSource(fileName, container)
                  }
                }}
                isDataSourceManager={true}
                fileInfoType={FILE_INFO_TYPE.MEASUREMENT}
                dataSources={this.props.dataSources}
              /> */}
            </Modal>

            <Button
              text="Add/Delete data source"
              onClick={() => this.setState({isLoadDataSourceModalVisible: true})}
            />
            {dataSource && (
              <div>
                <div>Selected data source:</div>
                <select
                  style={{marginBottom: 16, marginRight: 8}}
                  value={this.props.selectedDataSourceIndex}
                  onChange={e => this.props.selectDataSourceIndex(Number(e.target.value))}
                >
                  {this.props.dataSources.map((dataSource, dataSourceIndex) => (
                    <option
                      key={dataSourceIndex}
                      value={dataSourceIndex}
                    >{dataSource.name}</option>
                  ))}
                </select>
                {!!notSelectedDataSources.length && (
                  <div>
                    <Button
                      text="Copy allocation from file:"
                      onClick={() => this.copyAllocation(context)}
                    />
                    <div>
                      <select
                        defaultValue={notSelectedDataSources[0].name}
                        ref={this.dataSourceSelect}
                      >
                        {notSelectedDataSources.map(dataSource => (
                          <option
                            key={dataSource.name}
                            value={dataSource.name}
                          >{dataSource.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
            <hr/>
            <div>
              <Draggable
                label="Delete"
                payload={JSON.stringify({isDelete: true})}
              />
            </div>
            <Button
              text="Reset Allocations"
              onClick={() => this.resetAllocations(context)}
            />
          </div>
        )}
      </AllocatorContextConsumer>
    )
  }

  copyAllocation = context => {
    const source = this.dataSourceSelect.current.value
    const dataSource = this.props.dataSources[this.props.selectedDataSourceIndex]
    const target = dataSource.name
    context.copyAllocation(source, target)
  }

  resetAllocations = context => {
    if(!window.confirm('Do you really want to reset all allocations?')) {
      return
    }

    context.resetAllocations()
  }
}
