export const capitalizeFirstCharacter = text => text.charAt(0).toUpperCase() + text.slice(1)

/**
 * Creates new name with incrementing numbers at the end in brackets. ('my name' -> 'my name (2)')
 * If name has already created with this function, and/or has a number in brackets in the end, this function increments the number. ('my name (23)' -> 'my name (24)')
 *
 * @param input {string} - Original name
 * @return {string} - New name with incremented number
 */
export const getIncrementedName = input => {
  const regexResult = input.match(/ \((\d*)\)$/)

  if(regexResult) {
    return regexResult.input.substring(0, regexResult.index) + ` (${++regexResult[1]})`
  }
  else {
    return input + ' (2)'
  }
}
