import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'cmp/Button/Button'

export const FilterQuery = ({
  metaFilterList,
  onFilterQueryApply,
}) => {
  const inputRef = React.useRef()

  /* region CALLBACKS */
  const onApply = () => {
    const filterQueryString = inputRef.current.value

    try {
      JSON.parse(filterQueryString)
    }
    catch(exception) {
      alert(`JSON syntax error: ${exception.message}`)
      return
    }

    onFilterQueryApply(JSON.parse(filterQueryString))
  }
  /* endregion CALLBACKS */

  React.useEffect(() => {
    inputRef.current.value = JSON.stringify(metaFilterList, null, 2)
  }, [metaFilterList])

  return (
    <div className="FilterQuery">
      <h6 className="sectionTitle">JSON import/export</h6>

      <textarea
        ref={inputRef}
        className="FilterQueryTextarea"
      />

      <div>
        <Button
          text="Apply"
          onClick={onApply}
        />
      </div>
    </div>
  )
}

FilterQuery.propTypes = {
  metaFilterList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onFilterQueryApply: PropTypes.func.isRequired,
}
