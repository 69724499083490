import _ from 'lodash'

import {uuid} from 'utils/uuid'
import {addAllocation, getAllocation} from './timeSetProvider'
import {Field} from './field'
import {Variable} from './variable'
import {Constant} from './constant'
import {EquipmentContainer} from './equipmentContainer'
import {Connection} from './connection'

/**
 * Adds/Removes Fields/Constants according to the rules set by user
 *
 * @param originalFields {[Field]} - From Equipment init data
 * @param userEquipmentSettings {object}
 * @param fieldType {('FIELD'|'CONSTANT'|'VARIABLE')}
 * @param equipmentInitData {object}
 */
function getFieldsByUserSettings(originalFields, userEquipmentSettings, fieldType, equipmentInitData) {
  const fieldResult = _.cloneDeep(originalFields)

  _.forEach(userEquipmentSettings, (value, key) => {
    const visibleField = fieldResult.find(f => f.key === key)
    if(visibleField) {
      if(!value) {
        const fieldIdx = fieldResult.findIndex(f => f.key === key)
        fieldResult.splice(fieldIdx, 1)
      }
    }
    else {
      if(value) {
        if(fieldType === 'FIELD') {
          const fieldInitData = equipmentInitData.settings.fields.find(f => f.key === key)
          fieldResult.push(new Field(fieldInitData))
        }
        else if(fieldType === 'CONSTANT') {
          const constantInitData = equipmentInitData.settings.constants.find(c => c.key === key)
          fieldResult.push(new Constant(constantInitData))
        }
        else if(fieldType === 'VARIABLE') {
          const variableInitData = equipmentInitData.settings.variables.find(v => v.key === key)
          fieldResult.push(new Variable(variableInitData))
        }
        else {
          throw new Error(`Unknown field type: ${fieldType}`)
        }
      }
    }
  })

  return fieldResult
}

/**
 * @param json {object} - No undefined! 'isNew' property for creating new Equipment,
 *                        see mandatory fields in this case in constructor!
 *
 * @class
 */
export class Equipment {
  constructor(json) {
    if(json.isNew) {
      // New 'id' may come from Draw2d, so can't generate here.
      if(json.id === undefined || json.type === undefined || json.analyses === undefined) {
        throw new Error(`Missing mandatory field`)
      }

      const userEquipmentSettings = window.minealytics.model._job.userEquipmentSettings[json.type]
      const equipmentInitDataRaw = window.minealytics.db_equipment.find(e => e.name === json.type)
      const equipmentInitData = {
        id: equipmentInitDataRaw.id,
        name: equipmentInitDataRaw.name,
        settings: JSON.parse(equipmentInitDataRaw.settings)
      }

      const fieldsFilteredByAnalyses = equipmentInitData.settings.fields.filter(field => {
        return _.intersection(field.analysisKeys, json.analyses).length > 0
      })

      const constantsFilteredByAnalyses = equipmentInitData.settings.constants.filter(constant => {
        return _.intersection(constant.analysisKeys, json.analyses).length > 0
      })

      const variablesFilteredByAnalyses = equipmentInitData.settings.variables.filter(variable => {
        return _.intersection(variable.analysisKeys, json.analyses).length > 0
      })

      json.fields = userEquipmentSettings ?
        getFieldsByUserSettings(fieldsFilteredByAnalyses, userEquipmentSettings.fields, 'FIELD', equipmentInitData) :
        fieldsFilteredByAnalyses

      json.constants = userEquipmentSettings ?
        getFieldsByUserSettings(constantsFilteredByAnalyses, userEquipmentSettings.constants, 'CONSTANT', equipmentInitData) :
        constantsFilteredByAnalyses

      json.variables = userEquipmentSettings ?
        getFieldsByUserSettings(variablesFilteredByAnalyses, userEquipmentSettings.variables, 'VARIABLE', equipmentInitData) :
        variablesFilteredByAnalyses

      json.equipmentContainers = equipmentInitData.settings.containers
        .map(containerInitData => {
          containerInitData.allocations = []
          containerInitData.equipmentList = _.range(containerInitData.countMin).map(() => {
            return new Equipment({
              isNew: true,
              id: uuid(),
              type: containerInitData.type,
              analyses: json.analyses,
            })
          })

          return new EquipmentContainer(containerInitData)
        })

      // If json contains 'name', this value will be the name, otherwise name will be the Equipment type
      if(!json.name) {
        json.name = json.type
      }

      json.description = ''
      json.connections = []
      json.isControlModule = equipmentInitData.settings.isControlModule
    }

    this.id = json.id
    this.type = json.type
    this.name = json.name
    this.description = json.description
    this.equipmentContainers = json.equipmentContainers.map(object => new EquipmentContainer(object))
    this.fields = json.fields.map(object => new Field(object))
    this.constants = json.constants.map(object => new Constant(object))
    this.variables = json.variables.map(object => new Variable(object))
    this.isControlModule = !!json.isControlModule
    this.connections = json.connections ? json.connections.map(object => new Connection(object)) : []
    this.settings = json.settings || ''

    this.allocations = []
  }

  getVariable(variableKey) {
    return this.variables.find(v => v.key === variableKey)
  }

  addConnection(targetId) {
    this.connections.push(new Connection({id: `${this.id}_${targetId}`, targetId}))
  }
}

Equipment.prototype.addAllocation = addAllocation
Equipment.prototype.getAllocation = getAllocation
