import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import {Button} from 'cmp/Button/Button'
import {FileNameFilter} from './FileNameFilter'
import {MetaFilter} from './MetaFilter'
import {FilterQuery} from './FilterQuery'

export const ImageFilter = ({
  fileNameFilter,
  setFileNameFilter,
  metaFilterList,
  setMetaFilterList,
  onSearch,
}) => {
  /* region CALLBACKS */
  const onAddMetaFilter = newMetaFilter => {
    setMetaFilterList(update(metaFilterList, {
      $push: [newMetaFilter],
    }))
  }

  const onRemoveMetaFilter = metaKey => {
    const metaFilterIdx = metaFilterList.findIndex(m => metaKey === m.key)
    setMetaFilterList(update(metaFilterList, {
      $splice: [[metaFilterIdx, 1]],
    }))
  }

  const onFilterQueryApply = ({fileNameFilter, metaFilterList}) => {
    setFileNameFilter(fileNameFilter)
    setMetaFilterList(metaFilterList)
  }
  /* endregion CALLBACKS */

  return (
    <div id="ImageFilter">

      <h6 className="sectionTitle">Filter images</h6>

      <FileNameFilter
        fileNameFilter={fileNameFilter}
        setFileNameFilter={setFileNameFilter}
      />

      <MetaFilter
        metaFilterList={metaFilterList}
        addMetaFilter={onAddMetaFilter}
        removeMetaFilter={onRemoveMetaFilter}
      />

      <Button
        text="Search"
        onClick={onSearch}
      />

      <hr/>

      <FilterQuery
        fileNameFilter={fileNameFilter}
        metaFilterList={metaFilterList}
        onFilterQueryApply={onFilterQueryApply}
      />

    </div>
  )
}

ImageFilter.propTypes = {
  fileNameFilter: PropTypes.string.isRequired,
  metaFilterList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  setFileNameFilter: PropTypes.func.isRequired,
  setMetaFilterList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}
