import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'cmp/Button/Button'

export const MetaFilter = props => {
  /* region STATE */
  const [isAddingMeta, setIsAddingMeta] = useState(false)
  const [metaInputKey, setMetaInputKey] = useState('')
  const [metaInputValue, setMetaInputValue] = useState('')
  /* endregion STATE */

  /* region CALLBACKS */
  const onAddMetaFilter = () => {
    if(isOKMetaFilterButtonEnabled) {
      props.addMetaFilter({
        key: metaInputKey,
        value: metaInputValue,
      })
      setMetaInputKey('')
      setMetaInputValue('')
      setIsAddingMeta(false)
    }
  }

  const onRemoveMetaFilter = metaKey => props.removeMetaFilter(metaKey)

  const onCancel = () => {
    setMetaInputKey('')
    setMetaInputValue('')
    setIsAddingMeta(false)
  }

  const onSwitchToNewMetaFilterInput = () => setIsAddingMeta(true)

  const onNewMetaKeyChange = e => setMetaInputKey(e.target.value)

  const onNewMetaValueChange = e => setMetaInputValue(e.target.value)

  const isOKMetaFilterButtonEnabled = metaInputKey !== '' && metaInputValue !== ''

  const hasMetaFilterItem = props.metaFilterList.length > 0
  /* endregion CALLBACKS */

  return (
    <div className="MetaFilter">
      {hasMetaFilterItem && (
        <div className="ImageManager__MetaList">
          {props.metaFilterList.map((metaFilter, metaFilterIdx) => (
            <div
              className="metaItem"
              key={metaFilterIdx}
            >
              <span className="metaKey">{metaFilter.key}</span>
              <span className="metaValue">{metaFilter.value}</span>
              <i
                className="editIcon far fa-trash-alt"
                onClick={onRemoveMetaFilter}
              />
            </div>
          ))}
        </div>
      )}

      <div className="ImageManager__Editor">
        {isAddingMeta ? (
          <div className="NewMetaInput">
            <input
              type="text"
              value={metaInputKey}
              placeholder="key..."
              onChange={onNewMetaKeyChange}
            />
            <input
              type="text"
              value={metaInputValue}
              placeholder="value (RegExp)..."
              onChange={onNewMetaValueChange}
            />
            <i
              className={`editIcon fas fa-check ${!isOKMetaFilterButtonEnabled && 'disabled'}`}
              onClick={onAddMetaFilter}
            />
            <i
              className="editIcon far fa-trash-alt"
              onClick={onCancel}
            />
          </div>
        ) : (
          <Button
            text="Add MetaFilter"
            onClick={onSwitchToNewMetaFilterInput}
          />
        )}
      </div>
    </div>
  )
}

MetaFilter.propTypes = {
  metaFilterList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  addMetaFilter: PropTypes.func.isRequired,
  removeMetaFilter: PropTypes.func.isRequired,
}
