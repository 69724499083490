/**
 * @param apps {array}
 * @param serviceTypes {array} - Array of integers: 1001 - Realtime Apps (solar), 1 - Container access,  10 - File Apps, 20 - Plant Apps, 30 - Image Apps, 40 - Annotator App
 * @param textFilterValues {array}
 */
export const appFilter = (apps, serviceTypes, textFilterValues) => {
  const filteredApps = apps.filter(app => serviceTypes.includes(app.serviceType))
    .filter(app => {
      if(textFilterValues.length === 0) {
        return true
      }

      const isMatch = text => {
        const result = app.appName.includes(text) ||
          app.appDescription.includes(text)

        return result
      }

      const match = textFilterValues.find(text => {
        const result = isMatch(text)
        return result
      })

      return match
    })

  return filteredApps
}
