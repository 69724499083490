import React from 'react'
import PropTypes from 'prop-types'

export const MenuTitle = props => (
  <span
    className="MenuTitle"
    onClick={props.onClick}
    onMouseEnter={props.onHover}
  >
    {props.text}
  </span>
)

MenuTitle.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
}
