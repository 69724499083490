import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import makeBem from 'bem-cx'

import { Modal } from '../../../../cmp/Modal/index'
import { server } from '../../../../utils/server'
import { Button } from '../../../../cmp/Button/Button'
import './FileModal.css'

const cn = makeBem('FileModal')

const fileTypes = {
  MEASUREMENT: 'MEASUREMENT',
  EVENT: 'EVENT',
  IMAGE: 'IMAGE',
  SUPPORT: 'SUPPORT',
}

const fileTypePropType = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}).isRequired).isRequired

export class FileModal extends Component {
  state = {
    userFiles: [],
    isLoading: true,
    isUploading: false,
    isUploadingReady: false,
    filesToUpload: [],
    selectedFileType: fileTypes.MEASUREMENT,
    dateTimeFrom: '2019-01-01T00:00',
    dateTimeTo: '2019-01-01T00:00',
  }

  async componentDidMount() {
    const userFiles = await server.get({
      url: 'blob/filenames',
      type: fileTypePropType,
    })
    this.setState({
      userFiles,
      isLoading: false,
    })
  }

  render() {
    /*const new_content = (
      <div className={cn}>
        <div className={cn.el('Settings')}>
          <div className={cn.el('Settings').el('FileSelect')}>
            <input
              name="file"
              type="file"
              onChange={async (e) => {
                this.setState({filesToUpload: e.target.files})
              }}
              multiple
              disabled={this.state.isUploadingReady}
            />
          </div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div className={cn.el('Settings').el('UploadButton')}>8</div>
          <div className={cn.el('Settings').el('Progress')}>9</div>
        </div>
        <div className={cn.el('UserFiles')}>y</div>
      </div>
    )*/

    const content = (
      <div className={cn}>

        <div className={cn.el('newFiles')}>

          <input
            name="file"
            type="file"
            onChange={async (e) => {
              this.setState({filesToUpload: e.target.files})
            }}
            multiple
            disabled={this.state.isUploadingReady}
          />

          <div>
            <input
              type="datetime-local"
              value={this.state.dateTimeFrom}
              disabled={!this.state.filesToUpload}
              onChange={e => this.setState({dateTimeFrom: e.target.value})}
            />
            <input
              type="datetime-local"
              value={this.state.dateTimeTo}
              disabled={!this.state.filesToUpload}
              onChange={e => this.setState({dateTimeTo: e.target.value})}
            />
          </div>

          <div>
            <div>
              <input
                id="FileModal_Measurement"
                type="radio"
                disabled={!this.state.filesToUpload}
                checked={this.state.selectedFileType === fileTypes.MEASUREMENT}
                onChange={() => this.setState({selectedFileType: fileTypes.MEASUREMENT})}
              />
              <label htmlFor="FileModal_Measurement">Measurement</label>
            </div>

            <div>
              <input
                id="FileModal_Event"
                type="radio"
                disabled={!this.state.filesToUpload}
                checked={this.state.selectedFileType === fileTypes.EVENT}
                onChange={() => this.setState({selectedFileType: fileTypes.EVENT})}
              />
              <label htmlFor="FileModal_Event">Event</label>

            </div>

            <div>
              <input
                id="FileModal_Image"
                type="radio"
                disabled={!this.state.filesToUpload}
                checked={this.state.selectedFileType === fileTypes.IMAGE}
                onChange={() => this.setState({selectedFileType: fileTypes.IMAGE})}
              />
              <label htmlFor="FileModal_Image">Image</label>
            </div>

          </div>

          <Button
            text="Upload Files"
            disabled={_.isEmpty(this.state.filesToUpload) || this.state.isUploadingReady}
            style={{marginBottom: 8}}
            onClick={() => {
              this.setState({
                isUploading: true,
                uploadingFileFinished: 0,
              })

              _.each(this.state.filesToUpload, async(file) => {
                const formData = new FormData()
                formData.append('file', file)
                formData.append('job_id', this.props.jobId)
                formData.append('file_type', this.state.selectedFileType)
                formData.append('date_time_from', this.state.dateTimeFrom)
                formData.append('date_time_to', this.state.dateTimeTo)

                axios.defaults.headers.common['Auth-token'] = localStorage.getItem('username')

                await axios.post(
                  '/api/blob/upload',
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                  },
                )

                const uploadingFileFinished = this.state.uploadingFileFinished + 1
                const isUploadingReady = this.state.filesToUpload.length === uploadingFileFinished

                this.setState({
                  uploadingFileFinished: uploadingFileFinished,
                  isUploading: !isUploadingReady,
                  isUploadingReady,
                })
              })
            }}
          />

          {this.state.isUploading && (
            <div>Uploading in progress... {this.state.filesToUpload.length}/{this.state.uploadingFileFinished}</div>
          )}

          {this.state.isUploadingReady && <div>{this.state.uploadingFileFinished} file{this.state.uploadingFileFinished > 1 && 's'} uploaded successfully</div>}
          {this.state.isUploadingReady && <Button text="Close" onClick={this.props.onClose}/>}

        </div>

        <div className={cn.el('existingFiles')}>

          {this.state.userFiles
            .map((userFile, fileIndex) => (
              <div
                key={fileIndex}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if(window.confirm('Delete file?')) {
                    server.get({url: `blob/delete/${userFile.name}`}, async () => {
                      this.setState({isLoading: true})
                      const userFiles = await server.get({
                        url: 'blob/filenames',
                        type: fileTypePropType,
                      })
                      this.setState({
                        userFiles,
                        isLoading: false,
                      })
                    })
                  }
                }}
              >{userFile.name}</div>
            ))}

        </div>

      </div>
    )

    return (
      <Modal
        isVisible={true}
        onClose={this.props.onClose}
        title="Upload Files"
      >
        {this.state.isLoading ? <div>loading...</div> : content}
      </Modal>
    )
  }
}

FileModal.propTypes = {
  jobId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
}
