import {Allocation} from './allocation'

/**
 * @function
 * @param allocation {Allocation}
 */
export const addAllocation = function(allocation) {
  const allocationIndex = this.allocations.findIndex(a => a.dataSource === allocation.dataSource)

  if(allocationIndex >= 0) {
    this.allocations.splice(allocationIndex, 1)
  }

  this.allocations.push(new Allocation(allocation))
}

/**
 * @function
 * @param dataSourceName {string}
 */
export const getAllocation = function(dataSourceName) {
  return this.allocations.find(a => a.dataSource === dataSourceName)
}

TimeSetProvider.prototype.addAllocation = addAllocation

/**
 * @interface
 *
 * Interface to provide time allocation to child components
 */
export function TimeSetProvider() {}
