import React, {Component} from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'
import {Statechart} from 'scion-core'
import update from 'immutability-helper'
import _ from 'lodash'

import {initModel} from 'model/model'
import {Designer} from './Designer/Designer'
import {Allocator} from './Allocator/Allocator'
import {Analyser} from './Analyser/Analyser'
import {Control} from './Control/Control'
import ImageManager from './ImageManager'
import FileManager from './FileManager'
import {Menu} from './Menu'
import {MenuTitle} from './Menu/MenuTitle'
import {Modal} from 'cmp/Modal'
import {NewJobModal} from './NewJobModal/NewJobModal'
import FileContainerModal from './FileContainerModal'
import {AnalysisSelectModal} from './AnalysisSelectModal/AnalysisSelectModal'
import {FileModal} from './FileModal'
import {FileControl} from 'cmp/FileControl/FileControl'
import {DBQueryEditor} from 'cmp/DBQueryEditor/DBQueryEditor'
import {ShareJob} from 'cmp/ShareJob/ShareJob'
import {server} from 'utils/server'
import {getIncrementedName} from 'utils/stringManipulation'
import './MainPage.css'

const cn = makeBem('MainPage')

export const MENU_ITEM = {
  NEW_JOB: 'NEW_JOB',
  SAVE_JOB: 'SAVE_JOB',
  SHARE_JOB: 'SHARE_JOB',
  UPLOAD_FILE: 'UPLOAD_FILE',
  FILE_HANDLING: 'FILE_HANDLING',
  FILE_MANAGER: 'FILE_MANAGER',
  DB_QUERY_EDITOR: 'DB_QUERY_EDITOR',
  USER_SETTINGS: 'USER_SETTINGS',
  LOGOUT: 'LOGOUT',
}

const MENU_TITLE = {
  JOB: 'JOB',
  DATA: 'DATA',
  USER: 'USER',
}

export const TAB = {
  DESIGN: 'DESIGN',
  DATA: 'DATA',
  ANALYSIS: 'ANALYSIS',
  CONTROL: 'CONTROL',
  IMAGES: 'IMAGES',
}

const MODAL_STATE = {
  NONE: 'NONE',
  LOADING: 'LOADING',
  JOB_SELECT: 'JOB_SELECT',
  ANALYSIS_SELECT: 'ANALYSIS_SELECT',
  FILE_CONTAINER: 'FILE_CONTAINER',
  FILE: 'FILE',
}

const getComponentInitialState = () => ({
  job: null,
  jobIdentifiers: [],
  selectedEquipmentId: null,
  selectedMenuTitle: null,
  activeTab: TAB.DESIGN,
  modal: MODAL_STATE.LOADING,
  isPlantReady: false,
  isFileHandlingModalVisible: false,
  isFileManagerModalVisible: false,
  isDataQueryModalVisible: false,
  isShareJobModalVisible: false,
  fileQueries: [],
  appSettings: [],
  fileContainers: [],
  fileContainerIdx: null,
})

const STATE = {
  INIT: 'INIT',
  PLANNER: 'PLANNER',
  SETTING_JOB: 'SETTING_JOB',
  SETTING_ANALYSES: 'SETTING_ANALYSES',
  SETTING_FILE_CONTAINER: 'SETTING_FILE_CONTAINER',
  REQUESTING_JOB: 'REQUESTING_JOB',
  LOADING_JOB: 'LOADING_JOB',
  CLONING_JOB: 'CLONING_JOB',
}

const EVENT = {
  READY: 'READY',
  LOAD: 'LOAD',
  CLONE: 'CLONE',
  SET_JOB: 'SET_JOB',
  SET_ANALYSES: 'SET_ANALYSES',
  SET_FILE_CONTAINER: 'SET_FILE_CONTAINER',
  NEW: 'NEW',
  FILE: 'FILE',
  CLOSE: 'CLOSE',
  BACK: 'BACK',
}

const draw2d = window.draw2d
const draw2dReader = new draw2d.io.json.Reader()
const draw2dWriter = new draw2d.io.json.Writer()
const MainPageContext = React.createContext({})
const MainPageContextProvider = MainPageContext.Provider
export const MainPageContextConsumer = MainPageContext.Consumer

const HiddenTabsContext = React.createContext({})
const HiddenTabsContextProvider = HiddenTabsContext.Provider
export const HiddenTabsContextConsumer = HiddenTabsContext.Consumer

export class MainPage extends Component {
  static displayName = cn

  state = getComponentInitialState()

  unselectMenuTitle = e => {
    if(!!this.state.selectedMenuTitle && e.target.className !== 'MenuTitle') {
      this.setState({selectedMenuTitle: null})
    }
  }

  assignCloneEquipmentShortcut = e => {
    if(e.ctrlKey && e.keyCode === 68) {
      e.preventDefault()

      /**
       * Lists newly created Equipments with their sources in the form of: {old: [Eq.], fresh: [Eq.]}
       *
       * @type {Array}
       */
      const equipmentCloningConnections = []

      // Equipment
      this.canvas.getSelection().all.data
        .filter(isEquipment)
        .forEach(oldFigure => {
          const coordinateGap = 50
          const nodeType = oldFigure.cssClass.substring(22)
          const newFigure = new window.minealytics.equipment[nodeType]()
          const oldEquipment = window.minealytics.model._job.plant.getEquipment(oldFigure.id)
          const newEquipmentName = getIncrementedName(oldEquipment.name)

          equipmentCloningConnections[oldFigure.id] = newFigure

          const onPortConnect = this.onPortConnect

          const portTop = newFigure.createPort('hybrid', new draw2d.layout.locator.TopLocator())
          portTop.on('connect', onPortConnect)

          const portRight = newFigure.createPort('hybrid', new draw2d.layout.locator.RightLocator())
          portRight.on('connect', onPortConnect)

          const portBottom = newFigure.createPort('hybrid', new draw2d.layout.locator.BottomLocator())
          portBottom.on('connect', onPortConnect)

          const portLeft = newFigure.createPort('hybrid', new draw2d.layout.locator.LeftLocator())
          portLeft.on('connect', onPortConnect)

          this.canvas.add(newFigure, oldFigure.x + coordinateGap, oldFigure.y + coordinateGap)
          newFigure.attr({resizeable: false})
          //TODO: put back this line when the nodeType switch-case is ready!
          // figure.add(new draw2d.shape.basic.Label({text: newEquipment.name}), new draw2d.layout.locator.TopLocator())
          newFigure.add(new draw2d.shape.basic.Label({text: newEquipmentName}), new draw2d.layout.locator.TopLocator())

          draw2dWriter.marshal(this.canvas, canvasData => {
            const job = window.minealytics.model.cloneEquipment(oldEquipment, newFigure.id, newEquipmentName, JSON.stringify(canvasData))
            // const job = window.minealytics.model.addEquipment(newFigure.id, nodeType, JSON.stringify(canvasData), newEquipmentName)
            this.setState({job})
          })
        })

      // Connections
      this.canvas.getSelection().all.data
        .filter(isConnection)
        .forEach(oldConnection => {
          const oldSourceFigure = oldConnection.sourcePort.parent
          const oldTargetFigure = oldConnection.targetPort.parent

          const sourcePortIdx = oldConnection.sourcePort.name.substring(6)
          const targetPortIdx = oldConnection.targetPort.name.substring(6)

          const newSourceFigure = equipmentCloningConnections[oldSourceFigure.id]
          const newTargetFigure = equipmentCloningConnections[oldTargetFigure.id]

          const newConnection = new draw2d.Connection({
            source: newSourceFigure.getPorts().data[sourcePortIdx],
            target: newTargetFigure.getPorts().data[targetPortIdx],
          });

          newConnection.setRouter(new draw2d.layout.connection.InteractiveManhattanConnectionRouter());

          this.canvas.add(newConnection)
        })

      /**
       * Decides if canvas item is Equipment.
       *
       * @param canvasItem - Draw2d canvas object
       * @return {boolean}
       */
      function isEquipment(canvasItem) {
        return canvasItem.cssClass.startsWith('minealytics_equipment')
      }

      /**
       * Decides if canvas item is Connection.
       *
       * @param canvasItem
       * @return {boolean}
       */
      function isConnection(canvasItem) {
        return canvasItem.cssClass === 'draw2d_Connection'
      }
    }
  }

  async componentDidMount() {
    const stateChartModel = {
      states: [

        // INIT
        {
          id: STATE.INIT,
          transitions: [
            {
              event: EVENT.READY,
              target: STATE.PLANNER,
            },
            {
              event: EVENT.SET_JOB,
              target: STATE.SETTING_JOB,
            },
          ],
          onEntry: () => {
            initModel(model => {
              const storedJobId = localStorage.getItem('jobId')
              this.setState({job: model.getJob()})

              if(storedJobId) {
                model.initJob({
                  initType: 'LOAD',
                  jobId: parseInt(storedJobId),
                }, job => {
                  this.sc.gen(EVENT.READY, {job})
                })
              }
              else {
                this.sc.gen(EVENT.SET_JOB, {jobIdentifiers: model.getJobIdentifiers()})
              }
            })
          },
        },

        // PLANNER
        {
          id: STATE.PLANNER,
          onEntry: e => {
            if(e.data.job) {
              localStorage.setItem('jobId', e.data.job.id)
              this.setState({
                isPlantReady: true,
                modal: MODAL_STATE.NONE,
                job: e.data.job,
              })
            }
            else {
              this.setState({
                isPlantReady: true,
                modal: MODAL_STATE.NONE,
              })
            }
          },
        },

        // SETTING_JOB
        {
          id: STATE.SETTING_JOB,
          transitions: [
            {
              event: EVENT.SET_ANALYSES,
              target: STATE.SETTING_ANALYSES,
            },
            {
              event: EVENT.LOAD,
              target: STATE.LOADING_JOB,
            },
            {
              event: EVENT.CLONE,
              target: STATE.CLONING_JOB,
            },
          ],
          onEntry: e => {
            const jobIdentifiers = e.data.jobIdentifiers

            this.setState({
              jobIdentifiers,
              modal: MODAL_STATE.JOB_SELECT,
            })
          },
        },

        // REQUESTING_JOB
        {
          id: STATE.REQUESTING_JOB,
          transitions: [
            {
              event: EVENT.READY,
              target: STATE.PLANNER,
            },
          ],
          states: [

            // LOADING_JOB
            {
              id: STATE.LOADING_JOB,
              transitions: [
                {
                  event: EVENT.READY,
                  target: STATE.PLANNER,
                },
              ],
              onEntry: e => {
                window.minealytics.model.loadJob(e.data.jobId, job => {
                  this.sc.gen(EVENT.READY, {job})
                })
              }
            },

            // CLONING_JOB
            {
              id: STATE.CLONING_JOB,
              transitions: [
                {
                  event: EVENT.SET_ANALYSES,
                  target: STATE.SETTING_ANALYSES,
                },
              ],
            },
          ],
        },

        // SETTING_ANALYSES
        {
          id: STATE.SETTING_ANALYSES,
          transitions: [
            {
              event: EVENT.READY,
              target: STATE.PLANNER,
            },
            {
              event: EVENT.SET_FILE_CONTAINER,
              target: STATE.SETTING_FILE_CONTAINER,
            },
            {
              event: EVENT.BACK,
              target: STATE.INIT,
            },
          ],
          onEntry: e => {
            this.setState(state => update(state, {
              modal: {
                $set: MODAL_STATE.ANALYSIS_SELECT,
              },
              job: {
                title: {
                  $set: e.data.title,
                },
                location: {
                  $set: e.data.location,
                },
                process: {
                  $set: e.data.process,
                },
              },
            }))
          },
        },

        // SETTING_FILE_CONTAINER
        {
          id: STATE.SETTING_FILE_CONTAINER,
          transitions: [
            {
              event: EVENT.READY,
              target: STATE.PLANNER,
            },
            {
              event: EVENT.BACK,
              target: STATE.SETTING_ANALYSES,
            },
          ],
          onEntry: e => {
            localStorage.setItem('jobId', e.data.job.id)
            this.setState({
              job: e.data.job,
              modal: MODAL_STATE.FILE_CONTAINER,
            })
          },
          onExit: e => {
            const job = window.minealytics.model.addFileContainerToJob(this.state.fileContainers[e.data.fileContainerIdx])
            this.setState({job})
          },
        },
      ],
    }

    this.sc = new Statechart(stateChartModel, {})
    this.sc.start()

    document.addEventListener('click', this.unselectMenuTitle)

    const fileQueries = await server.getFileQueries()
    const appSettings = await server.getServiceAppSettings()
    const fileContainers = await server.getFileContainers()
    window.minealytics.db_equipment = await server.getEquipmentInitData()

    document.addEventListener('keydown', this.assignCloneEquipmentShortcut)

    this.setState({fileQueries, appSettings, fileContainers})
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.unselectMenuTitle)
    document.removeEventListener('keydown', this.assignCloneEquipmentShortcut)
  }

  render() {
    let content

    if(this.state.isPlantReady) {
      switch(this.state.activeTab) {
        case TAB.DESIGN:
          content = (
            <MainPageContextProvider
              value={{
                job: this.state.job,
                save: () => {
                  const job = window.minealytics.model.save()
                  this.setState({job})
                },
                setEquipmentName: (equipmentId, name) => {
                  const job = window.minealytics.model.setEquipmentName(equipmentId, name)
                  this.setState({job})
                },
                setEquipmentDescription: (equipmentId, description) => {
                  const job = window.minealytics.model.setEquipmentDescription(equipmentId, description)
                  this.setState({job})
                },
                setConstantValue: (equipmentId, constantKey, value) => {
                  const job = window.minealytics.model.setConstantValue(equipmentId, constantKey, value)
                  this.setState({job})
                },
                setConstantUnit: (equipmentId, constantKey, unit) => {
                  const job = window.minealytics.model.setConstantUnit(equipmentId, constantKey, unit)
                  this.setState({job})
                },
                setField: (equipmentId, fieldKey, value) => {
                  const job = window.minealytics.model.setField(equipmentId, fieldKey, value)
                  this.setState({job})
                },
                addSubEquipment: (parentEquipmentId, equipmentType) => {
                  const job = window.minealytics.model.addSubEquipment(parentEquipmentId, equipmentType)
                  this.setState({job})
                },
                deleteMainEquipment: equipmentId => {
                  const equipmentNode = this.canvas.getPrimarySelection()
                  const deleteCommand = new draw2d.command.CommandDelete(equipmentNode)
                  this.canvas.getCommandStack().execute(deleteCommand)
                },
                removeSubEquipment: (equipmentId, parentId, equipmentType) => {
                  const job = window.minealytics.model.removeSubEquipment(equipmentId, parentId, equipmentType)
                  this.setState({job})
                },
                changeEquipmentField: (equipmentId, fieldKey, actionType, fieldType) => {
                  const job = window.minealytics.model.changeEquipmentField(equipmentId, fieldKey, actionType, fieldType)
                  this.setState({job})
                },
                setEquipmentSettings: (equipmentId, settings) => {
                  const job = window.minealytics.model.setEquipmentSettings(equipmentId, settings)
                  this.setState({job})
                },
              }}
            >
              <Designer
                activeTab={this.state.activeTab}
                onTabClick={this.onTabClick}
                equipment={this.state.job.plant.equipmentList.find(e => e.id === this.state.selectedEquipmentId)}
                onCanvasReady={this.onCanvasReady}
                fileQueries={this.state.fileQueries}
                job={this.state.job}
                container={this.state.job.containerId}
                appSettings={this.state.appSettings}
              />
            </MainPageContextProvider>
          )
          break

        case TAB.DATA:
          content = (
            <MainPageContextProvider
              value={{
                job: this.state.job,
                addEventAllocation: (allocationKey, allocation) => {
                  const job = window.minealytics.model.addEventAllocation(allocationKey, allocation)
                  this.setState({job})
                },
                removeEventAllocation: (allocationKey, dataSourceName) => {
                  const job = window.minealytics.model.removeEventAllocation(allocationKey, dataSourceName)
                  this.setState({job})
                }
              }}
            >
              <Allocator
                activeTab={this.state.activeTab}
                onTabClick={this.onTabClick}
                job={this.state.job}
                dataSources={this.state.job.dataSources}
                containers={this.state.fileContainers}
                appSettings={this.state.appSettings}
                changePlant={plant => {
                  this.setState(state => update(state, {
                    job: {
                      plant: {
                        $set: plant,
                      },
                    },
                  }))
                }}
                addDataSource={dataSource => {
                  const job = window.minealytics.model.addDataSource(dataSource)

                  this.setState(state => update(state, {
                    job: {
                      $set: job,
                    },
                  }))
                }}
                deleteDataSource={(name, container) => {
                  const job = window.minealytics.model.deleteDataSource(name, container)
                  this.setState({job})
                }}
                addAllocationToVariable={(equipmentId, variableKey, allocationJson) => {
                  const job = window.minealytics.model.addAllocationToVariable(equipmentId, variableKey, allocationJson)
                  this.setState({job})
                }}
                removeAllocationFromVariable={(equipmentId, variableKey, dataSourceName) => {
                  const job = window.minealytics.model.removeAllocationFromVariable(equipmentId, variableKey, dataSourceName)
                  this.setState({job})
                }}
                addAllocationToTimeNode={(dataSourceName, timeDataSetIndex, entity) => {
                  const job = window.minealytics.model.addAllocationToTimeNode(dataSourceName, timeDataSetIndex, entity)
                  this.setState({job})
                }}
                setUnit={(equipmentId, variableKey, value) => {
                  const job = window.minealytics.model.setUnit(equipmentId, variableKey, value)
                  this.setState({job})
                }}
                copyAllocation={(source, target) => {
                  const job = window.minealytics.model.copyAllocation(source, target)
                  this.setState({job})
                }}
                resetAllocations={() => {
                  const job = window.minealytics.model.resetAllocations()
                  this.setState({job})
                }}
              />
            </MainPageContextProvider>
          )
          break

        case TAB.ANALYSIS:
          content = (
            <Analyser
              activeTab={this.state.activeTab}
              onTabClick={this.onTabClick}
              job={this.state.job}
            />
          )
          break

        case TAB.CONTROL:
          content = (
            <Control
              activeTab={this.state.activeTab}
              onTabClick={this.onTabClick}
              equipmentList={this.state.job.plant.equipmentList}
              jobId={this.state.job.id}
            />
          )
          break

        case TAB.IMAGES:
          content = (
            <ImageManager
              activeTab={this.state.activeTab}
              onTabClick={this.onTabClick}
              job={this.state.job}
              appSettings={this.state.appSettings}
              fileContainer={this.state.job.containerId}
            />
          )
          break

        default:
          console.warn('Unknown tab click')
      }
    }

    /*region Temporary hack to hide Images Tab. TODO: refactor*/
    const hiddenTabs = []

    const isImageManagerDisabled = this.state.job && !_.some([
        'SEGMENTATION',
        'LABELING',
        'BOUNDING_BOX_PLACEMENT',
        'REVIEW',
      ], el => this.state.job.analyses.includes(el))

    if(isImageManagerDisabled) {
      hiddenTabs.push('IMAGES')
    }
    /*endregion*/

    return (
      <div className={cn}>

        <Modal
          isVisible={this.state.modal === MODAL_STATE.LOADING}
          onClose={() => {}}
        >
          <div>loading...</div>
        </Modal>

        <Modal
          isVisible={this.state.isFileHandlingModalVisible}
          onClose={() => this.setState({isFileHandlingModalVisible: false})}
          title="File Handling"
        >
          {this.state.isFileHandlingModalVisible && (
            <FileControl jobTitle={this.state.job.title}/>
          )}
        </Modal>

        <Modal
          isVisible={this.state.isFileManagerModalVisible}
          onClose={() => this.setState({isFileManagerModalVisible: false})}
          title="File Manager"
        >
          {this.state.isFileManagerModalVisible && (
            <FileManager
              containers={this.state.fileContainers}
              appSettings={this.state.appSettings}
              job={this.state.job}
            />
          )}
        </Modal>

        <Modal
          isVisible={this.state.isDataQueryModalVisible}
          onClose={() => this.setState({isDataQueryModalVisible: false})}
          title="DB Query Editor"
        >
          <DBQueryEditor/>
        </Modal>

        {this.state.isShareJobModalVisible && (
          <Modal
            isVisible={true}
            onClose={() => this.setState({isShareJobModalVisible: false})}
            title="Share Job"
          >
            <ShareJob/>
          </Modal>
        )}

        {this.state.modal === MODAL_STATE.JOB_SELECT && (
          <NewJobModal
            jobIdentifiers={this.state.jobIdentifiers}
            newJob={(title, location, process) => {
              this.sc.gen(EVENT.SET_ANALYSES, {
                title,
                location,
                process,
              })
            }}
            loadJob={jobId => this.sc.gen(EVENT.LOAD, {jobId})}
            cloneJob={(jobId, sameAnalyses) => this.sc.gen(EVENT.CLONE, {
              jobId,
              sameAnalyses,
            })}
          />
        )}

        {this.state.modal === MODAL_STATE.ANALYSIS_SELECT && (
          <AnalysisSelectModal
            title={this.state.job.title}
            location={this.state.job.location}
            analyses={this.state.job.analyses}
            select={(analyses, controlModules) => {
              window.minealytics.model.initJob({
                initType: 'NEW',
                title: this.state.job.title,
                location: this.state.job.location,
                process: this.state.job.process,
                analyses,
                controlModules,
              }, () => {
                // Old way, conditionally set image container, preserved if backup is needed
                // const event = job.hasCVAnnotation() ? EVENT.SET_FILE_CONTAINER : EVENT.READY
                const event = EVENT.SET_FILE_CONTAINER
                this.sc.gen(event, {
                  job: window.minealytics.model.getJob(),
                })
              })
            }}
            back={() => this.sc.gen(EVENT.BACK)}
          />
        )}

        {this.state.modal === MODAL_STATE.FILE_CONTAINER && (
          <FileContainerModal
            fileContainers={this.state.fileContainers}
            onSelect={fileContainerIdx => this.sc.gen(EVENT.READY, {fileContainerIdx})}
            onCancel={() => this.sc.gen(EVENT.BACK)}
          />
        )}

        {this.state.modal === MODAL_STATE.DATA && (
          <FileModal
            jobId={this.state.job.id}
            onClose={() => this.sc.gen(EVENT.DATA)}
          />
        )}

        <Menu
          selectedMenuTitle={this.state.selectedMenuTitle}
          menuTitles={MENU_TITLE}
          jobTitle={this.state.job && this.state.job.title}
          defaultFileContainer={this.state.job && this.state.job.containerId}
          onMenuItemClick={this.onMenuItemClick}
          onLogout={this.props.onLogout}
        >
          <MenuTitle
            text="Job"
            onClick={() => this.onMenuTitleClick(MENU_TITLE.JOB)}
            onHover={() => {
              if(!!this.state.selectedMenuTitle && this.state.selectedMenuTitle !== MENU_TITLE.JOB) {
                this.onMenuTitleClick(MENU_TITLE.JOB)
              }
            }}
          />
          <MenuTitle
            text="Data"
            onClick={() => this.onMenuTitleClick(MENU_TITLE.DATA)}
            onHover={() => {
              if(!!this.state.selectedMenuTitle && this.state.selectedMenuTitle !== MENU_TITLE.DATA) {
                this.onMenuTitleClick(MENU_TITLE.DATA)
              }
            }}
          />
          <MenuTitle
            text="User"
            onClick={() => this.onMenuTitleClick(MENU_TITLE.USER)}
            onHover={() => {
              if(!!this.state.selectedMenuTitle && this.state.selectedMenuTitle !== MENU_TITLE.USER) {
                this.onMenuTitleClick(MENU_TITLE.USER)
              }
            }}
          />
        </Menu>

        <div className={cn.el('Content')}>
          <HiddenTabsContextProvider
            value={{hiddenTabs}}
          >
            {content}
          </HiddenTabsContextProvider>
        </div>

      </div>
    )
  }

  onMenuTitleClick = selectedMenuTitle => {
    if(this.state.selectedMenuTitle === selectedMenuTitle) {
      this.setState({selectedMenuTitle: null})
    }
    else {
      this.setState({selectedMenuTitle})
    }
  }

  onMenuItemClick = menuItem => {
    switch(menuItem) {
      case MENU_ITEM.NEW_JOB:
        if(!window.confirm('Close current Job and continue?')) {
          return
        }

        localStorage.removeItem('jobId')
        window.location.reload()
        break

      case MENU_ITEM.SHARE_JOB:
        this.setState({isShareJobModalVisible: true})
        break

      case MENU_ITEM.FILE_HANDLING:
        this.setState({isFileHandlingModalVisible: true})
        break

      case MENU_ITEM.FILE_MANAGER:
        this.setState({isFileManagerModalVisible: true})
        break

      case MENU_ITEM.DB_QUERY_EDITOR:
        this.setState({isDataQueryModalVisible: true})
        break

      default:
        console.warn('Unknown menu item click')
    }
  }

  onTabClick = activeTab => this.setState({activeTab})

  onPortConnect = (port, target) => {
    /**
     * 'connect' event is triggered twice, first on source port, second on target port.
     * In the first case target port is unknown (=== null), so we just skip it,
     * and wait for the second event.
     */
    if(target.connection.targetPort === null) {
      return
    }

    target.connection.setTargetDecorator(new draw2d.decoration.connection.ArrowDecorator())

    // Canvas is not updated here, yet.
    setTimeout(() => {
      draw2dWriter.marshal(this.canvas, canvasData => {
        const job = window.minealytics.model.addConnection(
          target.connection.sourcePort.parent.id,
          target.connection.targetPort.parent.id,
          canvasData
        )
        this.setState({job})
      })
    }, 0)
  }

  onEquipmentMove = () => {
    // Canvas is not updated here, yet.
    setTimeout(() => {
      draw2dWriter.marshal(this.canvas, canvasData => {
        // In this case updating component state is not necessary.
        window.minealytics.model.setCanvasData(JSON.stringify(canvasData))
      })
    }, 0)
  }

  initializeCanvas = () => {
    this.canvas.clear()
    draw2dReader.unmarshal(this.canvas, this.state.job.plant.canvasData)
    this.canvas.figures.data.forEach(figure => {
      figure.hybridPorts.data.forEach(port => {
        port.on('connect', this.onPortConnect)
        port.connections.data.forEach(connection => {
          connection.setTargetDecorator(new draw2d.decoration.connection.ArrowDecorator())
        })
      })

      const equipment = this.state.job.plant.equipmentList.find(e => e.id === figure.id)
      figure.attr({resizeable: false})
      if (equipment !== undefined && equipment.name !== undefined){
        const text = new draw2d.shape.basic.Text({text: equipment.name})
        figure.add(text, new draw2d.layout.locator.TopLocator())
      }
      else{
        console.warn(equipment + 'has no name')
      }
    })
  }

  onCanvasReady = () => {
    const self = this

    const EquipmentSelectionPolicy = draw2d.policy.figure.SelectionPolicy.extend({
      NAME: 'EquipmentSelectionPolicy',
      init: function(attr, setter, getter) {
        this._super(attr, setter, getter)
      },
      onSelect: function(canvas, figure, isPrimarySelection) {
        this._super(canvas, figure, isPrimarySelection)
        self.setState({selectedEquipmentId: figure.id})
      },
      onUnselect: function(canvas, figure) {
        this._super(canvas, figure)
        self.setState({selectedEquipmentId: null})
      },
    })

    const baseEquipmentObject = {
      init : function()
      {
        this._super()
        this.installEditPolicy(new EquipmentSelectionPolicy())
      },
      onDragEnd: function() {
        self.onEquipmentMove()
        this._super()
      },
    }

    const getControlModuleType = name => {
      return draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment." + name,
        getSVG: function(){
          return `
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
          <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
          <rect x="10" y="35" width="30" height="10" stroke="#1B1B1B" stroke-width="2"/>
          <line x1="25" y1="35" x2="33" y2="24" stroke="#1B1B1B" stroke-width="2"></line>
          <ellipse cx="35" cy="20" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>
        </svg>`
        },
      })
    }

    const svgForAllMills = function(){
      return `
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polyline points="0,20 8,20 8,30 0,30" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="8,20 15,3 17,3 17,47 15,47 8,30" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="17,10 33,10 33,40 17,40" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="33,5 40,20 40,30 33,45 33,5" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="40,20 50,20 50,30 40,30" stroke="#1B1B1B" stroke-width="2"/>
			  
			  <polyline points="17,13 33,13" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="17,18 33,18" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="17,25 33,25" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="17,32 33,32" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="17,37 33,37" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
    }

    const EquipmentType = {
      RRRExactInverseKinematicsControl: getControlModuleType('RRRExactInverseKinematicsControl'),
      ReinforcementLearningInverseKinematicsControl: getControlModuleType('ReinforcementLearningInverseKinematicsControl'),
      ReinforcementEvolutionaryStrategyInverseKinematicsControl: getControlModuleType('ReinforcementEvolutionaryStrategyInverseKinematicsControl'),
      KinematicsSimulatorControl: getControlModuleType('KinematicsSimulatorControl'),
      CrushingCircuitSimulator: getControlModuleType('CrushingCircuitSimulator'),
      CrushingCircuitOptimalControl: getControlModuleType('CrushingCircuitOptimalControl'),
      FlotationBankSimulator: getControlModuleType('FlotationBankSimulator'),
      FlotationBankOptimalControl: getControlModuleType('FlotationBankOptimalControl'),
      WaterManagementSimulator: getControlModuleType('WaterManagementSimulator'),
      WaterManagementControl: getControlModuleType('WaterManagementControl'),
      HydrocycloneSimulator: getControlModuleType('HydrocycloneSimulator'),
      HydrocycloneControl: getControlModuleType('HydrocycloneControl'),

      /**************************/
      /*Original equipments*/

      Bin: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Bin",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polyline points="0,0 0,45 25,50 50,45 50,0" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      Conveyor: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Conveyor",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>    
              <ellipse cx="5" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>    
              <ellipse cx="45" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>      
              <line x1="5" y1="20" x2="45" y2="20" stroke="#1B1B1B" stroke-width="2"></line>    
              <line x1="5" y1="30" x2="45" y2="30" stroke="#1B1B1B" stroke-width="2"></line>    
            </svg>`
        },
      }),
      ShuttleConveyor: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.ShuttleConveyor",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>    
              <ellipse cx="5" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>    
              <ellipse cx="45" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>      
              <line x1="5" y1="20" x2="45" y2="20" stroke="#1B1B1B" stroke-width="2"></line>    
              <line x1="5" y1="30" x2="45" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              
              <ellipse cx="10" cy="35" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>      
              <ellipse cx="40" cy="35" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>
            </svg>`
        },
      }),
      ApronFeeder: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.ApronFeeder",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <ellipse cx="5" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="45" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="5" y1="20" x2="45" y2="20" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="5" y1="30" x2="45" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              <rect x="5" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="15" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="25" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="35" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="10" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="20" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="30" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="40" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
            </svg>`
        },
      }),
      BeltFeeder: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.BeltFeeder",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <ellipse cx="5" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="45" cy="25" rx="5" ry="5" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="5" y1="20" x2="45" y2="20" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="5" y1="30" x2="45" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              <rect x="5" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="15" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="25" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="35" y="15" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="10" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="20" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="30" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
              <rect x="40" y="30" width="5" height="5" stroke="#1B1B1B" stroke-width="2"></rect>
            </svg>`
        },
      }),
      VibratingFeeder: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.VibratingFeeder",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <rect x="5" y="20" width="40" height="10" stroke="#1B1B1B" stroke-width="2"></rect>
              <line x1="20" y1="30" x2="42" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="30" x2="42" y2="35" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="42" y1="34" x2="42" y2="41" stroke="#1B1B1B" stroke-width="2"></line>
            </svg>`
        },
      }),
      ConeCrusher: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.ConeCrusher",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polygon points="0,0 5,5 45,5 50,0" stroke="#1B1B1B" stroke-width="2"></polygon>
              <polygon points="5,5 0,40 50,40 45,5" stroke="#1B1B1B" stroke-width="2"></polygon>
              <polygon points="0,40 0,45 50,45, 50,40" stroke="#1B1B1B" stroke-width="2"></polygon>
              <polygon points="10,45 15,50 35,50 40,45" stroke="#1B1B1B" stroke-width="2"></polygon>
            </svg>`
        },
      }),
      JawCrusher: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.JawCrusher",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <circle cx="35" cy="15" r="15" stroke="#1B1B1B" stroke-width="2"/>
              <rect x="0" y="5" width="45" height="45" stroke="#1B1B1B" stroke-width="2" fill="white"></rect>
              <rect x="5" y="10" width="10" height="35" stroke="#1B1B1B" stroke-width="2"></rect>
              <polygon points="20,45 25,45 40,15 30,10" stroke="#1B1B1B" stroke-width="2"></polygon>
            </svg>`
        },
      }),
      GyratoryCrusher: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.GyratoryCrusher",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polygon points="0,0 5,5 45,5 50,0" stroke="#1B1B1B" stroke-width="2"></polygon>
              <polygon points="5,5 0,40 50,40 45,5" stroke="#1B1B1B" stroke-width="2"></polygon>
              <polygon points="0,40 0,45 50,45, 50,40" stroke="#1B1B1B" stroke-width="2"></polygon>
              <polygon points="10,45 15,50 35,50 40,45" stroke="#1B1B1B" stroke-width="2"></polygon>
            </svg>`
        },
      }),
      DiverterGate: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.DiverterGate",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polygon points="12,0 12,25 38,50, 50,38, 38,25 38,0" stroke="#1B1B1B" stroke-width="2"/>
              <line x1="12" y1="12" x2="38" y2="12" stroke="#1B1B1B" stroke-width="2"></line>
            </svg>`
        },
      }),
      DustCollector: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.DustCollector",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polygon points="5,0 5,32 25,50 45,32 45,0" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="5,10 13,10 13,30 18,30 18,10 23,10 23,30 28,30 28,10 33,10 33,10 33,30 38,30 38,10 45,10" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      FrontLoader: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.FrontLoader",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polyline points="38,36 41,39 50,39 38,27 38,31" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="32,28 32,24 25,10 12,10 12,20 0,20 0,35 6,35" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="18,35 26,35" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="3,20 3,15 7,15 7,20" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="5,15 0,10" stroke="#1B1B1B" stroke-width="2"/>
              <polygon points="15,20 26,20 22,13 15,13" stroke="#1B1B1B" stroke-width="2"/>
              <circle cx="12" cy="34" r="6" stroke="#1B1B1B" stroke-width="2"/>
              <circle cx="32" cy="34" r="6" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      Magnet: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Magnet",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polyline points="50,25 50,50 0,50 0,25 50,25 25,0 0,25" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      RockBreaker: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.RockBreaker",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="10,10 10,20 40,20 40,10 10,10" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="15,20 15,25 35,25 35,20" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="20,25 20,40 25,45 30,40 30,25" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      AGMill: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.AGMill",
        getSVG: svgForAllMills,
      }),
      SAGMill: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.SAGMill",
        getSVG: svgForAllMills,
      }),
      RodMill: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.RodMill",
        getSVG: svgForAllMills,
      }),
      BallMill: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.BallMill",
        getSVG: svgForAllMills,
      }),
      PebbleMill: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.PebbleMill",
        getSVG: svgForAllMills,
      }),
      Hydrocyclone: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Hydrocyclone",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polyline points="18,0 32,0" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="20,0 30,0 30,10 20,10 20,0" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="8,10 42,10" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="10,10 40,10 40,20 10,20 10,10" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="8,20 42,20" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="10,20 20,50 30,50 40,20" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="18,50 32,50" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="40,13 50,13" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="40,17 50,17" stroke="#1B1B1B" stroke-width="2"/>
			  <polyline points="50,10 50,20" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      PVCell: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.PVCell",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <line x1="16" y1="10" x2="46" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="12" y1="20" x2="42" y2="20" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="8" y1="30" x2="38" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="4" y1="40" x2="34" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="16" y1="10" x2="4" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="26" y1="10" x2="14" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="36" y1="10" x2="24" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="46" y1="10" x2="34" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
            </svg>`
        },
      }),
      Charger: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Charger",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <line x1="10" y1="10" x2="40" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="10" x2="40" y2="50" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="50" x2="10" y2="50" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="50" x2="10" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="20" y1="10" x2="20" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="20" y1="0" x2="30" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="0" x2="30" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="25" y1="20" x2="20" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="20" y1="30" x2="30" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="30" x2="25" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
            </svg>`
        },
      }),
      Inverter: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Inverter",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <line x1="00" y1="00" x2="50" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="50" y1="0" x2="50" y2="50" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="50" y1="50" x2="0" y2="50" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="0" y1="50" x2="0" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="0" y1="50" x2="50" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="5" y1="10" x2="20" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="5" y1="15" x2="20" y2="15" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="25" y1="35" x2="30" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="30" x2="40" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="40" x2="45" y2="35" stroke="#1B1B1B" stroke-width="2"></line>
          </svg>`
        },
      }),
      Battery: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Battery",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <line x1="0" y1="10" x2="50" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="50" y1="10" x2="50" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="50" y1="40" x2="0" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="0" y1="40" x2="0" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="10" x2="10" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="0" x2="20" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="20" y1="10" x2="20" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="10" x2="30" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="0" x2="40" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="10" x2="40" y2="0" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="25" x2="20" y2="25" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="30" y1="25" x2="40" y2="25" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="35" y1="20" x2="35" y2="30" stroke="#1B1B1B" stroke-width="2"></line>
          </svg>`
        },
      }),
      Camera: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Camera",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <line x1="0" y1="10" x2="50" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="50" y1="10" x2="50" y2="25" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="50" y1="25" x2="15" y2="25" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="15" y1="25" x2="0" y2="10" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="5" y1="15" x2="5" y2="23" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="5" y1="23" x2="13" y2="23" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="25" y1="25" x2="25" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="25" y1="40" x2="50" y2="40" stroke="#1B1B1B" stroke-width="2"></line>
          </svg>`
        },
      }),
      Sensor: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Sensor",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <ellipse cx="25" cy="25" rx="22" ry="22" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="25" y1="40" x2="40" y2="20" stroke="#1B1B1B" stroke-width="2"></line>
              <ellipse cx="10" cy="20" rx="1" ry="1" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="17" cy="15" rx="1" ry="1" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="25" cy="13" rx="1" ry="1" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="33" cy="15" rx="1" ry="1" stroke="#1B1B1B" stroke-width="2"></ellipse>
            </svg>`
        },
      }),
      ModelGenerator: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.ModelGenerator",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
            
              <ellipse cx="6" cy="12" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="10" y1="12" x2="21" y2="7" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="12" x2="21" y2="19" stroke="#1B1B1B" stroke-width="2"></line>
            
              <ellipse cx="6" cy="25" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="10" y1="25" x2="21" y2="7" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="25" x2="21" y2="19" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="25" x2="21" y2="31" stroke="#1B1B1B" stroke-width="2"></line>
            
              <ellipse cx="6" cy="38" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="10" y1="38" x2="21" y2="19" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="38" x2="21" y2="31" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="38" x2="21" y2="43" stroke="#1B1B1B" stroke-width="2"></line>
            
              <ellipse cx="44" cy="12" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="40" y1="12" x2="29" y2="7" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="12" x2="29" y2="19" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="12" x2="29" y2="31" stroke="#1B1B1B" stroke-width="2"></line>
            
              <ellipse cx="44" cy="25" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="40" y1="25" x2="29" y2="19" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="25" x2="29" y2="31" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="25" x2="29" y2="43" stroke="#1B1B1B" stroke-width="2"></line>
            
              <ellipse cx="44" cy="38" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <line x1="40" y1="38" x2="29" y2="31" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="38" x2="29" y2="43" stroke="#1B1B1B" stroke-width="2"></line>
            
              <ellipse cx="25" cy="7" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="25" cy="19" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="25" cy="31" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
              <ellipse cx="25" cy="43" rx="4" ry="4" stroke="#1B1B1B" stroke-width="2"></ellipse>
            </svg>
          `
        },
      }),
      Sizer: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Sizer",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="0,5 0,45 9,45 9,50 13,50 13,45 22,45 22,5 13,5 13,0 9,0 9,5 0,5" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="28,5 28,45 37,45 37,50 41,50 41,45 50,45 50,5 41,5 41,0 37,0 37,5 28,5" stroke="#1B1B1B" stroke-width="2"/>
              
              <line x1="2" y1="11" x2="12" y2="11" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="4" y1="18" x2="14" y2="18" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="6" y1="25" x2="16" y2="25" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="8" y1="32" x2="18" y2="32" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="10" y1="39" x2="20" y2="39" stroke="#1B1B1B" stroke-width="2"></line>
              
              <line x1="48" y1="11" x2="38" y2="11" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="46" y1="18" x2="36" y2="18" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="44" y1="25" x2="34" y2="25" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="42" y1="32" x2="32" y2="32" stroke="#1B1B1B" stroke-width="2"></line>
              <line x1="40" y1="39" x2="30" y2="39" stroke="#1B1B1B" stroke-width="2"></line>  
                
            </svg>`
        },
      }),
      BananaScreen: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.BananaScreen",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="0,5 17,16 35,23 50,25 35,45 0,5" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      VibratingScreen: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.VibratingScreen",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="0,5 50,25 35,45 0,5" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      WetScreen: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.WetScreen",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="0,5 50,25 35,45 0,5" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      Scrubber: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Scrubber",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="10,10 40,10 40,40 10,40 10,10" stroke="#1B1B1B" stroke-width="2"/>
              <ellipse cx="25" cy="25" rx="10" ry="10" stroke="#1B1B1B" stroke-width="2"></ellipse>
            </svg>`
        },
      }),
      Stockpile: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Stockpile",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="5,45 25,20 45,45 5,45" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      OpenTank: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.OpenTank",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"/>
              <polyline points="0,0 0,45 25,50 50,45 50,0" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      PressurisedTank: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.PressurisedTank",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="0,5 0,45 25,50 50,45 50,5" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="0,5 10,0 40,0 50,5 0,5" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      Stacker: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Stacker",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <polyline points="5,45 25,20 45,45 5,45" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="5,18 25,13 20,10 5,14" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      Reclaimer: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.Reclaimer",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              
              <polyline points="25,10 25,5 31,12" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="40,25 45,25 38,31" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="25,40 25,45 19,38" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="10,25 5,25 12,19" stroke="#1B1B1B" stroke-width="2"/>
              
              <polyline points="35,35 39,39 31,38" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="35,15 39,11 38,19" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="15,15 11,11 19,12" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="15,35 11,39 12,31" stroke="#1B1B1B" stroke-width="2"/>
              
              <circle cx="25" cy="25" r="15" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
      ClamshellTrainLoader: draw2d.SVGFigure.extend({
        ...baseEquipmentObject,
        NAME: "minealytics.equipment.ClamshellTrainLoader",
        getSVG: function(){
          return `
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="50" height="50">
              <path d="m 0 0 l 0 50 l 50 0 l 0 -50 z" stroke="#fff" stroke-width="1"></path>
              <circle cx="25" cy="20" r="4" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="21,20 5,25 0,35 20,50 15,40 23,23" stroke="#1B1B1B" stroke-width="2"/>
	            <polyline points="29,20 45,25 50,35 30,50 35,40 27,23" stroke="#1B1B1B" stroke-width="2"/>
              <polyline points="23,16 23,0 27,0 27,16" stroke="#1B1B1B" stroke-width="2"/>
            </svg>`
        },
      }),
    }

    window.minealytics.equipment = EquipmentType

    this.canvas = new draw2d.Canvas('draw2d_canvas')

    this.initializeCanvas()

    this.canvas.on('figure:remove', function(canvas, event) {
      if(event.figure.cssClass === 'draw2d_Connection') {
        return
      }

      const canvasData = []

      canvas.getFigures().each(function (i, figure) {
        canvasData.push(figure.getPersistentAttributes())
      })

      canvas.getLines().each(function (i, element) {
        canvasData.push(element.getPersistentAttributes())
      })

      const job = window.minealytics.model.removeMainEquipment(event.figure.id, JSON.stringify(canvasData))
      self.setState({job})
    })

    this.canvas.onDrop = async (node, x, y) => {
      const nodeType = node.data().type
      const figure = new EquipmentType[nodeType]()

      const onPortConnect = this.onPortConnect

      const portTop = figure.createPort('hybrid', new draw2d.layout.locator.TopLocator())
      portTop.on('connect', onPortConnect)

      const portRight = figure.createPort('hybrid', new draw2d.layout.locator.RightLocator())
      portRight.on('connect', onPortConnect)

      const portBottom = figure.createPort('hybrid', new draw2d.layout.locator.BottomLocator())
      portBottom.on('connect', onPortConnect)

      const portLeft = figure.createPort('hybrid', new draw2d.layout.locator.LeftLocator())
      portLeft.on('connect', onPortConnect)


      this.canvas.add(figure, x, y)
      figure.attr({resizeable: false})
      //TODO: put back this line when the nodeType switch-case is ready!
      // figure.add(new draw2d.shape.basic.Label({text: newEquipment.name}), new draw2d.layout.locator.TopLocator())
      figure.add(new draw2d.shape.basic.Text({text: nodeType}), new draw2d.layout.locator.TopLocator())

      draw2dWriter.marshal(this.canvas, canvasData => {
        const job = window.minealytics.model.addEquipment(figure.id, nodeType, JSON.stringify(canvasData))
        this.setState({job})
      })
    }
  }
}

MainPage.propTypes = {
  onLogout: PropTypes.func.isRequired,
}
