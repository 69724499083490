import {Component} from 'react'
import PropTypes from 'prop-types'

export class MainPageComponent extends Component {
  render() {
    return this.props.children
  }
}

MainPageComponent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
}
