import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import _ from 'lodash'

import {NewMetaInput} from './NewMetaInput'
import {NewMetaItem} from './NewMetaItem'
import './NewMetaContainer.css'

const keyCodeToMetaListIndex = keyCode => keyCode - 49

export const NewMetaContainer = props => {
  function setShortcut() {
    const assignShortcut = e => {
      if(e.ctrlKey && e.keyCode >= 49 && e.keyCode <= 57) {
        const metaListIndex = keyCodeToMetaListIndex(e.keyCode)
        if(props.newMetaList.length - 1 >= metaListIndex) {
          const meta = props.newMetaList[metaListIndex]

          props.addMetaToSelectedImages({
            key: meta.key,
            value: meta.value,
          })
        }
      }
    }

    document.addEventListener('keydown', assignShortcut)

    return () => {
      document.removeEventListener('keydown', assignShortcut)
    }
  }

  React.useEffect(setShortcut, [props.newMetaList])

  return (
    <div className="ImageManager__MetaList">
      {_.map(props.newMetaList, (meta, idx) => (
        <NewMetaItem
          key={idx}
          metaKey={meta.key}
          metaValue={meta.value}
          canAdd={props.areMultipleImagesSelected}
          onAdd={() => {
            props.addMetaToSelectedImages({
              key: meta.key,
              value: meta.value,
            })
          }}
          onRemove={() => {
            props.setNewMetaList(update(props.newMetaList, {
              $splice: [[idx, 1]],
            }))
          }}
        />
      ))}
      <NewMetaInput
        onAdd={(key, value) => {
          props.setNewMetaList(update(props.newMetaList, {
            $push: [{key, value}],
          }))
        }}
      />
    </div>
  )
}

NewMetaContainer.propTypes = {
  newMetaList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  addMetaToSelectedImages: PropTypes.func.isRequired,
  setNewMetaList: PropTypes.func.isRequired,
  areMultipleImagesSelected: PropTypes.bool.isRequired,
}
