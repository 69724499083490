import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Button} from 'cmp/Button/Button'

export const FilterQuery = ({
  fileNameFilter,
  metaFilterList,
  onFilterQueryApply,
}) => {
  const inputRef = React.useRef()

  /* region CALLBACKS */
  const onApply = () => {
    try {
      JSON.parse(inputRef.current.value)
    }
    catch(exception) {
      alert(`JSON syntax error: ${exception.message}`)
      return
    }

    const inputObject = JSON.parse(inputRef.current.value)

    const metaFilterListArray = _.map(inputObject.metaFilterList, (value, key) => ({key, value}))

    onFilterQueryApply({
      fileNameFilter: inputObject.fileName,
      metaFilterList: metaFilterListArray,
    })
  }
  /* endregion CALLBACKS */

  React.useEffect(() => {
    const metaFilterListObject = {}

    metaFilterList.forEach(metaFilter => metaFilterListObject[metaFilter.key] = metaFilter.value)

    inputRef.current.value = JSON.stringify({
      fileName: fileNameFilter,
      metaFilterList: metaFilterListObject,
    }, null, 2)
  }, [fileNameFilter, metaFilterList])

  return (
    <div className="FilterQuery">
      <h6 className="sectionTitle">JSON import/export</h6>

      <textarea
        ref={inputRef}
        className="FilterQueryTextarea"
      />

      <div>
        <Button
          text="Apply"
          onClick={onApply}
        />
      </div>
    </div>
  )
}

FilterQuery.propTypes = {
  fileNameFilter: PropTypes.string.isRequired,
  metaFilterList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onFilterQueryApply: PropTypes.func.isRequired,
}
