import React from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'

import {TAB} from 'cmp/App/MainPage/MainPage'
import {HiddenTabsContextConsumer} from '../MainPage'
import './WorkZone.css'

const cn = makeBem('WorkZone')

export const WorkZone = props => (
  <div className={cn}>
    <HiddenTabsContextConsumer>
      {hiddenTabsContext => {
        return (
          <div className={cn.el('Tabs')}>
            <span
              className={props.activeTab === TAB.DESIGN ? 'active' : undefined}
              onClick={() => props.onTabClick(TAB.DESIGN)}
            >Design</span>
            <span
              className={props.activeTab === TAB.DATA ? 'active' : undefined}
              onClick={() => props.onTabClick(TAB.DATA)}
            >Allocate</span>
            <span
              className={props.activeTab === TAB.ANALYSIS ? 'active' : undefined}
              onClick={() => props.onTabClick(TAB.ANALYSIS)}
            >Analyse</span>
            <span
              className={props.activeTab === TAB.CONTROL ? 'active' : undefined}
              onClick={() => props.onTabClick(TAB.CONTROL)}
            >Control</span>
            {!hiddenTabsContext.hiddenTabs.includes(TAB.IMAGES) && (
              <span
                className={props.activeTab === TAB.IMAGES ? 'active' : undefined}
                onClick={() => props.onTabClick(TAB.IMAGES)}
              >Computer Vision</span>
            )}
          </div>
        )
      }}
    </HiddenTabsContextConsumer>
    <div className={cn.el('Content')}>
      {props.children}
    </div>
  </div>
)

WorkZone.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
}
