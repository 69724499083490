import React from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'

import './Sidebar.css'

const cn = makeBem('Sidebar')

export const Sidebar = props => (
  <div id={cn}>
    <div id={cn.el('Title')}>{props.title}</div>
    {props.children}
  </div>
)

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
}
