/**
 * @class
 * Represents a column like data.
 */
export class DataSet {
  constructor(json) {
    json = json || {data: []}

    this.index = json.index
    this.name = json.name
    this.data = json.data
  }
}
