import _ from 'lodash'

import {EquipmentProperty} from './equipmentProperty'
import {Allocation} from './allocation'
import {Quantity} from './quantity'

/** @class */
export class Variable extends EquipmentProperty {
  /**
   * @param json {object}
   *
   * @param json.key {string}
   * @param json.label {string}
   * @param json.description {string}
   * @param json.quantity {Quantity}
   * @param json.allocations {Allocation[]}
   */
  constructor(json) {
    super(json)

    json = json || {allocations: []}

    this.quantity = new Quantity(json.quantity)
    this.allocations = json.allocations ? json.allocations.map(a => new Allocation(a)) : []
  }

  /**
   * Adds Allocation to the Variable if no Allocation with same dataSource name exists.
   * Updates Allocation if exists.
   *
   * @param allocation {Allocation}
   */
  addAllocation(allocation) {
    const existingAllocation = this.allocations.find(a => a.dataSource.name === allocation.dataSource.name)
    if(existingAllocation !== undefined) {
      if(!_.isNil(allocation.dataSet)) {
        existingAllocation.dataSet = allocation.dataSet
      }

      if(!_.isNil(allocation.timeDataSet)) {
        existingAllocation.timeDataSet = allocation.timeDataSet
      }
    }
    else {
      this.allocations.push(new Allocation(allocation))
    }
  }
}
