import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import makeBem from 'bem-cx'
import ReactTooltip from 'react-tooltip'

import { DataSource } from 'model/dataSource'
import { Draggable } from '../../../../../cmp/DragNDrop/Draggable'
import './DataSourceDrag.css'

const cn = makeBem('DataSourceDrag')

export class DataSourceDrag extends Component {
  static propTypes = {
    dataSource: PropTypes.instanceOf(DataSource).isRequired,
  }

  state = {
    areFirstRowsVisible: false,
  }

  render() {
    return (
      <div>
        <div className={cn.el('Title')}>
          <i className="far fa-file fa-fw"></i>
          {this.props.dataSource.name}
        </div>
        <div
          className={cn.el('PreviewToggle')}
          onClick={() => this.setState({areFirstRowsVisible: !this.state.areFirstRowsVisible})}
        >
          {this.state.areFirstRowsVisible ?
            <i className="fas fa-angle-down fa-fw"></i> :
            <i className="fas fa-angle-right fa-fw"></i>
          }
          {this.state.areFirstRowsVisible ? 'hide first rows' : 'show first rows'}
        </div>
        {this.state.areFirstRowsVisible && (
          <div className={cn.el('Preview')} style={{maxWidth: (window.innerWidth - 350) / 2}}>
            <table>
              <thead>
              <tr>
                {this.props.dataSource.dataSets.map((dataSet, dataSetIndex) => (
                  <th key={dataSetIndex}>{dataSet.name}</th>
                ))}
              </tr>
              </thead>
              <tbody>
              {_.range(5).map(i => (
                <tr key={i}>
                  {this.props.dataSource.dataSets.map((dataSet, dataSetIndex) => (
                    <td key={dataSetIndex}>{dataSet.data[i]}</td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
        {this.props.dataSource.dataSets.map((dataSet, dataSetIndex) => {
          const tooltipDataFor = 'tooltip-' + dataSetIndex

          return (
            <div key={dataSetIndex}>
              <span
                data-tip
                data-for={tooltipDataFor}
              >
                <Draggable
                  label={dataSet.name}
                  payload={JSON.stringify({
                    dataSourceName: this.props.dataSource.name,
                    dataSetIndex: dataSet.index,
                  })}
                />
              </span>
              <ReactTooltip
                id={tooltipDataFor}
                delayShow={300}
              >
                <div>Column index: {dataSetIndex}</div>
              </ReactTooltip>
            </div>
          )
        })}
      </div>
    )
  }
}
