import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import {Button} from 'cmp/Button/Button'
import {FileNameFilter} from './FileNameFilter'
import {MetaFilter} from './MetaFilter'
import {FilterQuery} from './FilterQuery'

export const FileFilter = ({
  fileNameFilter,
  metaFilterList,
  fileContainers,
  fileContainer,
  onSelectFileContainer,
  setFileNameFilter,
  setMetaFilterList,
  onSearch,
}) => {
  /* region CALLBACKS */
  const onAddMetaFilter = newMetaFilter => {
    setMetaFilterList(update(metaFilterList, {
      $push: [newMetaFilter],
    }))
  }

  const onRemoveMetaFilter = metaKey => {
    const metaFilterIdx = metaFilterList.findIndex(m => metaKey === m.key)
    setMetaFilterList(update(metaFilterList, {
      $splice: [[metaFilterIdx, 1]],
    }))
  }

  const onFilterQueryApply = ({fileNameFilter, metaFilterList}) => {
    setFileNameFilter(fileNameFilter)
    setMetaFilterList(metaFilterList)
  }
  /* endregion CALLBACKS */

  return (
    <div id="FileFilter">

      <h6 className="sectionTitle">Filter images</h6>

      {fileContainer && (
        <select
          value={fileContainers.find(c => c.containerId === fileContainer).containerId}
          onChange={e => onSelectFileContainer(e.target.value)}
        >
          {fileContainers.map(container => (
            <option
              key={container.containerId}
              value={container.containerId}
            >{container.containerId} - {container.description}</option>
          ))}
        </select>
      )}

      <FileNameFilter
        fileNameFilter={fileNameFilter}
        setFileNameFilter={setFileNameFilter}
      />

      <MetaFilter
        metaFilterList={metaFilterList}
        addMetaFilter={onAddMetaFilter}
        removeMetaFilter={onRemoveMetaFilter}
      />

      <Button
        text="Search"
        onClick={onSearch}
      />

      <hr/>

      <FilterQuery
        fileNameFilter={fileNameFilter}
        metaFilterList={metaFilterList}
        onFilterQueryApply={onFilterQueryApply}
      />

    </div>
  )
}

FileFilter.propTypes = {
  fileNameFilter: PropTypes.string.isRequired,
  metaFilterList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  fileContainers: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileContainer: PropTypes.string,
  onSelectFileContainer: PropTypes.func.isRequired,
  setFileNameFilter: PropTypes.func.isRequired,
  setMetaFilterList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}
