import {EquipmentProperty} from './equipmentProperty'
import {Quantity} from './quantity'

/** @class */
export class Constant extends EquipmentProperty {
  /**
   * @param json {object}
   *
   * @param json.key {string}
   * @param json.label {string}
   * @param json.description {string}
   * @param json.value {string|undefined}
   * @param json.quantity {Quantity}
   */
  constructor(json) {
    super(json)

    if(typeof json.quantity !== 'object') {
      throw new Error('json.quantity is not an object')
    }

    this.value = json.value || ''
    this.quantity = new Quantity(json.quantity)
  }
}
