import {DataSource} from './dataSource'
import {Plant} from './plant'
import {isCVAnnotation} from 'cmp/App/types'

/** @class */
export class Job {
  constructor(json) {
    json = json || {
        id: null,
        title: '',
        location: '',
        process: '',
        dataSources: [],
        analyses: [],
        controlModules: [],
        allocations: [],
        startTime: '',
        finishTime: '',
        containerId: '',
        userEquipmentSettings: {},
      }

    // older Jobs don't have "userEquipmentSettings"
    if(json && !json.userEquipmentSettings) {
      json.userEquipmentSettings = {}
    }

    this.id = json.id
    this.title = json.title
    this.plant = new Plant(json.plant)
    this.dataSources = json.dataSources.map(d => new DataSource(d))
    this.analyses = json.analyses
    this.controlModules = json.controlModules
    this.location = json.location
    this.process = json.process
    this.startTime = json.startTime
    this.finishTime = json.finishTime
    this.containerId = json.containerId
    this.userEquipmentSettings = json.userEquipmentSettings
  }

  hasCVAnnotation() {
    let result = false

    this.analyses.forEach(analysisKey => {
      if(isCVAnnotation(analysisKey)) {
        result = true
      }
    })

    return result
  }
}
