import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Machine, assign} from 'xstate'
import {useMachine} from '@xstate/react'
import {server} from 'utils/server'
import {Button} from 'cmp/Button/Button'
import {uuid} from 'utils/uuid'

const STATE = {
  NO_FILES_TO_UPLOAD: 'NO_FILES_TO_UPLOAD',
  FILES_SELECTED_TO_UPLOAD: 'FILES_SELECTED_TO_UPLOAD',
  DELETING_FILES: 'DELETING_FILES',
  UPLOADING_FILE: 'UPLOADING_FILE',
}

const EVENT = {
  SELECT_FILES: 'SELECT_FILES',
  DELETE: 'DELETE',
  UPLOAD_FILE: 'UPLOAD_FILE',
}

const stateChart = {
  id: 'TransferBarSM',
  initial: STATE.NO_FILES_TO_UPLOAD,
  context: {
    files: [],
    fileIdxToUpload: 0,

    /**
     * Unique id parameter of the file input HTML element.
     * Changing this key forces the component to rerender the input.
     */
    fileInputKey: uuid(),
  },
  states: {
    [STATE.NO_FILES_TO_UPLOAD]: {
      on: {
        [EVENT.SELECT_FILES]: STATE.FILES_SELECTED_TO_UPLOAD,
        [EVENT.DELETE]: STATE.DELETING_FILES,
      },
    },
    [STATE.FILES_SELECTED_TO_UPLOAD]: {
      entry: ['setSelectedFilesToUpload'],
      on: {
        [EVENT.UPLOAD_FILE]: STATE.UPLOADING_FILE,
      },
    },
    [STATE.DELETING_FILES]: {
      invoke: {
        src: 'deleteFiles',
        onDone: {
          target: STATE.NO_FILES_TO_UPLOAD,
          actions: ['transferReady'],
        },
      },
    },
    
    [STATE.UPLOADING_FILE]: {
      invoke: {
        src: 'uploadFile',
        onDone: [
          {
            target: [STATE.UPLOADING_FILE],
            cond: context => {
              return context.fileIdxToUpload + 1 < context.files.length
            },
            actions: ['incrementFileIdx'],
          },
          {
            target: [STATE.NO_FILES_TO_UPLOAD],
            cond: context => {
              return context.fileIdxToUpload + 1 === context.files.length
            },
            actions: ['transferReady'],
          },
        ],
      }
    },
  },
}

const stateChartOptions = {
  services: {
    uploadFile: async(context) => {
      const file = context.files[context.fileIdxToUpload]

      await server.uploadFile2(
        file,
        context.container,
        context.metaFilterList,
        context.path,
        context.jobTitle,
      )
    },
    deleteFiles: async(context, event) => {
      await Promise.all(_.map(event.data.selectedFiles, async(fileInfo) => {
        await server.deleteFile2(
          context.container,
          fileInfo.fileName,
        )
      }))
    },
  },
  actions: {
    setSelectedFilesToUpload: assign((context, event) => {
      return event.data
    }),
    transferReady: assign((context, event) => {
      if(event.type === 'done.invoke.deleteFiles') {
        alert(`Selected file(s) deleted`)
        context.onDone()
      }
      else {
        alert(`${context.files.length} files uploaded`)
      }

      return {
        files: [],
        fileInputKey: uuid(),
      }
    }),
    incrementFileIdx: assign(context => {
      return {
        fileIdxToUpload: context.fileIdxToUpload + 1,
      }
    }),
  },
}

export const TransferBar = ({
  container,
  metaFilterList,
  path,
  jobTitle,
  onDone,
  selectedFiles,
}) => {
  stateChart.context.container = container
  stateChart.context.path = path
  stateChart.context.jobTitle = jobTitle
  stateChart.context.onDone = onDone
  stateChart.context.metaFilterList = metaFilterList

  const [state, send, service] = useMachine(Machine(stateChart, stateChartOptions))

  /* region LOGGING */
  // React.useEffect(() => service.subscribe(state => console.log(state)).unsubscribe, [service])
  /* endregion LOGGING */
  
  return (
    <div id="TransferBar">
      <div className="buttonGroup">
        <Button
          text="Upload Files"
          disabled={_.isEmpty(state.context.files)}
          onClick={() => send(EVENT.UPLOAD_FILE)}
        />
        <input
          type="file"
          key={state.context.fileInputKey}
          onChange={e => send(EVENT.SELECT_FILES, {data: {files: e.target.files}})}
          multiple
        />
        {state.matches(STATE.UPLOADING_FILE) && <div>Uploading files {state.context.fileIdxToUpload + 1}/{state.context.files.length}</div>}
        <Button
          text="Delete"
          disabled={!selectedFiles.length && selectedFiles.length === 0}
          onClick={() => {
            if(window.confirm('Selected file(s) will be deleted! Continue?')) {
              send(EVENT.DELETE, {data: {selectedFiles}})
            }
          }}
        />
      </div>
    </div>
  )
}

TransferBar.propTypes = {
  container: PropTypes.string.isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  metaFilterList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  path: PropTypes.string.isRequired,
  jobTitle:  PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
}
