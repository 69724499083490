import {DataSet} from './dataSet'

/**
 * @class
 * Represents table like data. Each DataSet in a DataSource is a column.
 */
export class DataSource {
  constructor(json) {
    json = json || {dataSets: []}

    this.name = json.name
    this.container = json.container
    this.dataSets = json.dataSets.map(d => new DataSet(d))
  }
}
