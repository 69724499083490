import React from 'react'
import PropTypes from 'prop-types'

import './Draggable.css'

export const Draggable = props => (
  <div
    className="Draggable"
    draggable
    onDragStart={e => e.dataTransfer.setData('draggable_payload', props.payload)}
  >
    <i className="fas fa-grip-vertical"></i>
    {props.label}
  </div>
)

Draggable.propTypes = {
  label: PropTypes.string.isRequired,
  payload: PropTypes.string.isRequired,
}
