import React from 'react'
import makeBem from 'bem-cx'
import PropTypes from 'prop-types'
import _ from 'lodash'
import update from 'immutability-helper'

import {Job} from 'model/job'
import {DataSource} from 'model/dataSource'
import { MainPageComponent } from '../../../../cmp/App/MainPage/MainPageComponent'
import {AllocationTree} from './AllocationTree'
import {DataSourceControl} from './DataSourceControl'
import './Allocator.css'
import { server } from '../../../../utils/server'
import { Sidebar } from '../../../../cmp/App/MainPage/Sidebar'
import { WorkZone } from '../../../../cmp/App/MainPage/WorkZone'
import {DataSourceDrag} from './DataSourceDrag/DataSourceDrag'

const cn = makeBem('Allocator')

const AllocatorContext = React.createContext({})
const AllocatorContextProvider = AllocatorContext.Provider
export const AllocatorContextConsumer = AllocatorContext.Consumer

export class Allocator extends MainPageComponent {
  static propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.instanceOf(DataSource)).isRequired,
    containers: PropTypes.arrayOf(PropTypes.shape({
      containerId: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })).isRequired,
    changePlant: PropTypes.func.isRequired,
    addDataSource: PropTypes.func.isRequired,
    addAllocationToVariable: PropTypes.func.isRequired,
    removeAllocationFromVariable: PropTypes.func.isRequired,
    addAllocationToTimeNode: PropTypes.func.isRequired,
  }
  
  state = {
    selectedDataSourceIndex: 0,
    allocations: [],
  }

  render() {
    const dataSource = this.props.dataSources[this.state.selectedDataSourceIndex]

    return (
      <>
        <Sidebar title="Allocate options">
          <AllocatorContextProvider
            value={{
              job: this.props.job,
              copyAllocation: (source, target) => {
                this.props.copyAllocation(source, target)
              },
              resetAllocations: this.props.resetAllocations,
              containers: this.props.containers,
              appSettings: this.props.appSettings,
            }}
          >
            <DataSourceControl
              plant={this.props.job.plant}
              dataSources={this.props.dataSources}
              selectedDataSourceIndex={this.state.selectedDataSourceIndex}
              setPlant={plant => this.props.changePlant(plant)}
              addDataSource={async (resourceId, container) => {
                let dataSource

                if(container === 'USER_DB') {
                  dataSource = await server.get({url: `dataQuery/dataSource/${resourceId}`})
                }
                else {
                  dataSource = await server.get({
                    url: `blob/files/${container}/${resourceId}`,
                    type: PropTypes.shape(DataSource).isRequired,
                  })
                }

                dataSource.container = container
                this.props.addDataSource(dataSource)
              }}
              deleteDataSource={() => {
                this.setState({selectedDataSourceIndex: 0})
                this.props.deleteDataSource()
              }}
              selectDataSourceIndex={selectedDataSourceIndex => this.setState({selectedDataSourceIndex})}
            />
          </AllocatorContextProvider>
        </Sidebar>
        <WorkZone activeTab={this.props.activeTab} onTabClick={this.props.onTabClick}>
          <div className={cn.el('WorkZone')}>
            {dataSource && (
              <div className={cn.el('plant')}>
                <AllocatorContextProvider
                  value={{
                    addAllocationToVariable: (equipmentId, variableKey, allocationJson) => {
                      this.props.addAllocationToVariable(equipmentId, variableKey, allocationJson)
                    },
                    removeAllocationFromVariable: (equipmentId, variableKey, dataSourceName) => {
                      this.props.removeAllocationFromVariable(equipmentId, variableKey, dataSourceName)
                    },
                    setUnit: (equipmentId, variableKey, value) => {
                      this.props.setUnit(equipmentId, variableKey, value)
                    },
                  }}
                >
                  <AllocationTree
                    plant={this.props.job.plant}
                    dataSource={this.props.dataSources[this.state.selectedDataSourceIndex] ? this.props.dataSources[this.state.selectedDataSourceIndex] : null}
                    setPlant={plant => this.props.changePlant(plant)}
                    addAllocation={this.props.addAllocationToTimeNode}
                  />
                </AllocatorContextProvider>
              </div>
            )}
            <div className={cn.el('data')}>
              {dataSource && <DataSourceDrag dataSource={dataSource}/>}
            </div>
          </div>
        </WorkZone>
      </>
    )
  }

  onVariableDropOnPlant = payload => {
    const dataSet = JSON.parse(payload)
    const selectedFileName= this.props.dataSources[this.state.selectedDataSourceIndex] ? this.props.dataSources[this.state.selectedDataSourceIndex].name : null
    const timeDataSetIndex = this.state.allocations.findIndex(a => a.isPlant && a.dataSource === selectedFileName)

    if(dataSet.isDelete) {
      this.setState(state => update(state, {
        allocations: {
          $splice: [[timeDataSetIndex, 1]],
        },
      }))
    }
    else {
      if(timeDataSetIndex === -1) {
        this.setState(state => update(state, {
          allocations: {
            $push: [{
              equipmentId: null,
              isPlant: true,
              equipmentContainerLabel: null,
              timeDataSet: dataSet.headerIndex,
              dataSource: selectedFileName,
            }],
          },
        }))
      }
      else {
        this.setState(state => update(state, {
          allocations: {
            [timeDataSetIndex]: {
              timeDataSet: {
                $set: dataSet.headerIndex,
              },
            },
          },
        }))
      }
    }
  }

  onVariableDropOnEquipment = (dataSet, ids, type, isTimestamp, key) => {
    const plantClone = _.cloneDeep(this.props.job.plant)
    let equipment = plantClone.equipmentList.find(e => e.id === ids[0])
    const selectedFileName= this.props.dataSources[this.state.selectedDataSourceIndex] ? this.props.dataSources[this.state.selectedDataSourceIndex].name : null

    ids.shift()

    ids.forEach(id => {
      equipment.equipmentContainers.forEach(equipmentContainer => {
        equipmentContainer.equipmentList.forEach(e => {
          if(e.id === id) {
            equipment = e
          }
        })
      })
    })

    if(type === 'variable') {
      if(isTimestamp) {
        const variable = equipment.variables.find(v => v.key === key)

        const allocationIndex = variable.allocations.findIndex(a => a.dataSource === selectedFileName)

        if(allocationIndex >= 0) {
          variable.allocations[allocationIndex].timeDataSet = dataSet.headerIndex
        }
        else {
          variable.allocations.push({
            dataSource: selectedFileName,
            dataSet: null,
            timeDataSet: dataSet.headerIndex,
          })
        }
      }
      else {
        const findTimeDataSet = equipmentId => {
          const plant = this.convertPlantToAllocation(plantClone)
          let result = plant.timeDataSet

          const checkEquipment = equipment => {
            if(equipment.id === equipmentId) {
              if(equipment.timeDataSet) {
                result = equipment.timeDataSet
              }
            }
            else {
              equipment.equipmentContainers.forEach(c => c.equipmentList.forEach(e => checkEquipment(e)))
            }
          }

          plant.equipmentList.forEach(e => checkEquipment(e))

          return result
        }

        const variable = equipment.variables.find(v => v.key === key)

        const allocationIndex = variable.allocations.findIndex(a => a.dataSource === selectedFileName)

        if(allocationIndex >= 0) {
          variable.allocations[allocationIndex].dataSet = dataSet.headerIndex
        }
        else {
          const timeDataSetName = findTimeDataSet(equipment.id)
          const timeDataSet = this.props.dataSources.find(d => d.name === selectedFileName).dataSets.find(d => d.headerField === timeDataSetName).headerIndex

          variable.allocations.push({
            dataSource: selectedFileName,
            dataSet: dataSet.headerIndex,
            timeDataSet,
          })
        }
      }

      this.props.changePlant(plantClone)
    }
    else if(type === 'equipment') {
      const timeDataSetIndex = this.state.allocations.findIndex(a => a.dataSource === selectedFileName && a.equipmentId === equipment.id)

      if(timeDataSetIndex === -1) {
        this.setState(state => update(state, {
          allocations: {
            $push: [{
              equipmentId: equipment.id,
              isPlant: false,
              equipmentContainerLabel: null,
              timeDataSet: dataSet.headerIndex,
              dataSource: selectedFileName,
            }],
          },
        }))
      }
      else {
        this.setState(state => update(state, {
          allocations: {
            [timeDataSetIndex]: {
              timeDataSet: {
                $set: dataSet.headerIndex,
              },
            },
          },
        }))
      }
    }
    else if(type === 'container') {
      const timeDataSetIndex = this.state.allocations.findIndex(a => a.dataSource === selectedFileName && a.equipmentId === equipment.id && a.equipmentContainerLabel === key)

      if(timeDataSetIndex === -1) {
        this.setState(state => update(state, {
          allocations: {
            $push: [{
              equipmentId: equipment.id,
              isPlant: false,
              equipmentContainerLabel: key,
              timeDataSet: dataSet.headerIndex,
              dataSource: selectedFileName,
            }],
          },
        }))
      }
      else {
        this.setState(state => update(state, {
          allocations: {
            [timeDataSetIndex]: {
              timeDataSet: {
                $set: dataSet.headerIndex,
              },
            },
          },
        }))
      }
    }
    else {
      throw new Error('Incorrect parameter "type"')
    }
  }

  //TODO: this method is duplicated!
  convertPlantToAllocation = plant => {
    const selectedFileName= this.props.dataSources[this.state.selectedDataSourceIndex] ? this.props.dataSources[this.state.selectedDataSourceIndex].name : null
    const dataSource = this.props.dataSources.find(d => d.name === selectedFileName)

    const convertEquipment = equipment => {
      const equipmentAllocation = this.state.allocations.find(a => a.equipmentContainerLabel === null && !a.isPlant && a.equipmentId === equipment.id && a.dataSource === selectedFileName)

      if(equipmentAllocation) {
        const dataSet = dataSource.dataSets[equipmentAllocation.timeDataSet]
        equipment.timeDataSet = dataSet.headerField ? dataSet.headerField : `unnamed index:${dataSet.headerIndex}`
      }
      else {
        equipment.timeDataSet = null
      }

      equipment.equipmentContainers.forEach(equipmentContainer => {
        const containerAllocation = this.state.allocations.find(a => !a.isPlant && a.equipmentId === equipment.id && a.equipmentContainerLabel === equipmentContainer.label && a.dataSource === selectedFileName)

        if(containerAllocation) {
          const dataSet = dataSource.dataSets[containerAllocation.timeDataSet]
          equipmentContainer.timeDataSet = dataSet.headerField ? dataSet.headerField : `unnamed index:${dataSet.headerIndex}`
        }
        else {
          equipmentContainer.timeDataSet = null
        }

        equipmentContainer.equipmentList.forEach(e => convertEquipment(e))
      })
    }

    const allocation = _.cloneDeep(plant)

    const plantAllocation = this.state.allocations.find(a => a.isPlant && a.dataSource === selectedFileName)

    if(plantAllocation) {
      const dataSet = dataSource.dataSets[plantAllocation.timeDataSet]
      allocation.timeDataSet = dataSet.headerField ? dataSet.headerField : `unnamed index:${dataSet.headerIndex}`
    }
    else {
      allocation.timeDataSet = null
    }

    allocation.equipmentList.forEach(e => convertEquipment(e))

    return allocation
  }

  resetAllocation = plant => {
    this.props.changePlant(plant)
    this.setState({allocations: []})
  }
}
