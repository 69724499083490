import React from 'react'
import PropTypes from 'prop-types'

export const NewMetaItem = props => (
  <div className="NewMetaItem">
    <div className="metaItem">
      <span className="metaKey">{props.metaKey}</span>
      <span className="metaValue">{props.metaValue}</span>
      <span className="icons">
        <i
          className={'editIcon fas fa-check'}
          onClick={props.onAdd}
        />
        <i
          className="editIcon far fa-trash-alt"
          onClick={props.onRemove}
        />
      </span>
    </div>
  </div>
)

NewMetaItem.propTypes = {
  metaKey: PropTypes.string.isRequired,
  metaValue: PropTypes.string.isRequired,
  canAdd: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}
