import React from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

import Tree, {TreeNode} from 'cmp/Tree'
import {DropZone} from 'cmp/DragNDrop/DropZone'
import {EventAllocator} from './EventAllocator'
import {DataSource} from 'model/dataSource'
import {Plant} from 'model/plant'
import {MG_SUPPORT_TYPE} from 'model/constants'
import {AllocatorContextConsumer} from './Allocator'
import {uuid} from 'utils/uuid'
import {MainPageContextConsumer} from 'cmp/App/MainPage/MainPage'
import {Modal} from 'cmp/Modal'
import {FileControl} from 'cmp/FileControl/FileControl'
import {FILE_INFO_TYPE} from 'cmp/FileControl/class'
import {Button} from 'cmp/Button/Button'

const cn = makeBem('AllocationTree')

const TIME_NODE_TYPE = {
  PLANT: 'PLANT',
  EQUIPMENT: 'EQUIPMENT',
  CONTAINER: 'CONTAINER',
  EVENT: 'EVENT',
}

// TimeNode

const TimeNode = props => {
  let allocation = {
    dataSource: null,
    dataSet: null,
    timeDataSet: null,
  }

  const entitysAllocation = props.entity.allocations.find(a => a.dataSource.name === props.dataSource.name)

  if(entitysAllocation !== undefined) {
    allocation = entitysAllocation
  }
  else {
    if(props.allocation !== undefined) {
      allocation = props.allocation
    }
    else {

    }
  }

  const onDrop = allocationString => {
    const allocationObject = JSON.parse(allocationString)

    if(allocationObject.isDelete) {
      props.addAllocation(props.dataSource.name, null, props.entity)
    }
    else {
      props.addAllocation(props.dataSource.name, allocationObject.dataSetIndex, props.entity)
    }
  }

  switch(props.type) {
    case TIME_NODE_TYPE.PLANT:
      const plant = props.entity

      return (
        <Tree>
          <TreeNode
            label={(
              <span>
              <div className={cn.el('Icon')}>
                <i className="fas fa-industry"></i>
              </div>
              <DropZone
                label={(
                  <span>
                    <i className="fas fa-clock"></i>
                    {/*<span className={cn.el('DropZoneLabel')}>{_.isNil(allocation.timeDataSet) ? '-' : allocation.timeDataSet.name}</span>*/}
                    <span className={cn.el('DropZoneLabel')}>{(!_.isNil(allocation) && !_.isNil(allocation.timeDataSet)) ? allocation.timeDataSet.name : '-'}</span>
                  </span>
                )}
                isEmpty={_.isNil(allocation.timeDataSet)}
                onDrop={onDrop}
              />
              <span>Plant</span>
            </span>
            )}
          >
            {plant.equipmentList.filter(e => !e.isControlModule).map((equipment, equipmentIndex) => (
              <div
                key={equipmentIndex}
              >
                <TimeNode
                  entity={equipment}
                  dataSource={props.dataSource}
                  type={TIME_NODE_TYPE.EQUIPMENT}
                  allocation={allocation}
                  addAllocation={props.addAllocation}
                />
              </div>
            ))}
          </TreeNode>
        </Tree>
      )

    case TIME_NODE_TYPE.EQUIPMENT:
      const equipment = props.entity

      return (
        <MainPageContextConsumer>
          {mainPageContext => (
            <TreeNode
              label={(
                <span>
              <div className={cn.el('Icon')}>
                <i className="fas fa-cog"></i>
              </div>
              <DropZone
                label={(
                  <span>
                    <i className="fas fa-clock"></i>
                    {/*<span className={cn.el('DropZoneLabel')}>{_.isNil(allocation.timeDataSet) ? '-' : allocation.timeDataSet.name}</span>*/}
                    <span className={cn.el('DropZoneLabel')}>{(!_.isNil(allocation) && !_.isNil(allocation.timeDataSet)) ? allocation.timeDataSet.name : '-'}</span>
                  </span>
                )}
                isEmpty={_.isNil(allocation.timeDataSet)}
                onDrop={onDrop}
              />
              Equipment: {equipment.name}
            </span>
              )}
            >
              {equipment.variables.map((variable, variableIndex) => (
                <div
                  key={variableIndex}
                >
                  <VariableNode
                    variable={variable}
                    allocation={allocation}
                    dataSource={props.dataSource}
                    equipmentId={equipment.id}
                  />
                </div>
              ))}

              {equipment.equipmentContainers.map((container, containerIndex) => (
                <div
                  key={containerIndex}
                >
                  <TimeNode
                    entity={container}
                    dataSource={props.dataSource}
                    type={TIME_NODE_TYPE.CONTAINER}
                    allocation={allocation}
                    addAllocation={props.addAllocation}
                  />
                </div>
              ))}
            </TreeNode>
          )}
        </MainPageContextConsumer>
      )

    case TIME_NODE_TYPE.CONTAINER:
      const container = props.entity

      return (
        <TreeNode
          label={(
            <span>
              <div className={cn.el('Icon')}>
                <i className="fas fa-box-open"></i>
              </div>
              <DropZone
                label={(
                  <span>
                    <i className="fas fa-clock"></i>
                    {/*<span className={cn.el('DropZoneLabel')}>{_.isNil(allocation.timeDataSet) ? '-' : allocation.timeDataSet.name}</span>*/}
                    <span className={cn.el('DropZoneLabel')}>{(!_.isNil(allocation) && !_.isNil(allocation.timeDataSet)) ? allocation.timeDataSet.name : '-'}</span>
                  </span>
                )}
                isEmpty={_.isNil(allocation.timeDataSet)}
                onDrop={onDrop}
              />
              Container: {container.label}
            </span>
          )}
        >
          {container.equipmentList.map((equipment, equipmentIndex) => (
            <div
              key={equipmentIndex}
            >
              <TimeNode
                entity={equipment}
                dataSource={props.dataSource}
                type={TIME_NODE_TYPE.EQUIPMENT}
                allocation={allocation}
                addAllocation={props.addAllocation}
              />
            </div>
          ))}
        </TreeNode>
      )

    case TIME_NODE_TYPE.EVENT:
      return (
        <div>...</div>
      )

    default:
      console.log('Incorrect TIME_NODE_TYPE')
  }
}

TimeNode.propTypes = {
  entity: PropTypes.object,
  allocation: PropTypes.instanceOf(Object),
  dataSource: PropTypes.instanceOf(DataSource).isRequired,
  type: PropTypes.oneOf(_.toArray(TIME_NODE_TYPE)).isRequired,
  addAllocation: PropTypes.func.isRequired,
}

export const AllocationTree = props => {
  const addEventAllocation = (context, key, allocation) => {
    context.addEventAllocation(key, allocation)
  }

  const removeEventAllocation = (context, key) => {
    context.removeEventAllocation(key, props.dataSource.name)
  }

  const eventAllocationCategories = [
    {
      label: 'Event timing',
      icon: 'fas fa-clock',
      allocations: [
        {
          key: 'event_start_time',
          label: 'Event start time',
        },
        {
          key: 'event_end_time',
          label: 'Event end time / Return to normal time',
        },
        {
          key: 'event_acknowledged_time',
          label: 'Event acknowledged time',
        },
        {
          key: 'event_duration',
          label: 'Event duration',
        },
      ],
    },
    {
      label: 'Event identification',
      icon: 'fas fa-eye',
      allocations: [
        {
          key: 'tag_name',
          label: 'Tag name',
        },
        {
          key: 'alarm_state',
          label: 'Alarm state',
        },
      ],
    },
    {
      label: 'Event user input / description',
      icon: 'fas fa-user',
      allocations: [
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'comment',
          label: 'Comment',
        },
        {
          key: 'operator_id',
          label: 'Operator ID / name when the event occurred',
        },
      ],
    },
    {
      label: 'Event localisation information',
      icon: 'fas fa-globe',
      allocations: [
        {
          key: 'plant',
          label: 'Plant',
        },
        {
          key: 'area',
          label: 'Area',
        },
        {
          key: 'sub_area',
          label: 'Sub area',
        },
        {
          key: 'process_zone',
          label: 'Process / Zone',
        },
        {
          key: 'equipment',
          label: 'Equipment',
        },
        {
          key: 'part',
          label: 'Part',
        },
      ],
    },
    {
      label: 'Failure information',
      icon: 'fas fa-exclamation-triangle',
      allocations: [
        {
          key: 'failure_mode',
          label: 'Failure mode',
        },
        {
          key: 'failure_cause',
          label: 'Failure cause',
        },
        {
          key: 'failure_effect',
          label: 'Failure effect',
        },
        {
          key: 'failure_classification',
          label: 'Failure classification',
        },
        {
          key: 'severity',
          label: 'Severity (impact)',
        },
        {
          key: 'priority',
          label: 'Priority (timing)',
        },
      ],
    },
  ]

  return (
    <div className={cn}>

      <TimeNode
        entity={props.plant}
        dataSource={props.dataSource}
        type={TIME_NODE_TYPE.PLANT}
        addAllocation={props.addAllocation}
      />

      <MainPageContextConsumer>
        {context => (
          <TreeNode label={(
            <span>
          <div className={cn.el('Icon')}>
            <i className="fas fa-bolt"></i>
          </div>
          <span>Event Data</span>
        </span>
          )}>

            {eventAllocationCategories.map(category => (
              <TreeNode key={category.label} label={(
                <span className="Event_data_label">
                  <div className={cn.el('Icon')}>
                    <i className={category.icon}></i>
                  </div>
                  <span>{category.label}</span>
                </span>
              )}>
                {category.allocations.map(allocation => (
                  <div key={allocation.key}>
                    <EventAllocator
                      label={allocation.label}
                      allocation={props.plant.eventAllocations.find(e => (e.key === allocation.key && e.dataSource.name === props.dataSource.name))}
                      addAllocation={newAllocation => addEventAllocation(context, allocation.key, newAllocation)}
                      removeAllocation={() => removeEventAllocation(context, allocation.key)}
                    />
                  </div>
                ))}
              </TreeNode>
            ))}
            
          </TreeNode>
        )}
      </MainPageContextConsumer>

    </div>
  )
}

AllocationTree.displayName = 'AllocationTree'

AllocationTree.propTypes = {
  plant: PropTypes.instanceOf(Plant).isRequired,
  dataSource: PropTypes.instanceOf(DataSource),
  addAllocation: PropTypes.func.isRequired,
}

// VariableNode

const VariableNode = props => {
  let allocation
  const tooltipId = uuid()
  const allocationInVariable = props.variable.allocations.find(a => a.dataSource.name === props.dataSource.name)

  /**
   * @param context {object} - AllocatorContext providing 'onAllocationDropOnVariable' method
   * @param allocationPayload {string} - '{dataSourceName:[string], dataSetIndex:[number]}' OR '{isDelete:[bool]}'
   * @param isTimeAllocation {boolean|undefined}
   */
  const onAllocationDropOnVariable = (context, allocationPayload, isTimeAllocation) => {
    const allocationJson = JSON.parse(allocationPayload)
    let allocation = props.variable.allocations.find(a => a.dataSource.name === props.dataSource.name)

    if(allocationJson.isDelete) {
      context.removeAllocationFromVariable(props.equipmentId, props.variable.key, props.dataSource.name)
      return
    }

    if(isTimeAllocation) {
      if(allocation === undefined) {
        allocation = {
          dataSource: props.dataSource.name,
          timeDataSet: allocationJson.dataSetIndex,
        }
      }
      else {
        allocation = {
          dataSource: props.dataSource.name,
          dataSet: allocation.dataSet ? allocation.dataSet.index : null,
          timeDataSet: allocationJson.dataSetIndex,
        }
      }
    }
    else {
      if(allocation === undefined) {
        allocation = {
          dataSource: props.dataSource.name,
          dataSet: allocationJson.dataSetIndex,
          timeDataSet: props.allocation.timeDataSet.index,
        }
      }
      else {
        allocation = {
          dataSource: props.dataSource.name,
          dataSet: allocationJson.dataSetIndex,
          timeDataSet: allocation.timeDataSet.index,
        }
      }
    }

    context.addAllocationToVariable(props.equipmentId, props.variable.key, allocation)
  }

  if(_.isNil(allocationInVariable)) {
    if(_.isNil(props.allocation)) {
      allocation = null
    }
    else {
      allocation = props.allocation
    }
  }
  else {
    allocation = allocationInVariable
  }

  return (
    <AllocatorContextConsumer>
      {context => (
        <span
          className={cn.el('Variable')}
        >
          <div className={cn.el('Icon').mod('Variable')}>
            <i className="fas fa-ruler"></i>
          </div>
          <DropZone
            isEmpty={_.isNil(allocation.timeDataSet)}
            label={(
              <span>
                <i className="fas fa-clock"></i>
                <span className={cn.el('DropZoneLabel')}>{(!_.isNil(allocation) && !_.isNil(allocation.timeDataSet)) ? allocation.timeDataSet.name : '-'}</span>
              </span>
            )}
            onDrop={payload => onAllocationDropOnVariable(context, payload, true)}
          />
            {!_.isNil(allocation.timeDataSet) && (
              <DropZone
                isEmpty={_.isNil(allocation.dataSet)}
                label={(
                  <span>
                    <i className="fas fa-database"></i>
                    <span className={cn.el('DropZoneLabel')}>{(!_.isNil(allocation) && !_.isNil(allocation.dataSet)) ? allocation.dataSet.name : '-'}</span>
                  </span>)}
                onDrop={payload => onAllocationDropOnVariable(context, payload, false)}
              />
            )}

            {!_.isNil(allocation) && !_.isNil(allocation.timeDataSet) && !_.isNil(allocation.dataSet) && (
              <select
                value={props.variable.quantity.unit || ''}
                onChange={e => {
                  // window.minealytics.model.setUnit(props.equipmentId, props.variable.key, e.target.value)
                  context.setUnit(props.equipmentId, props.variable.key, e.target.value)
                }}
              >
                {props.variable.quantity.units.map(unit => (
                  <option key={unit} value={unit}>{unit}</option>
                ))}
              </select>
            )}

            <span
              data-tip
              data-for={tooltipId}
            >{props.variable.label}</span>
            <ReactTooltip
              id={tooltipId}
            >
              <div>{props.variable.description}</div>
            </ReactTooltip>
        </span>
      )}
    </AllocatorContextConsumer>
  )
}

VariableNode.propTypes = {
  variable: PropTypes.object.isRequired,
  allocation: PropTypes.instanceOf(Object),
  dataSource: PropTypes.instanceOf(DataSource),
  equipmentId: PropTypes.string.isRequired,
}
