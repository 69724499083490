import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Pagination} from './Pagination'
import './FileDisplay.css'

export const FileDisplay = ({
  fileInfoList,
  fileFilter,
  fileCount,
  page,
  selectedFileIdxList,
  setPage,
  onToggleFileIdx,
  doFilter,
}) => {
  const isFilteredOut = fileInfo => !doFilter || fileFilter.filter(fileInfo) ? '' : 'isFilteredOut'
  const hasFileList = fileInfoList.length !== 0

  return (
    <div id="FileDisplay">
      <div className="fileDetails">
        {hasFileList ? (
          <table>
            <thead>
            <tr>
              <th></th>
              <th>File name</th>
              <th>Size</th>
              <th>Meta</th>
            </tr>
            </thead>
            <tbody>
            {fileInfoList.map((fileInfo, fileInfoIdx) => {

              const metaSummarized = fileInfo.metaList.map(meta => {
                let value = meta.value

                return `${meta.key}=${value}`
              }).join('\n')

              const href = fileInfo.uri
              const isChecked = _.includes(selectedFileIdxList, fileInfoIdx)

              return (
                <tr key={fileInfo.fileName} className={`fileInfoRow ${isFilteredOut(fileInfo)}`}>
                  <td>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={e => onToggleFileIdx(fileInfoIdx, e.target.checked)}
                    />
                  </td>
                  <td>
                    <a href={href} target="_blank" rel="noopener noreferrer">{fileInfo.fileName}</a>
                  </td>
                  <td>{fileInfo.size}</td>
                  <td>{metaSummarized}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        ) : <div>empty...</div>}
      </div>
      {hasFileList && <Pagination fileCount={fileCount} selectedPage={page} setPage={setPage} />}
    </div>
  )
}

FileDisplay.propTypes = {
  fileInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * @param {fileCount} - Pagination component uses for calculations
   */
  fileCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  selectedFileIdxList: PropTypes.arrayOf(PropTypes.number).isRequired,
  setPage: PropTypes.func.isRequired,

  /**
   * Triggered when user clicks on checkbox in a row of a file info.
   * @callback onToggleFileIdxCallback
   * @param {number} fileIdx - Index of file in FileManager's Statechart's context.
   */

  /**
   * @param {onToggleFileIdx} onToggleFileIdxCallback
   */
  onToggleFileIdx: PropTypes.func.isRequired,

  /**
   * @param {fileFilter} - In 'static mode', filtering files is executed in FileDisplay, and this parameter is used for the filtering.
   */
  fileFilter: PropTypes.object.isRequired,

  /**
   * @param {doFilter} - In 'static mode', value is true.
   */
  doFilter: PropTypes.bool,
}
