import React from 'react'
import PropTypes from 'prop-types'
import {EquipmentSettings} from './EquipmentSettings/EquipmentSettings'
import {EquipmentTree} from './EquipmentTree/EquipmentTree'
import {EquipmentDataQuery} from './EquipmentDataQuery/EquipmentDataQuery'
import {toast, ToastContainer} from 'react-toastify'
import {Equipment} from 'cmp/App/types'
import {MainPageComponent} from 'cmp/App/MainPage/MainPageComponent'
import {Sidebar} from 'cmp/App/MainPage/Sidebar'
import {WorkZone} from 'cmp/App/MainPage/WorkZone'
import ServiceApps from 'cmp/ServiceApps'
import {server} from 'utils/server'
import './Designer.css'

const TAB = {
  EQUIPMENT_TREE: 'EQUIPMENT_TREE',
  EQUIPMENT_SETTINGS: 'EQUIPMENT_SETTINGS',
  DATA_QUERY: 'DATA_QUERY',
  APPS: 'APPS',
}

export class Designer extends MainPageComponent {
  static propTypes = {
    job: PropTypes.object.isRequired,
    equipment: PropTypes.shape(Equipment),
    onCanvasReady: PropTypes.func.isRequired,
    fileQueries: PropTypes.array.isRequired,
    appSettings: PropTypes.arrayOf(PropTypes.shape({
      appName: PropTypes.string.isRequired,
      appDescription: PropTypes.string.isRequired,
      endpoint: PropTypes.string.isRequired,
    })).isRequired,
    container: PropTypes.string.isRequired,
  }

  state = {
    activeTab: TAB.EQUIPMENT_TREE,
  }

  componentDidMount() {
    this.props.onCanvasReady()
  }

  componentWillReceiveProps(newProps) {
    if(!!newProps.equipment) {
      if(this.props.equipment !== newProps.equipment) {
        this.setState({activeTab: TAB.EQUIPMENT_SETTINGS})
      }
    }
    else {
      this.setState({activeTab: TAB.EQUIPMENT_TREE})
    }
  }

  sleep = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms || 1000))
  }

  onAppStart = async(id) => {
    const settings = this.props.appSettings.find(a => a.id === id)
    const payload = {
      process_design: JSON.stringify(this.props.job.plant),
      selected_equipment: JSON.stringify(this.props.equipment),
      app_name: settings.appName,
      endpoint: settings.endpoint,
      container: this.props.container,
      project_name: this.props.job.title,
    }

    const responsePayload = await server.postDesignerApp(payload)
    let appUiUrl

    if(responsePayload && responsePayload.app_url) {
      appUiUrl = responsePayload.app_url
    }
    else {
      appUiUrl = settings.appUiUrl
    }

    toast.dark(`${settings.appName} App started`)

    if(appUiUrl) {
      try {
        window.open(appUiUrl, '_blank').focus()
      }
      catch(e) {
        console.error(e)
        alert('There was a problem while trying to open the App. Try again later!')
      }
    }

    return responsePayload
  }

  render() {
    return (
      <>
        <Sidebar title="Design options">
          <div className="WorkZone__Tabs">
            <span
              className={this.state.activeTab === TAB.EQUIPMENT_TREE ? 'active' : undefined}
              onClick={() => this.setState({activeTab: TAB.EQUIPMENT_TREE})}
            >Equipment</span>
            <span
              className={this.state.activeTab === TAB.EQUIPMENT_SETTINGS ? 'active' : undefined}
              onClick={() => this.setState({activeTab: TAB.EQUIPMENT_SETTINGS})}
              style={{left: 89}}
            >Settings</span>
            <span
              className={this.state.activeTab === TAB.DATA_QUERY ? 'active' : undefined}
              onClick={() => this.setState({activeTab: TAB.DATA_QUERY})}
              style={{left: 162}}
            >Queries</span>
            <span
              className={this.state.activeTab === TAB.APPS ? 'active' : undefined}
              onClick={() => this.setState({activeTab: TAB.APPS})}
              style={{left: 233}}
            >Apps</span>
          </div>
          <div className="SidebarContent">
            <div style={{display: this.state.activeTab === TAB.EQUIPMENT_TREE ? 'block' : 'none'}}>
              <EquipmentTree/>
            </div>
            <div style={{display: this.state.activeTab === TAB.EQUIPMENT_SETTINGS ? 'block' : 'none'}}>
              <EquipmentSettings equipment={this.props.equipment}/>
            </div>
            <div style={{display: this.state.activeTab === TAB.DATA_QUERY ? 'block' : 'none'}}>
              <EquipmentDataQuery
                jobLocation={this.props.job.location}
                equipment={this.props.equipment}
                fileQueries={this.props.fileQueries}
              />
            </div>
            <div style={{display: this.state.activeTab === TAB.APPS ? 'block' : 'none'}}>
              <ServiceApps
                appSettings={this.props.appSettings}
                onAppStart={this.onAppStart}
                serviceTypes={[20]}
              />
            </div>
          </div>
        </Sidebar>
        <WorkZone
          activeTab={this.props.activeTab}
          onTabClick={this.props.onTabClick}
        >
          <div id="draw2d_canvas"></div>
        </WorkZone>
      </>
    )
  }
}
