import React from 'react'
import PropTypes from 'prop-types'
import makeBem from 'bem-cx'

import './DropZone.css'

const cn = makeBem('DropZone')

export const DropZone = props => (
  <div
    className={cn.mod('is_empty', props.isEmpty)}
    onDrop={e => props.onDrop(e.dataTransfer.getData('draggable_payload'))}
    onDragOver={e => e.preventDefault()}
  >{props.label}</div>
)

DropZone.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  isEmpty: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
}
