import {DataSource} from './dataSource'
import {DataSet} from './dataSet'

/** @class */
export class Allocation {
  constructor(json) {
    json = json || {}

    this.dataSource = new DataSource(json.dataSource)
    this.dataSet = json.dataSet ? new DataSet(json.dataSet) : null
    this.timeDataSet = json.timeDataSet ? new DataSet(json.timeDataSet) : null
  }
}
