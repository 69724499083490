import React from 'react'
import PropTypes from 'prop-types'
import {NewMetaContainer} from 'cmp/NewMetaContainer/NewMetaContainer'
import {FilterQuery} from './FilterQuery'

export const ImageMeta = props => {
  /* region STATE */
  const [newMetaList, setNewMetaList] = React.useState([])
  /* endregion STATE */

  /* region CALLBACKS */
  const isZeroImageSelected  = props.selectedFileCount === 0
  const isOneImageSelected   = props.selectedFileCount === 1
  const areMultipleImagesSelected = props.selectedFileCount  >  1
  /* endregion CALLBACKS */
  
  return (
    <div id="ImageMeta">
      {isZeroImageSelected && (
        <div>No image is selected...</div>
      )}

      {isOneImageSelected && (
        <div className="sectionTitle">
          <span>{props.fileInfo.fileName}</span>
          <a
            href={props.fileInfo.uri}
            target="_blank"
            rel="noopener noreferrer"
            className="ImageManager__TabContainer__openImageIcon"
          >
            <i className="fas fa-external-link-alt"></i>
          </a>
        </div>
      )}

      {areMultipleImagesSelected && (
        <div>{props.selectedFileCount} images are selected</div>
      )}

      <div className="ImageManager__MetaList">
        {isOneImageSelected && props.fileInfo.metaList.map((meta, metaIdx) => (
          <div
            className="metaItem"
            key={metaIdx}
          >
            <span className="metaKey">{meta.key}</span>
            <span className="metaValue">{meta.value}</span>
            <i
              className="editIcon far fa-trash-alt"
              onClick={() => props.removeMeta(metaIdx)}
            />
          </div>
        ))}
      </div>

      <hr/>

      <div className="sectionTitle">Add metadata</div>

      <NewMetaContainer
        newMetaList={newMetaList}
        addMetaToSelectedImages={props.addMeta}
        setNewMetaList={setNewMetaList}
        areMultipleImagesSelected={areMultipleImagesSelected}
      />

      <input
        type="checkbox"
        className="withLabel"
        checked={props.isStaticThumbMode}
        onChange={props.onToggleStaticThumbMode}
      />
      <span>Static thumb mode</span>

      <hr/>

      <FilterQuery
        metaFilterList={newMetaList}
        onFilterQueryApply={setNewMetaList}
      />

    </div>
  )
}

ImageMeta.propTypes = {
  fileInfo: PropTypes.object,
  selectedFileCount: PropTypes.number.isRequired,
  isStaticThumbMode: PropTypes.bool.isRequired,
  addMeta: PropTypes.func.isRequired,
  removeMeta: PropTypes.func.isRequired,
  onToggleStaticThumbMode: PropTypes.func.isRequired,
}
