import React from 'react'
import makeBem from 'bem-cx'
import {useAuth0} from '@auth0/auth0-react'

import {MainPage} from './MainPage/MainPage'
import './App.css'

const cn = makeBem('App')

export const App = () => {
  /*region Auth*/
  const auth0 = useAuth0()
  const [token, setToken] = React.useState('')

  if(auth0.isAuthenticated) {
    if(auth0.user.name.includes('@arizon.com')) {
      window.location = `${process.env.REACT_APP_RTM_URL}units-overview.html?user=${auth0.user.name}`
    }

    const getToken = async() => {
      if(auth0.isAuthenticated) {
        const token = await auth0.getAccessTokenSilently()
        localStorage.setItem('bearer-token', token)
        localStorage.setItem('username', auth0.user.name)
        setToken(token)
      }
    }
    getToken()
  }
  else if(!auth0.isLoading) {
    auth0.loginWithRedirect()
  }
  /*endregion*/

  /*region Switch sites popup*/
  const [isSwitchPopupVisible, setSwitchPopupVisible] = React.useState(false)
  const onSwitchPopupVisibleClick = () => {
    if(isSwitchPopupVisible) {
      return false
    }

    document.addEventListener('click', onClickAnywhere)
    setSwitchPopupVisible(!isSwitchPopupVisible)

    return false
  }
  const onClickAnywhere = e => {
    if(e.target.id !== 'SwitchSitesPopup') {
      setSwitchPopupVisible(false)
      document.removeEventListener('click', onClickAnywhere)
    }
  }
  /*endregion*/

  return (
    <div className={cn}>
      {auth0.isLoading ? (
        <div id="AuthContainer">
          <div id="AuthText">Authenticating...</div>
        </div>
      ) : auth0.isAuthenticated && token !== '' && (
          <React.Fragment>
            <div className={cn.el('Head')}>
              <i
                id="SwitchSitesButton"
                className="fas fa-th"
                onClick={onSwitchPopupVisibleClick}
              ></i>
              {isSwitchPopupVisible && (
                <div id="SwitchSitesPopup">
                  <div>Switch to</div>
                  <ul>
                    <li>
                      <i className="fas fa-clock"></i>
                      <a href={`${process.env.REACT_APP_RTM_URL}units-overview.html?user=${auth0.user.name}`}>Realtime monitoring</a>
                    </li>
                    <li>
                      <i className="fas fa-flask"></i>
                      <a href="/">Analyses</a>
                    </li>
                  </ul>
                </div>
              )}
              <a href="https://www.minealytics.io/">
                <img src="img/MineAlyticsLogoBright.svg" alt=""/>
                <img src="img/logo.svg" alt="Minealytics" />
              </a>
            </div>
            <MainPage
              onLogout={() => {
                localStorage.clear()
                auth0.logout({returnTo: window.location.origin})
              }}
            />
          </React.Fragment>
        )}
    </div>
  )
}
