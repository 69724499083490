import React from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'cmp/Modal'
import {Button} from 'cmp/Button/Button'
import './FileContainerModal.css'

export const FileContainerModal = ({
  fileContainers,
  onSelect,
  onCancel,
}) => {
  const [selectedFileContainerId, setSelectedFileContainerId] = React.useState(null)

  return (
    <Modal
      isVisible={true}
      onClose={() => {}}
      title="Select File Container"
    >
      <div id="FileContainerModal">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Container ID</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {fileContainers.map((container, idx) => (
              <tr key={idx}>
                <td>
                  <input
                    type="radio"
                    checked={container.containerId === selectedFileContainerId}
                    onChange={() => setSelectedFileContainerId(container.containerId)}
                  />
                </td>
                <td className="containerName">{container.containerId}</td>
                <td>{container.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button
          text="OK"
          onClick={() => {
            const FileContainerIdx = fileContainers.findIndex(ic => ic.containerId === selectedFileContainerId)
            onSelect(FileContainerIdx)
          }}
          disabled={selectedFileContainerId === null}
        />
        <Button
          text="Cancel"
          onClick={onCancel}
        />
      </div>
    </Modal>
  )
}

FileContainerModal.propTypes = {
  fileContainers: PropTypes.arrayOf(PropTypes.shape({
    containerId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
