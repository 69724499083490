export const STATE = {
  REQUESTING_FILE_LIST: 'REQUESTING_FILE_LIST',
  LISTING: 'LISTING',
  UPLOADING: 'UPLOADING',
  UPLOADING_IMPORT: 'UPLOADING_IMPORT',
  EDITING_META: 'EDITING_META',
  DELETING: 'DELETING',
}

export const EVENT = {
  REQUEST_FILE_LIST: 'REQUEST_FILE_LIST',
  RESPONSE_FILE_LIST: 'RESPONSE_FILE_LIST',
  UPLOAD: 'UPLOAD',
  UPLOAD_IMPORT: 'UPLOAD_IMPORT',
  UPLOAD_IMPORT_READY: 'UPLOAD_IMPORT_READY',
  EDIT_META: 'EDIT_META',
  DELETE: 'DELETE',
}

export const FILE_INFO_TYPE = {
  MEASUREMENT: 'MEASUREMENT',
  EVENT: 'EVENT',
  IMAGE: 'IMAGE',
  SUPPORT: 'SUPPORT',
}

export const FILE_INFO_META_NAME = {
  JOB: 'JOB',
  FILE_NAME: 'FILE_NAME',
  TIME_VALIDITY: 'TIME_VALIDITY',
  TYPE_SUBCLASSES: 'TYPE_SUBCLASSES',
  DISCIPLINES: 'DISCIPLINES',
  LOCATION: 'LOCATION',
  ASSET: 'ASSET',
  SOURCE: 'SOURCE',
  SHORT_DESCRIPTION: 'SHORT_DESCRIPTION',
  MANUFACTURER: 'MANUFACTURER',
  DOCUMENT_NUMBER: 'DOCUMENT_NUMBER',
  REFERENCES: 'REFERENCES',
  USER_DATA: 'USER_DATA',
  QUERY: 'QUERY',
}

export const FILE_INFO_META_TYPE = {
  STRING: 'STRING',
  MULTI_STRING: 'MULTI_STRING',
  DATETIME: 'DATETIME',
  DISCIPLINES: 'DISCIPLINES',
}

export class FileMetaData {
  constructor(name, value){
    this.name = name

    const type = getMetaType(name)

    if(type === FILE_INFO_META_TYPE.MULTI_STRING) {
      if(typeof(value) === 'object' && value !== null){
        this.value = value
      }
      else{
        this.value = value ? value.split(',') : []
      }
    }
    else {
      this.value = value || ''
    }
  }
}

export class FileInfo {
  constructor(fileName, size, type, metaList, uri){
    this.fileName = fileName
    this.size = size
    this.type = type
    this.metaList = metaList.map(metaFilterData => new FileMetaData(metaFilterData.Key, metaFilterData.Value))
    this.uri = uri
  }
}

export const DISCIPLINE = {
  CIVIL: 'CIVIL',
  STRUCTURAL: 'STRUCTURAL',
  MECHANICAL: 'MECHANICAL',
  ELECTRICAL: 'ELECTRICAL',
  CONTROLS: 'CONTROLS',
  PROCESS: 'PROCESS',
}

export const getMetaType = fileInfoMetaName => {
  switch(fileInfoMetaName){
    case FILE_INFO_META_NAME.TIME_VALIDITY:
      return FILE_INFO_META_TYPE.DATETIME

    case FILE_INFO_META_NAME.JOB:
    case FILE_INFO_META_NAME.TYPE_SUBCLASSES:
    case FILE_INFO_META_NAME.LOCATION:
    case FILE_INFO_META_NAME.ASSET:
    case FILE_INFO_META_NAME.REFERENCES:
    case FILE_INFO_META_NAME.USER_DATA:
      return FILE_INFO_META_TYPE.MULTI_STRING

    case FILE_INFO_META_NAME.SOURCE:
    case FILE_INFO_META_NAME.SHORT_DESCRIPTION:
    case FILE_INFO_META_NAME.MANUFACTURER:
    case FILE_INFO_META_NAME.DOCUMENT_NUMBER:
      return FILE_INFO_META_TYPE.STRING

    case FILE_INFO_META_NAME.DISCIPLINES:
      return FILE_INFO_META_TYPE.DISCIPLINES

    default:
      return FILE_INFO_META_TYPE.STRING
  }
}
