import _ from 'lodash'
import {uuid} from 'utils/uuid'

import {server} from 'utils/server'
import {JOB_INIT_TYPE} from './constants'
import {Job} from './job'
import {Field} from './field'
import {Constant} from './constant'
import {Variable} from './variable'
import {Equipment} from './equipment'
import {EquipmentContainer} from './equipmentContainer'
import {DataSource} from './dataSource'
import {Allocation} from './allocation'
import {Plant} from './plant'
import {EventAllocation} from './eventAllocation'

export const initModel = function(callback) {
  server.getJobIdentifiers(jobIdentifiers => {
    const model = new Model()
    model._jobIdentifiers = jobIdentifiers
    window.minealytics = {model}

    if(callback !== undefined) {
      callback(model)
    }
  })
}

/** @class */
export class Model {
  constructor(job) {
    /**
     * @type {Job}
     * @private
     */
    this._job = new Job(job)
    this._jobIdentifiers = []
    this.isJobInitialized = false

    window.minealytics = {
      model: this,
    }
  }

  /**
   * @param noSync [bool]
   *
   * @return {Job}
   * @private
   */
  save(noSync) {
    if(!noSync) {
      server.putJob(this._job, successData => {
        console.log('synced')
      }, failData => {
        console.warn('syncing failed')
      })
    }

    return _.cloneDeep(this._job)
  }

  getJob() {
    return this.save(true)
  }

  getJobIdentifiers() {
    return this._jobIdentifiers
  }

  initJob(param, callback) {
    if(param.initType === JOB_INIT_TYPE.NEW) {
      this._job.title = param.title
      this._job.location = param.location
      this._job.process = param.process
      this._job.analyses = param.analyses
      this._job.controlModules = param.controlModules

      server.postJob(this._job, createdJobId => {
        this._job.id = createdJobId
        this.isJobInitialized = true

        if(callback !== undefined) {
          callback(_.cloneDeep(this._job))
        }
      }, () => console.log('Init job failed'))
    }
    else if(param.initType === JOB_INIT_TYPE.LOAD) {
      server.getJob(param.jobId, jobData => {
        this._job = new Job(jobData)
        this.isJobInitialized = true

        if(callback !== undefined) {
          callback(_.cloneDeep(this._job))
        }
      })
    }
    else if(param.initType === JOB_INIT_TYPE.CLONE) {
      server.getJob(param.jobId, jobData => {
        this._job = new Job(jobData)
        this._job.title = this._job.title + ' (clone)'

        if(param.analyses) {
          this._job.analyses = param.analyses
        }

        server.postJob(this._job, createdJobId => {
          this._job.id = createdJobId
          this.isJobInitialized = true

          if(callback !== undefined) {
            callback(_.cloneDeep(this._job))
          }
        })
      })
    }
    else {
      throw new Error('Unknown Job init type parameter')
    }
  }

  /**
   *
   * @param jobId {number}
   * @param callback {undefined|function}
   */
  loadJob(jobId, callback) {
    server.getJob(jobId, job => {
      this._job = new Job(job)

      if(callback !== undefined) {
        callback(this._job)
      }
    })
  }

  /**
   *
   * @param jobId {number}
   * @param sameAnalyses {undefined|bool}
   * @param callback {undefined|function}
   */
  cloneJob(jobId, sameAnalyses, callback) {
    const oldJob = _.cloneDeep(this._job)

    server.getJob(jobId, job => {
      this._job = new Job(job)
      this._job.id = oldJob.id
      this._job.title = oldJob.title + ' clone'

      if(sameAnalyses) {
        this._job.analyses = oldJob.analyses
      }

      if(callback !== undefined) {
        callback(this._job)
      }
    })
  }

  // Designer

  /**
   *
   * @param id {string}
   * @param type {string}
   * @param canvasData {string} - Draw2d marshal returns this.
   * @param name {string} - optional
   * @return {Job}
   */
  addEquipment(id, type, canvasData, name)  {
    this._job.plant.equipmentList.push(new Equipment({
      isNew: true,
      id,
      type,
      name,
      analyses: this._job.analyses,
    }))

    this._job.plant.canvasData = canvasData

    return this.save()
  }

  cloneEquipment(equipment, id, name, canvasData) {
    const equipmentClone = _.cloneDeep(equipment)
    equipmentClone.id = id
    equipmentClone.name = name

    //region Clear Allocations
    equipmentClone.variables.forEach(variable => {
      variable.allocations = []
    })

    equipmentClone.equipmentContainers.forEach(container => {
      container.equipmentList.forEach(equipment => {
        equipment.variables.forEach(variable => {
          variable.allocations = []
        })
      })
    })
    //endregion

    this._job.plant.equipmentList.push(equipmentClone)

    this._job.plant.canvasData = canvasData

    return this.save()
  }

  addSubEquipment(parentEquipmentId, equipmentType) {
    const parentEquipment = this._job.plant.getEquipment(parentEquipmentId)
    const container = parentEquipment.equipmentContainers.find(c => c.type === equipmentType)
    container.addEquipment(this._job.analyses)
    return this.save()
  }

  /**
   *
   * @param equipmentId {string}
   * @param canvasData {string} - Draw2d marshal returns this.
   * @return {Job}
   */
  removeMainEquipment(equipmentId, canvasData) {
    const equipmentIndex = this._job.plant.equipmentList.findIndex(e => e.id === equipmentId)
    this._job.plant.equipmentList.splice(equipmentIndex, 1)

    this._job.plant.canvasData = canvasData

    return this.save()
  }

  removeSubEquipment(equipmentId, parentId, equipmentType) {
    const parentEquipment = this._job.plant.getEquipment(parentId)
    const container = parentEquipment.equipmentContainers.find(c => c.type === equipmentType)
    const equipmentIndex = container.equipmentList.findIndex(e => e.id === equipmentId)
    container.equipmentList.splice(equipmentIndex, 1)
    return this.save()
  }

  setEquipmentName(equipmentId, name) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    equipment.name = name
    return this.save()
  }

  setEquipmentDescription(equipmentId, description) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    equipment.description = description
    return this.save()
  }

  setField(equipmentId, fieldKey, value) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    const field = equipment.fields.find(f => f.key === fieldKey)
    field.value = value
    return this.save()
  }

  setConstantValue(equipmentId, constantKey, value) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    const constant = equipment.constants.find(c => c.key === constantKey)
    constant.value = value
    return this.save()
  }

  setConstantUnit(equipmentId, constantKey, unit) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    const constant = equipment.constants.find(c => c.key === constantKey)
    constant.quantity.unit = unit
    return this.save()
  }

  /**
   * @param canvasData {string} - Generated by Draw2d
   */
  setCanvasData(canvasData) {
    this._job.plant.canvasData = canvasData
    return this.save()
  }

  /**
   * Toggles Equipment field visibility.
   *
   * @param equipmentId {string}
   * @param fieldKey {string}
   * @param actionType {('ADD'|'REMOVE')}
   * @param fieldType {('FIELD'|'CONSTANT'|'VARIABLE')}
   * @return {Job}
   */
  changeEquipmentField(equipmentId, fieldKey, actionType, fieldType) {
    if(fieldType !== 'FIELD' && fieldType !== 'CONSTANT' && fieldType !== 'VARIABLE') {
      throw new Error(`Unknown fieldType: ${fieldType}`)
    }

    const originalEquipment = this._job.plant.getEquipment(equipmentId)
    const equipmentType = window.minealytics.db_equipment.find(e => e.name === originalEquipment.type)

    // Older Jobs don't have 'userEquipmentSettings'
    if(!this._job.userEquipmentSettings) {
      this._job.userEquipmentSettings = {}
    }

    const fieldSettings = this._job.userEquipmentSettings[equipmentType.name]

    // Store user settings in Job
    if(fieldSettings) {
      if(fieldType === 'FIELD') {
        fieldSettings.fields[fieldKey] = actionType === 'ADD'
      }
      else if(fieldType === 'CONSTANT') {
        fieldSettings.constants[fieldKey] = actionType === 'ADD'
      }
      else { //VARIABLE
        fieldSettings.variables[fieldKey] = actionType === 'ADD'
      }
    }
    else {
      this._job.userEquipmentSettings[equipmentType.name] = {
        fields: {},
        constants: {},
        variables: {},
      }
    }

    if(fieldType === 'FIELD') {
      this._job.userEquipmentSettings[equipmentType.name].fields[fieldKey] = actionType === 'ADD'
    }
    else if(fieldType === 'CONSTANT') {
      this._job.userEquipmentSettings[equipmentType.name].constants[fieldKey] = actionType === 'ADD'
    }
    else { //VARIABLE
      this._job.userEquipmentSettings[equipmentType.name].variables[fieldKey] = actionType === 'ADD'
    }

    const equipmentTypeSettings = JSON.parse(equipmentType.settings)

    this._job.plant.getAllEquipment().forEach(equipment => {
      if(equipment.type !== equipmentType.name) {
        return
      }

      if(actionType === 'ADD') {
        if(fieldType === 'FIELD') {
          const equipmentTypeField = equipmentTypeSettings.fields.find(f => f.key === fieldKey)
          equipment.fields.push(new Field(equipmentTypeField))
        }
        else if(fieldType === 'CONSTANT') {
          const equipmentTypeConstant = equipmentTypeSettings.constants.find(c => c.key === fieldKey)
          equipment.constants.push(new Constant(equipmentTypeConstant))
        }
        else { //VARIABLE
          const equipmentTypeVariable = equipmentTypeSettings.variables.find(v => v.key === fieldKey)
          equipment.variables.push(new Variable(equipmentTypeVariable))
        }
      }
      else { //REMOVE
        if(fieldType === 'FIELD') {
          const fieldIndexToRemove = equipment.fields.findIndex(f => f.key === fieldKey)
          equipment.fields.splice(fieldIndexToRemove, 1)
        }
        else if(fieldType === 'CONSTANT') {
          const fieldIndexToRemove = equipment.constants.findIndex(c => c.key === fieldKey)
          equipment.constants.splice(fieldIndexToRemove, 1)
        }
        else { //VARIABLE
          const fieldIndexToRemove = equipment.variables.findIndex(v => v.key === fieldKey)
          equipment.variables.splice(fieldIndexToRemove, 1)
        }
      }
    })

    return this.save()
  }

  /**
   * Sets Equipment settings field
   *
   * @param equipmentId {string}
   * @param settings {string}
   */
  setEquipmentSettings(equipmentId, settings) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    equipment.settings = settings

    return this.save()
  }

  // Allocator

  addDataSource(dataSource) {
    if(!(dataSource instanceof Object) ||
      typeof dataSource.name !== 'string' ||
      !(dataSource.dataSets instanceof Array)
    ) {
      throw new Error('dataSource is not object')
    }

    this._job.dataSources.push(new DataSource(dataSource))

    return this.save()
  }

  addAllocationToVariable(equipmentId, variableKey, allocationJson) {
    if(typeof equipmentId !== 'string' ||
      typeof variableKey !== 'string' ||
      !(allocationJson instanceof Object)
    ){
      throw new Error('equipmentId is missing or has wrong type')
    }

    const dataSource = this._job.dataSources.find(d => d.name === allocationJson.dataSource)

    const allocationObject = {
      dataSource,
      dataSet: dataSource.dataSets.find(d => d.index === allocationJson.dataSet),
      timeDataSet: dataSource.dataSets.find(d => d.index === allocationJson.timeDataSet),
    }

    const allocation = new Allocation(allocationObject)
    const equipment = this._job.plant.getEquipment(equipmentId)
    const variable = equipment.variables.find(v => v.key === variableKey)

    variable.addAllocation(allocation)
    return this.save()
  }

  /**
   * @param equipmentId {string}
   * @param variableKey {string}
   * @param dataSourceName {string}
   */
  removeAllocationFromVariable(equipmentId, variableKey, dataSourceName) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    const variable = equipment.variables.find(v => v.key === variableKey)
    const allocationIndex = variable.allocations.findIndex(a => a.dataSource.name === dataSourceName)
    if(allocationIndex !== -1) {
      variable.allocations.splice(allocationIndex, 1)
    }
    return this.save()
  }

  addAllocationToTimeNode(dataSourceName, timeDataSetIndex, timeNode) {
    let entity
    const dataSource = this._job.dataSources.find(d => d.name === dataSourceName)

    if(timeNode instanceof Plant) {
      entity = this._job.plant
    }
    else if(timeNode instanceof Equipment) {
      entity = this._job.plant.getEquipment(timeNode.id)
    }
    else if(timeNode instanceof EquipmentContainer) {
      console.log('not implemented yet...')
    }

    // Delete Allocation with same DataSource
    const allocationIndex = entity.allocations.findIndex(a => a.dataSource.name === dataSourceName)
    if(allocationIndex !== -1) {
      entity.allocations.splice(allocationIndex, 1)
    }

    const timeDataSet = dataSource.dataSets.find(d => d.index === timeDataSetIndex)

    entity.allocations.push(new Allocation({
      dataSource,
      timeDataSet,
    }))

    return this.save()
  }

  setUnit(equipmentId, variableKey, unit) {
    const equipment = this._job.plant.getEquipment(equipmentId)
    const variable = equipment.variables.find(v => v.key === variableKey)
    variable.quantity.unit = unit

    return this.save()
  }

  /**
   * @param allocationKey {string}
   * @param allocation {object}
   * @param allocation.dataSourceName {string}
   * @param allocation.dataSetIndex {number}
   *
   * @returns {Job}
   */
  addEventAllocation(allocationKey, allocation) {
    const eventAllocation = new EventAllocation()
    const dataSource = this._job.dataSources.find(d => d.name === allocation.dataSourceName)
    const dataSet = dataSource.dataSets[allocation.dataSetIndex]

    eventAllocation.dataSource = dataSource
    eventAllocation.dataSet = dataSet
    eventAllocation.key = allocationKey

    const existingIndex = this._job.plant.eventAllocations.findIndex(e => {
      return e.key === allocationKey && e.dataSource.name === allocation.dataSourceName
    })
    if(existingIndex > -1) {
      this._job.plant.eventAllocations.splice(existingIndex, 1)
    }

    this._job.plant.eventAllocations.push(eventAllocation)
    return this.save()
  }

  removeEventAllocation(allocationKey, dataSourceName) {
    const index = this._job.plant.eventAllocations.findIndex(e => {
      return e.key === allocationKey && e.dataSource.name === dataSourceName
    })
    this._job.plant.eventAllocations.splice(index, 1)
    return this.save()
  }

  /**
   * @param sourceDataSourceName {string} - Name of source DataSource
   * @param targetDataSourceName {string} - Name of target DataSource
   */
  copyAllocation(sourceDataSourceName, targetDataSourceName) {
    const sourceDataSource = this._job.dataSources.find(d => d.name === sourceDataSourceName)
    const targetDataSource = this._job.dataSources.find(d => d.name === targetDataSourceName)

    // Copying is only allowed when the DataSources have equal numbers of DataSets
    // TODO: Could somehow be displayed on UI if allowed/not allowed
    if(sourceDataSource.dataSets.length !== targetDataSource.dataSets.length) {
      alert('Unable to copy. Copying is only allowed if number of columns are equal.')
      return this.save(true)
    }

    const allEquipment = this._job.plant.getAllEquipment()

    const copyAllocation = entity => {
      const allocationToCopy = entity.allocations.find(a => a.dataSource.name === sourceDataSourceName)
      if(!allocationToCopy) {
        return
      }

      const targetIndex = entity.allocations.findIndex(a => a.dataSource.name === targetDataSourceName)

      if(targetIndex > -1) {
        entity.allocations.splice(targetIndex, 1)
      }

      entity.allocations.push(new Allocation({
        dataSource: targetDataSource,
        dataSet: allocationToCopy.dataSet && targetDataSource.dataSets[allocationToCopy.dataSet.index],
        timeDataSet: allocationToCopy.timeDataSet && targetDataSource.dataSets[allocationToCopy.timeDataSet.index],
      }))
    }

    copyAllocation(this._job.plant)
    allEquipment.forEach(equipment => {
      copyAllocation(equipment)
      equipment.equipmentContainers.forEach(container => copyAllocation(container))
      equipment.variables.forEach(variable => copyAllocation(variable))
    })

    const newEventAllocations = this._job.plant.eventAllocations.filter(a => a.dataSource.name !== targetDataSourceName)
    this._job.plant.eventAllocations.forEach(a => {
      if(a.dataSource.name === sourceDataSourceName) {
        newEventAllocations.push(new EventAllocation({
          key: a.key,
          dataSource: targetDataSource,
          dataSet: a.dataSet && targetDataSource.dataSets[a.dataSet.index],
          timeDataSet: a.timeDataSet && targetDataSource.dataSets[a.timeDataSet.index],
        }))
      }
    })
    this._job.plant.eventAllocations = newEventAllocations

    return this.save()
  }

  resetAllocations() {
    this._job.plant.allocations = []
    this._job.plant.getAllEquipment().forEach(equipment => {
      equipment.allocations = []
      equipment.variables.forEach(variable => variable.allocations = [])
      equipment.equipmentContainers.forEach(container => container.allocations = [])
    })
    this._job.plant.eventAllocations = []
    return this.save()
  }

  addConnection(sourceId, targetId, canvasData) {
    const equipment = this._job.plant.getEquipment(sourceId)
    equipment.addConnection(targetId)
    this.setCanvasData(canvasData)
    return this.save()
  }

  deleteDataSource(name, container) {
    if(!window.confirm('Allocations will be deleted! Continue?')) {
      return
    }

    const index = this._job.dataSources.findIndex(ds => ds.name === name && ds.container === container)
    const equipmentList = this._job.plant.getAllEquipment()

    equipmentList.forEach(equipment => {
      const indexToRemove = equipment.allocations.findIndex(a => a.dataSource.name === name && a.dataSource.container === container)
      equipment.allocations.splice(indexToRemove, 1)

      equipment.variables.forEach(variable => {
        const indexToRemove = variable.allocations.findIndex(a => a.dataSource.name === name && a.dataSource.container === container)
        variable.allocations.splice(indexToRemove, 1)
      })

      equipment.equipmentContainers.forEach(equipmentContainer => {
        const indexToRemove = equipmentContainer.allocations.findIndex(a => a.dataSource.name === name && a.dataSource.container === container)
        equipmentContainer.allocations.splice(indexToRemove, 1)
      })
    })

    const indexToRemove = this._job.plant.allocations.findIndex(a => a.dataSource.name === name && a.dataSource.container === container)
    this._job.plant.allocations.splice(indexToRemove, 1)

    const eventAllocationIndex = this._job.plant.eventAllocations.findIndex(a => a.dataSource.name === name && a.dataSource.container === container)
    this._job.plant.eventAllocations.splice(eventAllocationIndex, 1)

    this._job.dataSources.splice(index, 1)
    return this.save()
  }

  addFileContainerToJob(container) {
    this._job.containerId = container.containerId
    return this.save()
  }
}
